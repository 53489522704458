import * as vueRouter from 'vue-router'

function getFirstQueryParameter(
  query: vueRouter.LocationQuery,
  parameterName: string,
): string | undefined {
  const parameter = query[parameterName]
  if (!parameter) {
    return undefined
  }

  if (Array.isArray(parameter)) {
    if (parameter.length >= 1) {
      return decodeURIComponent(parameter[0] ?? '')
    } else {
      return undefined
    }
  } else {
    return decodeURIComponent(parameter)
  }
}

function getFirstQueryParamAsNumber(
  query: vueRouter.LocationQuery,
  parameterName: string,
  defaultValue: number = 0,
): number {
  const parameter = getFirstQueryParameter(query, parameterName)
  if (!parameter) {
    return defaultValue
  }
  const value = Number.parseInt(parameter, 10)
  return isNaN(value) ? defaultValue : value
}

function getFirstQueryParamAsStringArray(query: vueRouter.LocationQuery, key: string): string[] {
  const value = getFirstQueryParameter(query, key)
  return value && value !== null && value.length > 0 ? value.split(',').map(decodeURIComponent) : []
}

function splitParameterValue(
  value: string | vueRouter.LocationQueryValue,
  delimiter: string,
): string[] {
  if (!value || value.length === 0) {
    return []
  }

  if (!delimiter) {
    console.log('no delimiter')
    return [decodeURIComponent(value)]
  }

  console.log('splitParameterValue', value, decodeURIComponent(value).split(delimiter))
  return decodeURIComponent(value).split(delimiter)
}

function getQueryParameterArray(
  query: vueRouter.LocationQuery,
  parameterName: string,
  delimiter: string = ',',
): string[] {
  const parameter = query[parameterName]
  const results: string[] = []
  if (!parameter) {
    return results
  }

  if (Array.isArray(parameter)) {
    for (const element of parameter) {
      const splitElement = splitParameterValue(element, delimiter)
      if (splitElement && splitElement.length > 0) {
        for (const item of splitElement) {
          if (!results.includes(item)) {
            results.push(item)
          }
        }
      }
    }
  } else {
    results.push(...splitParameterValue(parameter, delimiter))
  }

  return results
}

export {
  getFirstQueryParameter,
  getFirstQueryParamAsNumber,
  getFirstQueryParamAsStringArray,
  getQueryParameterArray,
}
