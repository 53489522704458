<template>
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="props.fillClass"
      d="M5.94 13.705C7.20685 13.713 8.46268 13.4694 9.63465 12.9882C10.8066 12.5071 11.8714 11.7981 12.7672 10.9022C13.663 10.0064 14.3721 8.94166 14.8532 7.76969C15.3343 6.59773 15.578 5.3419 15.57 4.07505C15.57 3.92505 15.57 3.78255 15.57 3.63255C16.2267 3.15141 16.7951 2.56017 17.25 1.88505C16.6316 2.15549 15.9773 2.33486 15.3075 2.41755C16.0177 1.99438 16.5506 1.3283 16.8075 0.542545C16.1456 0.939626 15.4199 1.21877 14.6625 1.36755C14.1524 0.82407 13.4775 0.463837 12.7421 0.342585C12.0067 0.221333 11.2518 0.34582 10.5943 0.696786C9.93678 1.04775 9.41324 1.60563 9.1047 2.2841C8.79617 2.96258 8.71983 3.72382 8.8875 4.45005C7.54202 4.384 6.22553 4.03531 5.02377 3.42668C3.822 2.81804 2.7619 1.96311 1.9125 0.917545C1.48419 1.66093 1.35437 2.53929 1.54932 3.37479C1.74427 4.21029 2.24942 4.94049 2.9625 5.41755C2.43646 5.39726 1.92259 5.25337 1.4625 4.99755V5.03505C1.45783 5.81192 1.71954 6.56693 2.20403 7.17425C2.68851 7.78156 3.3665 8.20449 4.125 8.37255C3.63573 8.50414 3.12322 8.52464 2.625 8.43255C2.84459 9.09509 3.26376 9.67347 3.82503 10.0884C4.38631 10.5033 5.06219 10.7344 5.76 10.75C4.56691 11.7104 3.08407 12.2391 1.5525 12.25C1.28396 12.2423 1.01606 12.2197 0.75 12.1825C2.30022 13.1702 4.1019 13.6909 5.94 13.6825"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  fillClass: {
    type: String,
    default: null,
  },
})
</script>
