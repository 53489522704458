<template>
  <div class="TITW popular-searches-mobile flex justify-between space-x-4">
    <dropdown
      v-if="model?.terms?.length > 0 && model.header"
      :menuItems="model.terms"
      :placeholder="model.header"
      :class-names="'border border-grey-200 shadow-sm'"
      @item-changed="handleItemChanged"
    />
  </div>
</template>

<script setup lang="ts">
import { AdjustmentsHorizontalIcon } from '@heroicons/vue/24/solid'
import SharedButton from '@TodayInTheWord/components/shared/s-button.vue'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import Dropdown from './dropdown.vue'

const emit = defineEmits(['newSearch'])

type PopularSearchesProps = {
  model: searchTypes.SearchTermsSection | null
}

const handleItemChanged = (text: string) => {
  emit('newSearch', text)
}

const props = defineProps<PopularSearchesProps>()
</script>
