<template>
  <div class="container mx-auto w-full">
    <h1 v-if="computedPageTitle" class="sr-only">{{ computedPageTitle }}</h1>
    <div v-if="breadcrumbs" class="my-5 lg:my-6">
      <bread-crumbs :links="breadcrumbs.links" :currentPageName="breadcrumbs.currentPageName" />
    </div>
    <discussion-starter-hero
      :model="model.header"
      class="mb-6 lg:mb-12"
      :class="{ 'mx-[-18px]': mediaSizeStore.isMobile }"
    />
    <div class="relative" ref="searchSection">
      <search-filters-component
        :search-component="model.searchComponent"
        :request-type="'post'"
        :segment-link-tabs="model.searchComponent?.contentTypes"
        @search-filter-change="onSearchFiltersChanged"
      />
    </div>
    <section
      v-if="
        state.searchResults && state.searchResults.items && state.searchResults.items.length > 0
      "
      class="discussionStarters mb-8 w-full space-y-8 md:mb-16 md:space-y-[108px]"
    >
      <div class="flex flex-col space-y-5 lg:grid lg:grid-cols-4 lg:gap-6 lg:space-y-0">
        <discussion-starter-result-card
          v-for="(card, index) in state.searchResults.items"
          :key="index"
          :card="card"
          class="col-span-1"
        />
      </div>

      <shared-pagination
        v-show="state.searchResults.totalPages > 1"
        :current-page="state.searchResults.page"
        :total-pages="state.searchResults.totalPages"
        @page-changed="gotoPage"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import * as routeHelper from '@shared/api/route-helper'
import * as searchRequestBuilder from '@shared/api/search-request-builder'
import * as webApi from '@shared/api/web-api'
import { EventName } from '@TodayInTheWord/components/pages/shared/search-filter-component/types/search-filter-types'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import SharedPagination from '@TodayInTheWord/components/shared/s-pagination.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import * as indexTypes from '@TodayInTheWord/types/index-search-types'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import { computed, onMounted, ref } from 'vue'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import searchFiltersComponent from '../shared/search-filter-component/search-filters-component.vue'
import discussionStarterHero from './components/discussion-starter-hero.vue'
import discussionStarterResultCard from './components/discussion-starter-result-card.vue'
import { DiscussionStarterIndexProps } from './types/discussion-starters-types'

const epiPageContextStore = useEpiPageContextStore()
const breadcrumbs: BreadcrumbProps = epiPageContextStore.breadcrumbs
const model: DiscussionStarterIndexProps['model'] = epiPageContextStore.model
const router = useRouter()
const route = useRoute()
const requestedCategories = routeHelper.getFirstQueryParameter(route.query, 'c')
const requestedDate = routeHelper.getFirstQueryParameter(route.query, 'd')
const searchSection = ref<HTMLElement | null>(null)
const mediaSizeStore = useMediaSizeStore()

interface IDiscussionIndexPageState {
  searchResults?: indexTypes.SearchResults | null
  searchParams: searchRequestBuilder.ISearchParams
  isLoading: boolean
  isFirstLoad: boolean
}

const state = ref<IDiscussionIndexPageState>({
  searchResults: null,
  isLoading: false,
  searchParams: {},
  isFirstLoad: true,
})

const initializePageState = () => {
  state.value.searchParams.q = routeHelper.getFirstQueryParameter(route.query, 'q')
  state.value.searchParams.c = routeHelper.getFirstQueryParamAsStringArray(route.query, 'c')
  state.value.searchParams.p = routeHelper.getFirstQueryParamAsNumber(route.query, 'p', 1)
  state.value.searchParams.d = routeHelper.getFirstQueryParameter(route.query, 'd')

  const queryParamT = routeHelper.getFirstQueryParameter(route.query, 't')

  if (!queryParamT) {
    state.value.searchParams.t = 'All'
  } else {
    state.value.searchParams.t = queryParamT
  }
}

function setUrlToMatchSearchParams(searchParams: searchRequestBuilder.ISearchParams) {
  const newLocation: RouteLocationRaw = {
    path: route.path,
    query: {},
  }

  newLocation.query.q = searchParams.q

  if (searchParams.c && searchParams.c.length > 0) {
    newLocation.query.c = searchParams.c.join()
  }

  if (searchParams.d !== '') {
    newLocation.query.d = searchParams.d
  }

  if (searchParams.p) {
    newLocation.query.p = searchParams.p
  }

  router.push(newLocation)
}

async function onSearchFiltersChanged(
  searchParams: searchRequestBuilder.ISearchParams,
  eventName: EventName,
) {
  state.value.searchParams.q = searchParams.q
  state.value.searchParams.c = searchParams.c
  state.value.searchParams.p = searchParams.p
  state.value.searchParams.d = searchParams.d

  setUrlToMatchSearchParams(searchParams)
  await fireSearchRequest()
}

async function fireSearchRequest() {
  const searchParams = state.value.searchParams
  const searchApiUrl: string = model?.searchComponent?.searchApiUrl ?? route.path

  if (searchParams.c && searchParams.c.length === 1) {
    searchParams.c = searchParams.c.toString().split(',')
  }

  state.value.isLoading = true

  const request = searchRequestBuilder.createPostRequest(searchApiUrl, searchParams)

  const response = await webApi.callAsync<
    indexTypes.SearchResults | indexTypes.AllTabSearchResults
  >(request)

  state.value.searchResults = response.data

  if (response.data) {
    if (searchSection.value && state.value.isFirstLoad == false) {
      searchSection.value.scrollIntoView({
        behavior: 'instant',
        block: 'start',
      })
    }
  } else {
    state.value.isFirstLoad = false
  }
  state.value.isLoading = false
  state.value.isFirstLoad = false
}

const gotoPage = async (page: number) => {
  if (page <= 0) {
    return
  }

  const newLocation: RouteLocationRaw = {
    path: route.path,
    query: {
      q: route.query.q,
    },
  }

  if (page > 1 && newLocation.query) {
    newLocation.query.p = page
  }

  if (requestedCategories && newLocation.query) {
    newLocation.query.c = requestedCategories
  }

  if (requestedDate && newLocation.query) {
    newLocation.query.d = requestedDate
  }

  if (newLocation.query) {
    newLocation.query.q = state.value.searchParams.q
  }

  await onSearchFiltersChanged(newLocation.query, 'applyFilters')
}

const computedPageTitle = computed(() => {
  return model.title ?? breadcrumbs.currentPageName
})

onMounted(async () => {
  initializePageState()
  await fireSearchRequest()
})
</script>
