<template>
  <div class="noSearchResultsMobile">
    <div class="flex justify-center">
      <div
        v-if="searchResponse?.query"
        class="text-grey-500 mb-5 text-lg font-medium"
        v-html="computedNoResultsMessage"
      />
    </div>
    <div
      v-if="
        searchResponse?.relatedQueries &&
        searchResponse.relatedQueries.suggestions &&
        searchResponse.relatedQueries.suggestions.length > 0
      "
      class="relatedQuerySuggestions text-left"
    >
      <h2 v-if="searchResponse.relatedQueries.heading" class="mb-6 text-lg font-medium text-black">
        {{ searchResponse.relatedQueries.heading }}
      </h2>
      <div class="divide-grey-200 bg-grey-50 flex flex-col divide-y divide-solid rounded-lg">
        <button
          v-for="(term, i) in searchResponse.relatedQueries.suggestions"
          :key="`term${i}`"
          @click="headerStore.updateSearchText(term.suggestion)"
          class="flex items-center justify-between p-4 text-left font-semibold text-black"
        >
          {{ term.suggestion }}
          <ChevronRightIcon class="text-grey-800 h-5 w-5"></ChevronRightIcon>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ChevronRightIcon } from '@heroicons/vue/24/solid'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const headerStore = useHeaderStore()
const { searchResponse } = storeToRefs(headerStore)

const computedNoResultsMessage = computed(() => {
  if (searchResponse.value?.isInvalidQuery) {
    return searchResponse.value?.noResultsMessage ?? 'No results.'
  }

  const noResultsText = searchResponse.value?.noResultsMessage ?? 'No results.'

  return noResultsText.replace(
    /{{ searchTerm }}/gi,
    `<span class="text-black" >“${headerStore.searchText}”</span> `,
  )
})
</script>
