<template>
  <div class="space-y-6" v-if="results?.items">
    <template v-for="item in results.items">
      <q-and-a-item :model="item" />
    </template>
    <shared-pagination
      v-show="results.totalPages > 1"
      :current-page="results.page"
      :total-pages="results.totalPages"
      @page-changed="pageChanged"
    />
  </div>
</template>

<script setup lang="ts">
import SharedPagination from '@TodayInTheWord/components/shared/s-pagination.vue'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import QAndAItem from './q-and-a-item.vue'

interface PagesProperties {
  segment: searchTypes.ISegmentModel
  results: searchTypes.TitwSearchTabResults | null
}

const emit = defineEmits<{
  changePage: [page: number]
}>()

const props = withDefaults(defineProps<PagesProperties>(), {
  results: null,
})

const pageChanged = (page: number) => {
  emit('changePage', page)
}
</script>

<style lang="scss"></style>
