<template>
  <div>
    <div class="py-5">
      <bread-crumbs :links="breadcrumbs.links" :currentPageName="breadcrumbs.currentPageName" />
    </div>
    <div class="container mx-auto">
      <div class="w-full border-b border-slate-300">
        <div class="mb-12 mt-6 flex gap-6 lg:w-11/12">
          <div :class="mediaSizeStore.isMobile ? '' : 'flex flex-row gap-x-6'">
            <div v-if="model.image && !mediaSizeStore.isMobile" class="flex w-1/4 items-start">
              <s-image
                :model="model.image"
                class="aspect-square h-auto w-full rounded-xl object-cover object-center"
                :preload="true"
              />
            </div>
            <div class="flex w-full flex-col justify-center lg:w-3/4">
              <div class="flex space-x-4 lg:flex-col lg:space-x-0">
                <s-image
                  v-if="model.image && mediaSizeStore.isMobile"
                  :model="model.image"
                  class="aspect-square h-full w-full max-w-[80px] rounded-xl object-cover"
                />
                <div>
                  <h1
                    v-if="model.displayName"
                    class="text-2xl font-semibold leading-8 lg:text-4xl lg:leading-10"
                  >
                    {{ model.displayName }}
                  </h1>
                  <span
                    v-if="model.title"
                    class="mt-1 text-sm font-normal leading-5 lg:text-base lg:leading-6"
                    >{{ model.title }}</span
                  >
                </div>
              </div>
              <div v-if="model.biography" class="my-6">
                <RichTextBlock :model="{ richTextField: model.biography }"></RichTextBlock>
              </div>
              <div
                class="flex flex-col space-x-0 space-y-7 lg:flex-row lg:items-center lg:space-x-4 lg:space-y-0"
              >
                <s-link-auto
                  v-for="link in model.links"
                  :link="link"
                  class="flex max-w-fit gap-x-2 text-sm font-medium leading-5 lg:text-base lg:leading-6"
                  >{{ link.text }}
                  <ArrowTopRightOnSquareIcon
                    class="text-light-blue-900 h-5 w-5"
                  ></ArrowTopRightOnSquareIcon>
                </s-link-auto>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="articleResults" class="pt-8 lg:pt-12">
        <div
          v-if="model.searchComponent?.filters && model.searchComponent?.filters.length > 2"
          class="mb-6 flex items-center space-x-3 overflow-y-scroll [-ms-overflow-style:none] [scrollbar-width:none] lg:mb-8 [&::-webkit-scrollbar]:hidden"
        >
          <button
            v-for="filter in model.searchComponent?.filters"
            @click.prevent="filterResult(filter)"
            class="text-grey-700 whitespace-nowrap rounded-xl px-3 py-2 text-sm font-medium transition lg:text-base"
            :class="filter === activeFilter ? 'bg-cyan-600 text-white' : 'hover:bg-grey-100'"
          >
            {{ filter }}
          </button>
        </div>
        <Author-Articles
          :model="articleResults"
          @changePage="changePage"
          class="lg:w-3/4"
        ></Author-Articles>
      </div>
      <div
        v-if="model.books && model.books.items && model.books.items.length > 0"
        class="mt-8 lg:mt-12"
      >
        <Author-Books :model="model.books"></Author-Books>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'
import * as webApi from '@shared/api/web-api'
import RichTextBlock from '@TodayInTheWord/components/blocks/rich-text-block/rich-text-block.vue'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import SImage from '@TodayInTheWord/components/shared/s-image.vue'
import SLinkAuto from '@TodayInTheWord/components/shared/s-link-auto.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { onMounted, ref } from 'vue'
import AuthorArticles from './components/author-articles.vue'
import AuthorBooks from './components/author-books.vue'
import { ArticleResultProps, AuthorDetailProps } from './types/author-detail-types'

const model: AuthorDetailProps['model'] = useEpiPageContextStore().model
const breadcrumbs = useEpiPageContextStore().breadcrumbs
const mediaSizeStore = useMediaSizeStore()
const articleResults = ref<ArticleResultProps | null>(null)
const activeFilter = ref('All')
const currentPage = ref(0)

const fetchSearchContent = async () => {
  try {
    const apiUrl = model.searchComponent?.searchApiUrl
    console.log(apiUrl)
    const response = await webApi.callAsync<ArticleResultProps>({
      method: 'GET',
      url: apiUrl,
      headers: { Accept: 'application/json' },
      params: {
        contentType: activeFilter.value,
        page: currentPage.value,
      },
    })

    articleResults.value = response.data ?? null
  } catch (error) {
    console.error('Error fetching search content:', error)
  }
}

const changePage = (page: number) => {
  if (page <= 0) {
    return
  }

  currentPage.value = page

  fetchSearchContent()

  window.scroll({
    top: 0,
    left: 0,
  })
}

const filterResult = (filter: string) => {
  activeFilter.value = filter
  fetchSearchContent()
}

onMounted(() => {
  fetchSearchContent()
})
</script>
