<template>
  <div class="py-5" :class="{ 'border-grey-300': !mediaStore.isMobile }">
    <bread-crumbs :links="breadcrumbs.links" :currentPageName="breadcrumbs.currentPageName" />
  </div>
  <main class="container mx-auto">
    <div class="lg:grid lg:grid-cols-12">
      <div class="col-span-12">
        <h1 v-if="model.pageHeading" class="sr-only">
          {{ model.pageHeading }}
        </h1>
        <search-filters-component
          :search-component="model.searchComponent"
          :segment-link-tabs="model.segmentLinks"
          :hideEmptyTabs="true"
          :restrict-empty-term-search="true"
          @search-filter-change="onSearchFiltersChanged"
        />
      </div>
      <div class="col-span-9 lg:pr-6">
        <error-message :text="errorMessage" />
        <popular-searches-mobile
          v-show="!mediaStore.isDesktop"
          :model="model.popularSearchTerms"
          @new-search="newSearch"
        />

        <div v-show="searchStore.isLoading">
          <SkeletonLoader>
            <SearchPageSkeleton />
          </SkeletonLoader>
        </div>
        <template v-if="searchStore.searchResults">
          <div v-if="hasResults">
            <template v-for="segment in model.segments">
              <component
                v-if="segmentHasResults(segment.segmentId)"
                :is="getSegmentComponent(segment.segmentId)"
                :model="segment"
              ></component>
            </template>
          </div>
          <div v-else>
            <no-results
              v-if="searchStore?.searchResults?.isInvalidQuery && validTerm"
              :message="model.badResultsMessage"
            />
            <no-results v-else-if="!validTerm" :message="model.noTermMessage" />
            <no-results
              v-else
              :message="model.noResultsMessage"
              :previousTerm="state.searchParams?.q"
            />
          </div>
        </template>
        <template v-else>
          <div v-if="searchStore.isError">
            <no-results :message="model.noResultsMessage" />
          </div>
          <div v-else>
            <no-results
              v-if="query == ''"
              :message="model.noTermMessage"
              :previousTerm="state.searchParams?.q"
            />

            <SkeletonLoader v-else>
              <SearchPageSkeleton />
            </SkeletonLoader>
          </div>
        </template>
      </div>
      <div class="mt-12 lg:col-span-3 lg:col-start-10">
        <popular-searches-desktop
          v-show="mediaStore.isDesktop"
          :model="model.popularSearchTerms"
          @new-search="newSearch"
        />
      </div>
    </div>
  </main>
</template>
<script setup lang="ts">
import * as routeHelper from '@shared/api/route-helper'
import * as searchRequestBuilder from '@shared/api/search-request-builder'
import * as webApi from '@shared/api/web-api'
import SearchPageSkeleton from '@TodayInTheWord/components/icons/search-page-skeleton.vue'
import PopularSearchesDesktop from '@TodayInTheWord/components/pages/search/segments/popular-searches/popular-searches-desktop.vue'
import PopularSearchesMobile from '@TodayInTheWord/components/pages/search/segments/popular-searches/popular-searches-mobile.vue'
import SegmentLinkList from '@TodayInTheWord/components/pages/search/segments/segment-link-list.vue'
import top_pages from '@TodayInTheWord/components/pages/search/segments/top-pages/top-pages.vue'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import SharedButton from '@TodayInTheWord/components/shared/s-button.vue'
import SkeletonLoader from '@TodayInTheWord/components/shared/skeleton-svg.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as indexTypes from '@TodayInTheWord/types/index-search-types'
import { computed, onMounted, ref } from 'vue'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import searchFiltersComponent from '../shared/search-filter-component/search-filters-component.vue'
import ErrorMessage from './error-message.vue'
import NoResults from './no-results.vue'
import articles from './segments/articles/articles-preview.vue'
import bible_verses from './segments/bible-verses/bible-verses-preview.vue'
import daily_devotionals from './segments/daily-devotionals/daily-devotionals-preview.vue'
import invalid_segment from './segments/invalid/invalid-preview.vue'
import monthly_studies from './segments/monthly-studies/monthly-studies-preview.vue'
import pages from './segments/pages/pages-preview.vue'
import q_and_a from './segments/q-and-a/q-and-a-preview.vue'
import videos from './segments/videos/videos-preview.vue'

const headerStore = useHeaderStore()
const mediaStore = useMediaSizeStore()
const epiPageContext = useEpiPageContextStore()
const breadcrumbs = epiPageContext.breadcrumbs
const model = epiPageContext.model
const router = useRouter()
const route = useRoute()
const searchStore = useSearchPageStore()
interface ISearchPageState {
  searchResults: indexTypes.SearchResults | null
  searchParams: searchRequestBuilder.ISearchParams
  isLoading: boolean
  segmentPath: string
}
const state = ref<ISearchPageState>({
  searchResults: null,
  isLoading: false,
  searchParams: {},
  segmentPath: '',
})
const components = {
  top_pages,
  articles,
  //bible_search,
  bible_verses,
  daily_devotionals,
  monthly_studies,
  pages,
  q_and_a,
  videos,
}
const errorMessage = computed(() => {
  return searchStore.searchResults?.errorMessage
})
const currentPage = ref(route.query.p || 1)
const query = ref(route.query.q || '')
function setUrlToMatchSearchParams(searchParams: searchRequestBuilder.ISearchParams) {
  const newLocation: RouteLocationRaw = {
    path: state.value.segmentPath,
    query: {},
  }
  newLocation.query.q = searchParams.q
  if (searchParams.c && searchParams.c.length > 0) {
    newLocation.query.c = searchParams.c.join()
  }
  if (searchParams.d !== '') {
    newLocation.query.d = searchParams.d
  }
  if (searchParams.t !== '') {
    newLocation.query.t = searchParams.t
  }
  if (searchParams.a && searchParams.a.length > 0) {
    newLocation.query.a = searchParams.a.join()
  }
  router.push(newLocation)
}
async function onSearchFiltersChanged(
  searchParams: searchRequestBuilder.ISearchParams,
  eventName: EventName,
) {
  state.value.searchParams.q = searchParams.q
  state.value.searchParams.c = searchParams.c
  state.value.searchParams.p = searchParams.p
  state.value.searchParams.d = searchParams.d
  state.value.searchParams.t = searchParams.t
  state.value.searchParams.a = searchParams.a
  getSegmentUrlPath(searchParams.t)
  setUrlToMatchSearchParams(searchParams)
  fireSearchRequest()
}
function getSegmentUrlPath(selectedTabId: string) {
  model.segmentLinks.forEach((link) => {
    if (link.segmentId === selectedTabId) {
      state.value.segmentPath = link.url
    }
  })
}

function segmentHasResults(segmentId) {
  const segment = searchStore.searchResults?.results[segmentId]
  return segment && segment.totalCount > 0
}

async function fireSearchRequest() {
  state.value.isLoading = true
  searchStore.searchAllSegments(state.value.searchParams)
}
const initializePageState = () => {
  state.value.searchParams.q = routeHelper.getFirstQueryParameter(route.query, 'q')
  state.value.searchParams.c = routeHelper.getFirstQueryParamAsStringArray(route.query, 'c')
  state.value.searchParams.a = routeHelper.getFirstQueryParamAsStringArray(route.query, 'a')
  state.value.searchParams.p = routeHelper.getFirstQueryParamAsNumber(route.query, 'p', 1)
  state.value.searchParams.d = routeHelper.getFirstQueryParameter(route.query, 'd')
  const queryParamT = routeHelper.getFirstQueryParameter(route.query, 't')
  if (!queryParamT) {
    state.value.searchParams.t = 'All'
  } else {
    state.value.searchParams.t = queryParamT
  }
}
onMounted(async () => {
  searchStore.setApiUrls(model.segmentApiUrls)
  searchStore.setCurrentSegmentId('all')
  initializePageState()
  await searchStore.searchAllSegments(state.value.searchParams)
})
const newSearch = (query) => {
  router.push({ query: { q: query } })
}
const getSegmentComponent = (segmentId) => {
  if (segmentId && components) {
    const component = components[segmentId]
    if (component) {
      return component
    }
  }
  return invalid_segment
}

const hasResults = computed(() => {
  let results = searchStore.searchResults?.results
  if (!results || typeof results !== 'object') {
    return false
  }
  for (const key in results) {
    if (results.hasOwnProperty(key)) {
      const item = results[key]
      if (item && typeof item === 'object' && item.hasOwnProperty('totalCount')) {
        if (item.totalCount > 0) {
          return true
        }
      }
    }
  }
  return false
})
const validTerm = computed(() => {
  return typeof state.value.searchParams?.q !== 'undefined'
})
const validQuery = computed(() => {
  return searchStore?.searchResults?.isInvalidQuery
})
</script>
