<template>
  <div class="w-full pb-8 lg:pb-12" v-if="model">
    <div class="w-full">
      <segment-header :model="model" />
    </div>
    <div>
      <c2-slider
        :slide-total="items?.length"
        :slides-to-show="3"
        :slides-to-show-tablet="2"
        :slides-to-show-mobile="1"
        :slide-speed="500"
        :gutter="mediaSizeStore.isMobile ? 16 : 24"
        :media-tablet-query="1023"
        :media-mobile-query="639"
        :show-overflow="true"
        :show-button-nav="true"
        :showIndicators="false"
        :next-btn-classes="'hidden'"
        :prev-btn-classes="'hidden'"
        :nav-classes="'flex justify-end mt-4 relative md:mt-14 md:mb-0'"
        :button-nav-wrapper-classes="'max-w-full absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 flex align-center'"
        :button-classes="'relative h-6 w-[37px] mx-0.5 after:rounded after:block after:h-1 after:w-full after:bg-grey-300 after:absolute after:top-1/2 after:transform after:-translate-y-1/2'"
        :active-button-classes="'after:!bg-light-blue-600'"
        class="relative w-full -translate-x-8 transform pl-8 md:translate-x-0 md:pl-0"
      >
        <template v-slot:slide>
          <monthly-studies-card
            v-for="item in items"
            :model="item"
            class="group relative flex grow flex-col justify-between rounded-xl"
          />
        </template>
      </c2-slider>
    </div>
  </div>
</template>

<script setup lang="ts">
import SearchHitTracking from '@shared/api/search-hit-tracking'
import Tag from '@TodayInTheWord/components/shared/tag.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import C2Slider from 'uikit/c2-slider/c2-slider.vue'
import { computed } from 'vue'
import segmentHeader from '../segment-header.vue'
import MonthlyStudiesCard from './monthly-studies-card.vue'

const searchStore = useSearchPageStore()
const mediaSizeStore = useMediaSizeStore()

const items = computed((): searchTypes.TitwSearchResultItemModel[] | null => {
  return searchStore?.searchResults?.results?.monthly_studies?.items ?? null
})

const emit = defineEmits(['changeTab'])

const props = defineProps({
  model: {
    type: Object,
    default: () => {},
  },
})
</script>

<style lang="scss"></style>
