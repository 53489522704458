<template>
  <div class="lg:hidden">
    <c2-accordion-item
      v-if="links && links.length > 0"
      :button-classes="[
        'flex items-center w-full justify-between py-6 border-b border-solid border-grey-600',
        { 'border-t': props.hasTopBorder },
      ]"
      :panel-classes="'w-full'"
      class="w-full"
      @click="updateOpenStatus()"
    >
      <template v-slot:button>
        <span class="text-grey-500 text-sm font-semibold uppercase">
          {{ title }}
        </span>
        <span
          class="h-5 w-5 rotate-0 duration-300 ease-in-out"
          :class="{ '-rotate-180': state.isOpen }"
        >
          <ChevronDownIcon class="fill-grey-900" />
        </span>
      </template>

      <template v-slot:content>
        <ul class="flex flex-col pt-3">
          <li v-for="(item, index) in links" :key="index" class="mb-3">
            <s-link
              :href="item.href"
              :target="item.target"
              :external-link="item.isExternal"
              class="text-grey-700 text-sm"
            >
              {{ item.text }}
            </s-link>
          </li>
        </ul>
      </template>
    </c2-accordion-item>
  </div>
</template>

<script setup lang="ts">
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { mobileAccordionProps } from '@TodayInTheWord/stores/footer/footer-types'
import C2AccordionItem from 'uikit/c2-accordion/c2-accordion-item.vue'
import { ref } from 'vue'

const props = defineProps<mobileAccordionProps>()

const state = ref({
  isOpen: false,
})

function updateOpenStatus() {
  state.value.isOpen = !state.value.isOpen
}
</script>
