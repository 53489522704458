import BibleSnippet from '@TodayInTheWord/components/shared/bible-snippet.vue'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import axios from 'axios'
import { defineStore } from 'pinia'
import { createApp, defineComponent } from 'vue'

export const useBibleGatewayStore = defineStore('BibleGateway', {
  state: () => ({
    model: {},
    activeSection: null,
    activeBook: null,
    activeChapter: null,
    activeVersion: null,
    activeVersionDisplay: null,
    activeVersionCopyright: null,
    selectedSection: null,
    selectedBook: null,
    passage: null,
    booksMenu: [],
    versionsMenu: [],
    snippetResponse: [],
    reader: {},
    versionMenu: false,
    verseMenu: false,
    mobileVerseMenu: false,
    mobileVersionMenu: false,
    isLoading: false,
    isLoadError: false,
    relatedResources: [],
    apiUrlPrefix: '',
    queryParams: null,
  }),
  actions: {
    clearBookMenu() {
      for (const menuIndex in this.booksMenu) {
        const booksMenu = this.booksMenu[menuIndex]
        booksMenu.isActive = false
        for (const sectionIndex in booksMenu.sections) {
          const section = booksMenu.sections[sectionIndex]
          section.isActive = false
          for (const bookIndex in section.books) {
            const book = section.books[bookIndex]
            book.isActive = false
            book.chapters = [...Array(book.chaptersCount).keys()].map((x) => {
              return { name: x + 1 }
            })
          }
        }
      }
    },
    clearVersionMenu() {
      for (const menuIndex in this.versionsMenu) {
        const versionMenu = this.versionsMenu[menuIndex]
        versionMenu.isActive = false
      }
    },
    setBookMenu(selectedBook, reload) {
      this.clearBookMenu()
      for (const contentIndex in this.reader) {
        const content = this.reader[contentIndex]
        let currentBook = content.current.name

        if (selectedBook) {
          currentBook = selectedBook
        }

        const chapters = content.current.passages.map((x) => x.chapter).sort()

        for (const menuIndex in this.booksMenu) {
          const booksMenu = this.booksMenu[menuIndex]
          for (const sectionIndex in booksMenu.sections) {
            const section = booksMenu.sections[sectionIndex]

            for (const bookIndex in section.books) {
              const book = section.books[bookIndex]

              if (book.name === currentBook) {
                booksMenu.isActive = true
                this.selectedSection = section.name

                if (reload) {
                  this.activeBook = book.display
                  this.selectedBook = book.display
                }

                for (let ch = 1; ch <= book.chaptersCount; ch++) {
                  const chapter = book.chapters.find((x) => x.name === ch)
                  if (chapters.length === 1 && ch === chapters[0]) {
                    chapter.isActive = true
                  }
                  if (chapters.length === 2 && ch >= chapters[0] && ch <= chapters[1]) {
                    chapter.isActive = true
                  }
                }
              }
            }
          }
        }
      }
    },
    setVersionMenu() {
      this.clearVersionMenu()
      for (const contentIndex in this.reader) {
        const content = this.reader[contentIndex]

        const verName = content.passage.attribution.translation

        for (const menuIndex in this.versionsMenu) {
          const versionMenu = this.versionsMenu[menuIndex]
          if (versionMenu.name === verName) {
            versionMenu.isActive = true
            this.setActiveVersion(versionMenu)
          }
        }
      }
    },
    setActiveVersion(menu) {
      this.activeVersionCopyright = menu.copyright?.shortText
      this.activeVersionDisplay = menu.display
      this.activeVersion = menu.name
    },
    async fetchContent(fetchMenu) {
      window.scrollTo(0, 0)
      try {
        const data = await axios({
          method: 'post',
          url: `${this.apiUrlPrefix}ReadingContent`,
          headers: { Accept: 'application/json' },
          params: {
            menu: fetchMenu,
            pas: this.passage,
            ver: this.activeVersion,
          },
        })
        const response = data.data
        if (fetchMenu) {
          this.booksMenu = response.menu
        }
        this.reader = response.content
        this.relatedResources = response.relatedResources
        this.setBookMenu(null, true)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchVersions() {
      try {
        const data = await axios({
          method: 'post',
          url: `${this.apiUrlPrefix}Versions`,
          headers: { Accept: 'application/json' },
        })
        const response = data.data
        this.versionsMenu = response
      } catch (error) {
        console.error(error)
      }
    },
    async updateContent(fetchmenu) {
      this.isLoading = true
      await this.fetchContent(fetchmenu)
      this.router.push({
        query: { pas: this.passage, ver: this.activeVersion },
      })
      this.isLoading = false
    },
    async updateVersion() {
      this.isLoading = true
      await this.fetchVersions()
      this.setVersionMenu()
      this.isLoading = false
    },
    async initStore(model) {
      if (model && model.pageUrl) {
        this.apiUrlPrefix = model.pageUrl
      }

      this.isLoading = true
      await Promise.all([this.fetchContent(true), this.fetchVersions()])
      this.setVersionMenu()

      this.model = model
      this.isLoading = false
      this.isLoadError = false
    },
    async loadSnippet(searchText) {
      try {
        const data = await axios({
          method: 'get',
          url: `${this.apiUrlPrefix}/bible-api/bible/passages`,
          headers: { Accept: 'application/json' },
          params: {
            osis: searchText,
            translations: 'NIV',
          },
        })
        const response = data.data[0]
        this.snippetResponse = response
      } catch (error) {
        console.error(error)
      }
    },
    async findReferences(snippets) {
      const header = useHeaderStore().header

      for (let i = 0; i < snippets.length; i++) {
        const snippet = defineComponent({
          extends: BibleSnippet,
        })

        await this.loadSnippet(snippets[i].title)

        if (this.snippetResponse.passages && this.snippetResponse.passages.length > 0) {
          const passage = this.snippetResponse.passages[0]
          const bibleVersion = passage.attribution?.translation
          const content = passage.content

          const snippetModal = createApp(snippet, {
            text: snippets[i].innerHTML,
            title: snippets[i].title,
            content: content,
            page: header.bibleReadingExperiencePageLink.href,
            bibleVersion: bibleVersion,
          })

          let title = snippets[i].title
          let id = title.replace(/\s+/g, '').replace(/:/g, '-')
          id = `snip-ref-${i}-${id}`

          const container = document.createElement('div')
          container.setAttribute('id', id)

          const element = snippets.item(i)
          element.replaceWith(container)

          snippetModal.mount(`#${id}`)
        }
      }
    },
  },
})
