<template>
  <section v-if="model" class="space-y-5 lg:space-y-6">
    <section-heading v-if="model.sectionTitle" :heading="model.sectionTitle" />
    <div
      v-for="(author, index) in model.authors"
      :key="index"
      class="flex space-y-4 lg:space-x-6 lg:space-y-0"
      :class="{
        'flex-col': mediaSizeStore.isTablet || mediaSizeStore.isMobile,
      }"
    >
      <div
        class="flex w-1/6 items-center space-x-4 lg:max-h-[196px] lg:w-full lg:max-w-[196px] lg:space-x-0"
      >
        <s-image
          v-if="author.image"
          :model="author.image"
          class="aspect-square h-full w-full rounded-xl object-cover"
        />
        <h4
          v-if="author.title && mediaSizeStore.isMobile"
          class="text-grey-800 whitespace-nowrap text-base font-semibold"
        >
          {{ author.title }}
        </h4>
      </div>
      <div class="flex w-full flex-col justify-center space-y-4 lg:w-5/6">
        <h4
          v-if="author.title && !mediaSizeStore.isMobile"
          class="text-grey-800 text-xl font-semibold"
        >
          {{ author.title }}
        </h4>
        <p
          v-if="author.description"
          class="text-grey-700 m-0 line-clamp-4 text-base font-normal lg:line-clamp-3"
        >
          {{ author.description }}
        </p>
        <s-link
          v-if="author.link?.href"
          :href="author.link.href"
          :external-link="author.link.isExternal"
          :target="author.link.target"
          class="text-light-blue-900 flex w-fit items-center"
        >
          <span v-if="author.link.text">{{ author.link.text }}</span>
          <span><arrow-right-icon class="ml-2 h-5 w-5" /></span
        ></s-link>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ArrowRightIcon } from '@heroicons/vue/20/solid'
import SectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import sImage from '@TodayInTheWord/components/shared/s-image.vue'
import SLink from '@TodayInTheWord/components/shared/s-link.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { AboutTheAuthorsProps } from '../types/article-detail-types'

interface AuthorProps {
  model?: AboutTheAuthorsProps
}

const props = defineProps<AuthorProps>()
const mediaSizeStore = useMediaSizeStore()
</script>
