<template>
  <s-link
    v-if="model.url"
    :class="class"
    :href="model.url"
    :external-link="isExternal"
    @click.capture="$emit('trackHit', query, model.hitId, trackId)"
  >
    <div
      class="bg-light-blue-100 relative aspect-[16/9] w-full flex-shrink-0 overflow-hidden rounded-xl transition-all duration-300 ease-in-out group-hover:shadow-lg"
    >
      <picture v-if="model.image" class="flex">
        <img
          :src="imageUrl"
          :alt="model.title"
          class="aspect-[16/9] h-full w-full overflow-hidden rounded-xl"
        />
      </picture>
    </div>
    <div class="flex h-full flex-col p-2">
      <div class="flex h-full flex-col">
        <span
          v-if="model.title"
          class="text-grey-700 group-hover:text-light-blue-600 mb-1 font-medium"
        >
          {{ model.title }}
        </span>
      </div>
    </div>
  </s-link>
</template>

<script setup lang="ts">
import SearchHitTracking from '@shared/api/search-hit-tracking'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import SLink from '@TodayInTheWord/components/shared/s-link.vue'
import Tag from '@TodayInTheWord/components/shared/tag.vue'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import { DateTime } from 'luxon'
import { computed } from 'vue'
import segmentHeader from './segment-header.vue'
import TopPageCard from './top-page-card.vue'

const searchStore = useSearchPageStore()
const trackId = computed(() => {
  return searchStore?.searchResults?.trackId
})

const query = computed(() => {
  return searchStore?.searchResults?.query
})

const items = computed((): searchTypes.TitwSearchResultItemModel[] | null => {
  return searchStore?.searchResults?.results?.pages?.items ?? null
})

const isExternal = computed(() => {
  return props.model?.url && props.model.url.startsWith('http')
})

const imageUrl = computed(() => {
  if (props.model.image) {
    const hasQueryString = props.model.image.includes('?')
    return (
      props.model.image +
      (hasQueryString ? '&width=320&height=180&format=webp' : '?width=320&height=180&format=webp')
    )
  }
  return null
})

const emit = defineEmits(['changeTab', 'trackHit'])

const props = defineProps({
  model: {
    type: Object,
    default: () => {},
  },
  class: {
    type: String,
    default:
      'group relative flex grow flex-col justify-between rounded-xl mx-auto mb-4 w-full px-3 md:w-1/3',
  },
})
</script>

<style lang="scss"></style>
