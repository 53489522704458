import { defineStore } from 'pinia'
import { FooterState } from './footer-types'

const STORE_ID = 'FOOTER_STORE'

const defaultState: FooterState = {
  footer: null,
}

export const useFooterStore = defineStore(STORE_ID, {
  state: (): FooterState => defaultState,
  actions: {
    setStore(data: any) {
      console.log('footer data', data)

      if (!data) {
        return
      }
      this.footer = data.siteLayout.footer
    },
  },
})
