<template>
  <div v-if="tag && tag.displayName" :class="tagColor">
    {{ tag.displayName }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface TagProps {
  tag?: {
    displayName: string
    tagColor: string
  }
}

const props = defineProps<TagProps>()

const tagColor = computed(() => {
  switch (props.tag?.tagColor) {
    case 'light-green':
      return 'text-lime-100 bg-lime-600'
    case 'light-blue':
      return 'text-light-blue-900 bg-light-blue-100'
    case 'dark-blue':
      return 'text-light-blue-100 bg-light-blue-900'
    case 'dark-green':
      return 'text-lime-100 bg-lime-800'
    case 'gold':
      return 'text-white bg-amber-800'
    default:
      return ''
  }
})
</script>
