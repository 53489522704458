<template>
  <div class="container mx-auto mt-5 w-full space-y-8 lg:mt-6 lg:space-y-12">
    <div>
      <bread-crumbs :links="breadcrumbs.links" :currentPageName="breadcrumbs.currentPageName" />
    </div>
    <section
      class="border-lighter-grey bg-grey-50 space-y-10 rounded-xl border px-4 py-6 md:space-y-16 md:p-16"
    >
      <div class="space-y-3">
        <h1
          v-if="computedHeroTitle"
          class="w-full text-2xl font-semibold text-cyan-900 md:w-2/3 md:text-4xl"
        >
          {{ computedHeroTitle }}
        </h1>
        <div
          v-if="model.heroFeaturedSubheading"
          class="text-grey-600 w-full text-base font-normal md:w-2/3 md:text-xl"
        >
          {{ model.heroFeaturedSubheading }}
        </div>
      </div>
      <div
        v-if="model.heroFeaturedCategories && model.heroFeaturedCategories.length > 0"
        class="flex flex-col space-y-4 md:flex-row md:space-x-6 md:space-y-0"
      >
        <shared-link
          v-for="(featuredCategory, index) in model.heroFeaturedCategories"
          :key="index"
          :href="featuredCategory.href"
          :target="featuredCategory.target"
          :external-link="featuredCategory.isExternaal"
          :emphasis="'outline'"
          class="border-grey-100 flex w-full items-center justify-between rounded-xl border px-3 py-2 text-base text-cyan-900 shadow md:w-1/3 md:px-4 md:py-3 md:text-lg"
        >
          {{ featuredCategory.text }}
          <span class="ml-6 h-5 w-5"><arrow-long-right-icon /></span>
        </shared-link>
      </div>
    </section>

    <shared-search-component
      :search-component="model?.searchComponent"
      :request-type="'post'"
      @search-filter-change="fireSearchRequest"
    />

    <section class="space-y-8 md:space-y-12">
      <featured-category-slider
        v-for="(category, index) in model.featuredVerseCategories"
        :model="category"
        :key="index"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import { ArrowLongRightIcon } from '@heroicons/vue/20/solid'
import * as searchRequestBuilder from '@shared/api/search-request-builder'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import SharedLink from '@TodayInTheWord/components/shared/s-link.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import { computed } from 'vue'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import SharedSearchComponent from '../shared/search-filter-component/search-filters-component.vue'
import FeaturedCategorySlider from './components/featured-category-slider.vue'
import { BibleVerseIndexProps } from './types/bible-verse-index-types'

const epiPageContextStore = useEpiPageContextStore()
const router = useRouter()

const breadcrumbs: BreadcrumbProps = epiPageContextStore.breadcrumbs
const model: BibleVerseIndexProps['model'] = epiPageContextStore.model

const computedHeroTitle = computed(() => {
  return model?.heroFeaturedTopic ?? breadcrumbs.currentPageName
})

function setUrlToMatchSearchParams(searchParams: searchRequestBuilder.ISearchParams) {
  const newLocation: RouteLocationRaw = {
    path: model.searchComponent?.searchPageUrl,
    query: {},
  }

  newLocation.query.q = searchParams.q

  if (searchParams.c && searchParams.c.length > 0) {
    newLocation.query.c = searchParams.c.join()
  }

  if (searchParams.p) {
    newLocation.query.p = searchParams.p
  }

  router.push(newLocation)
}

function fireSearchRequest(searchParams: searchRequestBuilder.ISearchParams) {
  setUrlToMatchSearchParams(searchParams)
}
</script>

<style scoped></style>
