﻿import * as webApi from '@shared/api/web-api'
import { defineStore } from 'pinia'

export type accountManagementStoreModel = {
  options: any[]
  isLoading: boolean
  isLoadError: boolean
  savedSuccess: boolean
}

const defaultState: accountManagementStoreModel = {
  options: [],
  isLoading: false,
  isLoadError: false,
  savedSuccess: false,
}

export const useAccountStore = defineStore('AccountManagement', {
  state: (): accountManagementStoreModel => defaultState,
  actions: {
    async saveOptions(postData: any, pageUrl: string) {
      this.isLoading = true
      if (pageUrl && pageUrl.endsWith('/')) {
        pageUrl = pageUrl.substring(0, pageUrl.length - 1)
      }

      const apiResponse = await webApi.callAsync({
        method: 'post',
        url: `${pageUrl}/SaveProfile`,
        //headers: { Accept: 'application/json' },
        params: postData,
      })

      this.isLoading = false
      this.isLoadError = apiResponse.success
      this.savedSuccess = apiResponse.success === true

      if (apiResponse.errorMessage) {
        console.error(apiResponse.errorMessage)
      }
    },
    clearMessages() {
      this.isLoading = false
      this.isLoadError = false
      this.savedSuccess = false
    },
  },
})
