<template>
  <div class="container mx-auto mt-5 w-full space-y-8 lg:mt-6 lg:space-y-12">
    <div>
      <bread-crumbs :links="breadcrumbs.links" :currentPageName="breadcrumbs.currentPageName" />
    </div>
    <section
      class="border-lighter-grey bg-grey-50 space-y-10 rounded-xl border px-4 py-6 md:space-y-16 md:p-16"
    >
      <div class="space-y-3">
        <h1
          v-if="computedHeroTitle"
          class="w-full text-2xl font-semibold text-cyan-900 md:w-2/3 md:text-4xl"
        >
          {{ computedHeroTitle }}
        </h1>
        <div
          v-if="model.heroFeaturedSubheading"
          class="text-grey-600 w-full text-base font-normal md:w-2/3 md:text-xl"
        >
          {{ model.heroFeaturedSubheading }}
        </div>
      </div>
      <div
        v-if="model.heroFeaturedCategories && model.heroFeaturedCategories.length > 0"
        class="flex flex-col space-y-4 md:flex-row md:space-x-6 md:space-y-0"
      >
        <shared-link
          v-for="(featuredCategory, index) in model.heroFeaturedCategories"
          :key="index"
          :href="featuredCategory.href"
          :target="featuredCategory.target"
          :external-link="featuredCategory.isExternaal"
          :emphasis="'outline'"
          class="border-grey-100 flex w-full items-center justify-between rounded-xl border px-3 py-2 text-base text-cyan-900 shadow md:w-1/3 md:px-4 md:py-3 md:text-lg"
        >
          {{ featuredCategory.text }}
          <span class="ml-6 h-5 w-5"><arrow-long-right-icon /></span>
        </shared-link>
      </div>
    </section>
    <div ref="searchSection"></div>
    <shared-search-component
      :search-component="model?.searchComponent"
      :request-type="'post'"
      @search-filter-change="onSearchFiltersChanged"
    />

    <section
      v-if="
        state.searchResults && state.searchResults.items && state.searchResults.items.length > 0
      "
      class="md:space-y-12"
    >
      <div class="flex flex-col space-y-5 md:grid md:grid-cols-4 md:gap-6 md:space-y-0">
        <div v-for="(card, index) in state.searchResults.items" class="w-full md:col-span-1">
          <s-link
            :href="card.url"
            :external-link="!card.url.startsWith('/')"
            :key="index"
            class="group block w-full flex-col"
          >
            <div class="bg-light-blue-100 relative aspect-video w-full overflow-hidden rounded-xl">
              <shared-image
                v-if="card.image"
                :model="{ url: card.image }"
                class="aspect-video h-full w-full object-cover"
                format="?width=540&height=276&format=webp&compand=true"
              />
            </div>
            <div v-if="card.title" class="w-full space-y-2 p-2">
              <div
                v-if="card.title"
                class="text-grey-800 group-hover:text-light-blue-600 line-clamp-2 text-base font-medium transition-all duration-300"
              >
                {{ card.title }}
              </div>
            </div>
          </s-link>
        </div>
      </div>
      <shared-pagination
        class="mt-[66px]"
        :current-page="state.searchResults.page"
        :total-pages="state.searchResults.totalPages"
        @page-changed="gotoPage"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import { ArrowLongRightIcon } from '@heroicons/vue/20/solid'
import * as routeHelper from '@shared/api/route-helper'
import * as searchRequestBuilder from '@shared/api/search-request-builder'
import * as webApi from '@shared/api/web-api'
import { EventName } from '@TodayInTheWord/components/pages/shared/search-filter-component/types/search-filter-types'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import SharedLink from '@TodayInTheWord/components/shared/s-link.vue'
import SharedPagination from '@TodayInTheWord/components/shared/s-pagination.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import * as indexTypes from '@TodayInTheWord/types/index-search-types'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import { computed, onMounted, ref } from 'vue'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import SharedSearchComponent from '../shared/search-filter-component/search-filters-component.vue'
import { BibleVerseSearchPageProps } from './types/bible-verse-index-search-types'

const mediaSizeStore = useMediaSizeStore()
const epiPageContextStore = useEpiPageContextStore()
const route = useRoute()
const router = useRouter()

const breadcrumbs: BreadcrumbProps = epiPageContextStore.breadcrumbs
const model: BibleVerseSearchPageProps['model'] = epiPageContextStore.model

const requestedCategories = routeHelper.getFirstQueryParameter(route.query, 'c')
const query = ref(route.query.q)
const page = ref(routeHelper.getFirstQueryParamAsNumber(route.query, 'p', 1))
const searchSection = ref<HTMLElement | null>()

interface IBibleVerseIndexSearchPageState {
  searchResults: indexTypes.SearchResults | null
  searchParams: searchRequestBuilder.ISearchParams
  isLoading: boolean
  isFirstLoad: boolean
}

const state = ref<IBibleVerseIndexSearchPageState>({
  searchResults: null,
  searchParams: {},
  isLoading: false,
  isFirstLoad: true,
})

const initializePageState = () => {
  state.value.searchParams.q = routeHelper.getFirstQueryParameter(route.query, 'q')
  state.value.searchParams.c = routeHelper.getFirstQueryParamAsStringArray(route.query, 'c')
  state.value.searchParams.p = routeHelper.getFirstQueryParamAsNumber(route.query, 'p', 1)
}

const gotoPage = async (page: number) => {
  if (page <= 0) {
    return
  }

  const newLocation: RouteLocationRaw = {
    path: route.path,
    query: {
      q: route.query.q,
    },
  }

  if (page > 1 && newLocation.query) {
    newLocation.query.p = page
  }

  if (requestedCategories && newLocation.query) {
    newLocation.query.c = requestedCategories
  }

  await onSearchFiltersChanged(newLocation.query, 'applyFilters')
}

function setUrlToMatchSearchParams(searchParams: searchRequestBuilder.ISearchParams) {
  const newLocation: RouteLocationRaw = {
    path: route.path,
    query: {},
  }

  newLocation.query.q = searchParams.q

  if (searchParams.c && searchParams.c.length > 0) {
    newLocation.query.c = searchParams.c.join()
  }

  if (searchParams.p) {
    newLocation.query.p = searchParams.p
  }

  router.replace(newLocation)
}

async function onSearchFiltersChanged(
  searchParams: searchRequestBuilder.ISearchParams,
  eventName: EventName,
) {
  state.value.searchParams.q = searchParams.q
  state.value.searchParams.c = searchParams.c
  state.value.searchParams.p = searchParams.p

  setUrlToMatchSearchParams(searchParams)
  await fireSearchRequest()
}

async function fireSearchRequest() {
  const searchParams = state.value.searchParams
  const searchApiUrl: string = model?.searchComponent?.searchApiUrl ?? route.path

  if (searchParams.c && searchParams.c.length === 1) {
    searchParams.c = searchParams.c.toString().split(',')
  }

  state.value.isLoading = true

  const request = searchRequestBuilder.createPostRequest(searchApiUrl, searchParams)

  const response = await webApi.callAsync<indexTypes.SearchResults>(request)

  state.value.searchResults = response.data
  if (response.data) {
    if (searchSection.value && state.value.isFirstLoad == false) {
      searchSection.value.scrollIntoView({
        behavior: 'instant',
        block: 'start',
      })
    }
  } else {
    state.value.isFirstLoad = false
  }
  state.value.isLoading = false
  state.value.isFirstLoad = false
}

const computedHeroTitle = computed(() => {
  return model?.heroFeaturedTopic ?? breadcrumbs.currentPageName
})

onMounted(async () => {
  initializePageState()
  await fireSearchRequest()
})
</script>

<style scoped></style>
