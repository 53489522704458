<template>
  <div>
    <div class="py-5" :class="{ 'border-grey-300': !mediaStore.isMobile }">
      <bread-crumbs :links="breadcrumbs.links" :currentPageName="breadcrumbs.currentPageName" />
    </div>
    <general-hero :model="model" />
    <div class="container mx-auto">
      <div class="pt-0 lg:grid lg:grid-cols-3 lg:gap-8 lg:pt-12">
        <aside class="sidebar col-span-1">
          <template v-if="!mediaStore.isDesktop && model.sideMenu.isValid">
            <mobile-dropdown-nav :menu-items="model.sideMenu.menuItems" />
          </template>
          <template v-else>
            <side-nav v-if="model.sideMenu.isValid" :model="model.sideMenu" />
            <content-area :model="model.sideMenu.content" />
          </template>
        </aside>
        <main class="col-span-2 -mx-4 flex flex-col space-y-8 md:space-y-16">
          <section class="container mx-auto">
            <div v-if="model.mainBodyMarkup">
              <RichTextBlock :model="{ richTextField: model.mainBodyMarkup }" />
            </div>
          </section>
          <content-area :model="model.pageContent" />
          <template v-if="mediaStore.isTablet">
            <content-area :model="model.sideMenu.content" />
          </template>
        </main>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import RichTextBlock from '@TodayInTheWord/components/blocks/rich-text-block/rich-text-block.vue'
import GeneralHero from '@TodayInTheWord/components/pages/general-detail/components/hero.vue'
import SideNav from '@TodayInTheWord/components/pages/general-detail/components/side-nav.vue'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import MobileDropdownNav from '@TodayInTheWord/components/shared/mobile-dropdown-nav.vue'
import ContentArea from '@TodayInTheWord/epi/content-area.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import { GeneralDetailProps } from './types/general-detail-types'

const model: GeneralDetailProps['model'] = useEpiPageContextStore().model
const breadcrumbs: BreadcrumbProps = useEpiPageContextStore().breadcrumbs
const mediaStore = useMediaSizeStore()
</script>

<style lang="scss">
.sidebar {
  .TITW.rich-text-block {
    padding: 0;
  }
}
</style>
