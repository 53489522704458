<template>
  <span v-if="model?.imageType === 'SVG'" v-html="model.xml" />
  <img v-else :src="imageUrl" :alt="model?.imageAlt" />
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
  model: {
    type: Object,
    default: () => ({}),
  },
  format: {
    type: String,
    default: null,
  },
})

const imageUrl = computed(() => {
  if (!props.model?.url || !props.format) {
    return props.model?.url
  }

  if (props.model.isExternal || props.model.url.endsWith('.svg')) {
    return props.model.url
  }

  return `${props.model.url}${props.format}`
})
</script>
