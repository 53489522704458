<template>
  <div v-if="model">
    <div
      v-if="model"
      class="border-b-lighter-grey flex w-full flex-col-reverse items-center gap-y-4 border-b pb-9 lg:flex-row lg:gap-x-12 lg:pb-12"
    >
      <div class="flex w-full flex-col">
        <div
          class="row item-center mb-3 flex justify-between lg:mb-4 lg:justify-normal lg:space-x-4"
        >
          <tag
            v-if="model.tag"
            :tag="{ displayName: model.tag, tagColor: 'light-blue' }"
            class="rounded-xl px-3 py-1"
          />
          <div v-if="model.date" class="flex items-center">
            {{ model.date }}
          </div>
        </div>
        <div class="mb-3 space-y-1 lg:mb-10 lg:space-y-2">
          <h3 v-if="pageTitle" class="font-semibold text-cyan-900 lg:text-4xl">
            {{ pageTitle }}
          </h3>
          <div v-if="model.description" class="text-grey-700 text-base font-normal lg:text-2xl">
            {{ model.description }}
          </div>
        </div>
        <div
          v-if="model.author?.imageUrl || model.author?.name"
          class="mb-3 flex items-center space-x-3 lg:mb-10"
        >
          <div class="aspect-square h-8 w-8 overflow-hidden rounded lg:h-12 lg:w-12">
            <s-image
              :model="{ url: model.author?.imageUrl }"
              class="h-full w-full object-cover"
              :preload="true"
            />
          </div>
          <span v-if="model.author?.name" class="text-grey-800 text-sm font-medium lg:text-base">{{
            model.author?.name
          }}</span>
        </div>
        <div class="flex space-x-2.5">
          <s-link
            v-if="model.link && model.link.href"
            :href="model.link.href"
            :external-link="model.link.isExternal"
            :target="model.link.target"
            :emphasis="'bold'"
            :style="'primary'"
            class="w-fit"
          >
            {{ model.link.text }}
          </s-link>
        </div>
      </div>
      <div
        class="aspect-video lg:w-7/12 lg:shrink-0 lg:overflow-hidden lg:rounded-xl"
        :class="{ '-mx-[18px]': mediaSizeStore.isMobile }"
      >
        <s-image :model="model.image" class="h-full w-full object-cover" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMediaSizeStore } from '@MoodyBible/stores/media-size'
import { HeaderProps } from '@TodayInTheWord/components/pages/article-index/types/article-index-types'
import sImage from '@TodayInTheWord/components/shared/s-image.vue'
import sLink from '@TodayInTheWord/components/shared/s-link.vue'
import tag from '@TodayInTheWord/components/shared/tag.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import { computed } from 'vue'

interface DevotionalHeaderProps {
  model?: HeaderProps
}

const mediaSizeStore = useMediaSizeStore()
const epiPageContextStore = useEpiPageContextStore()
const breadcrumbs: BreadcrumbProps = epiPageContextStore.breadcrumbs

const props = defineProps<DevotionalHeaderProps>()

const pageTitle = computed(() => {
  return props.model?.title ?? breadcrumbs.currentPageName
})
</script>

<style scoped></style>
