<template>
  <div v-if="contentSharing.isEnabled">
    <div
      class="absolute transition-all duration-300 ease-in-out"
      :class="[
        shareModal ? 'opacity-100' : 'pointer-events-none opacity-0',
        props.solid ? 'top-[-35px] lg:top-[-40px]' : 'top-[-24px] lg:top-[-30px]',
        props.featuredImageBtn ? 'right-0' : 'left-0',
      ]"
    >
      <div v-html="contentSharing.placementCode"></div>
    </div>
    <s-button
      v-if="props.solid"
      :class-override="'!p-2  lg:!p-3 shadow-md shareButton'"
      @click.prevent="toggleModal()"
    >
      <share-icon class="h-6 w-6"></share-icon>
    </s-button>
    <button v-else class="shareButton bg-transparent p-4" @click.prevent="toggleModal()">
      <share-icon class="stroke-light-blue-900 hover:stroke-light-blue-600 h-6 w-6" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { ShareIcon } from '@heroicons/vue/24/outline'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { ref } from 'vue'
import sButton from '../s-button.vue'
import { contentSharingProps } from './types/content-sharing-types'

const props = defineProps({
  solid: {
    type: Boolean,
    default: false,
  },
  featuredImageBtn: {
    type: Boolean,
    default: false,
  },
})

const shareModal = ref<boolean>(false)

const contentSharing: contentSharingProps = useEpiPageContextStore().contentSharing
const toggleModal = () => {
  shareModal.value = !shareModal.value

  if (contentSharing.isEnabled && window.__sharethis__) {
    window.__sharethis__.href = window.location.href
    window.__sharethis__.initialize()
  }
}
</script>
