<template>
  <s-link
    v-if="model.url"
    class="border-b-lighter-grey group block flex gap-x-3 border-b pb-5 lg:pb-6"
    :href="model.url"
    :external-link="isExternal"
    @click.capture="$emit('trackHit', query, model.hitId, trackId)"
  >
    <picture v-if="model.image" class="flex">
      <img
        :src="imageUrl"
        :alt="model.imageAlt"
        class="mb-2 aspect-[1/1] h-20 w-20 rounded-xl md:mb-0 md:mr-6 md:h-32 md:h-full md:w-32 md:w-full"
      />
    </picture>

    <div class="flex-1">
      <span
        v-if="model.title"
        class="text-grey-800 group-hover:text-light-blue-600 mb-1 line-clamp-1 text-base font-medium md:text-lg"
      >
        {{ model.title }}
      </span>
      <div v-if="model.description" class="text-grey-700 mb-3 line-clamp-3 text-sm">
        {{ model.description }}
      </div>
    </div>
  </s-link>
</template>

<script setup lang="ts">
import SearchHitTracking from '@shared/api/search-hit-tracking'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import SLink from '@TodayInTheWord/components/shared/s-link.vue'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import { computed } from 'vue'
import segmentHeader from './segment-header.vue'
import TopPageCard from './top-page-card.vue'

const searchStore = useSearchPageStore()
const trackId = computed(() => {
  return searchStore?.searchResults?.trackId
})

const query = computed(() => {
  return searchStore?.searchResults?.query
})

const isExternal = computed(() => {
  return props.model?.url && props.model.url.startsWith('http')
})

const emit = defineEmits(['changeTab', 'trackHit'])

const props = defineProps({
  model: {
    type: Object,
    default: () => {},
  },
})

const imageUrl = computed(() => {
  if (props.model.image) {
    const hasQueryString = props.model.image.includes('?')
    return (
      props.model.image +
      (hasQueryString ? '&width=128&height=128&format=webp' : '?width=128&height=128&format=webp')
    )
  }
  return undefined
})

const contentTypeTag = computed(() => {
  if (!props.model.contentType) {
    return null
  }

  return {
    displayName: props.model.contentType,
    tagColor: 'dark-blue',
  }
})
</script>

<style lang="scss"></style>
