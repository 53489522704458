<template>
  <div
    class="mx-auto w-full max-w-full space-y-8 md:max-w-[856px] lg:space-y-12"
    :class="{ container: mediaSizeStore.isMobile }"
  >
    <bread-crumbs
      :links="breadcrumbs.links"
      :currentPageName="computedBreadcrumb"
      class="mt-4 lg:mt-6"
    />
    <div class="space-y-3 border-b border-slate-200 pb-8 md:space-y-4 md:pb-12">
      <div
        class="text-light-blue-900 bg-light-blue-100 w-fit rounded-xl px-3 py-1 text-sm font-medium"
      >
        Q & A
      </div>
      <h1 class="text-lg font-semibold text-cyan-900 lg:text-2xl">
        {{ model.question }}
      </h1>
    </div>
    <div class="space-y-6 lg:space-y-10">
      <div class="space-y-4">
        <h2 class="text-grey-800 text-lg font-medium lg:text-2xl">
          {{ model.answerHeading }}
        </h2>
        <rich-text-block
          v-if="model.answer"
          :has-container="false"
          :model="{ richTextField: model.answer }"
        />
      </div>
      <div class="relative flex space-x-1">
        <share-this />
      </div>
    </div>
    <AboutTheAuthor :model="model.authorsSection" />
    <RelatedContent :model="model.relatedContent" />
  </div>
</template>

<script setup lang="ts">
import { ShareIcon } from '@heroicons/vue/24/outline'
import RichTextBlock from '@TodayInTheWord/components/blocks/rich-text-block/rich-text-block.vue'
import AboutTheAuthor from '@TodayInTheWord/components/pages/shared/about-the-author.vue'
import RelatedContent from '@TodayInTheWord/components/pages/shared/related-content.vue'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import ShareThis from '@TodayInTheWord/components/shared/share-this/s-share-this-button.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import { computed } from 'vue'
import { QADetailPageProps } from './types/QA-detail-types'

const model: QADetailPageProps['model'] = useEpiPageContextStore().model
const mediaSizeStore = useMediaSizeStore()
const breadcrumbs: BreadcrumbProps = useEpiPageContextStore().breadcrumbs

const computedBreadcrumb = computed(() => {
  return model?.cardTitle ?? breadcrumbs.currentPageName
})
</script>
