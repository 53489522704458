import { defineStore } from 'pinia'

export const useMediaSizeStore = defineStore('mediaSize', {
  state: () => ({
    isTablet: null,
    isMobile: null,
    isDesktop: null,
    isClient: false,
  }),
  actions: {
    checkMediaSize() {
      if (typeof window !== 'undefined') {
        this.isClient = true
      }
      if (window.matchMedia('(min-width: 769px) and (max-width: 1023px)').matches) {
        this.isTablet = true
        this.isMobile = false
        this.isDesktop = false
      } else if (window.matchMedia('(max-width: 768px)').matches) {
        this.isTablet = false
        this.isMobile = true
        this.isDesktop = false
      } else if (window.matchMedia('(min-width: 1024px)').matches) {
        this.isTablet = false
        this.isMobile = false
        this.isDesktop = true
      } else {
        this.isTablet = false
        this.isMobile = false
        this.isDesktop = true
        this.isClient = false
      }
    },
    initStore() {
      const self = this
      // init size on page load
      this.checkMediaSize()
      // changes value on resize
      window.addEventListener('resize', () => {
        self.checkMediaSize()
      })
    },
  },
})
