<template>
  <div class="w-full pb-8 lg:pb-12">
    <div class="w-full">
      <segment-header :model="model" />
    </div>
    <div class="flex flex-col space-y-6">
      <template v-for="item in items">
        <q-and-a-item :model="item" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import SearchHitTracking from '@shared/api/search-hit-tracking'
import Tag from '@TodayInTheWord/components/shared/tag.vue'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import C2Slider from 'uikit/c2-slider/c2-slider.vue'
import { computed } from 'vue'
import segmentHeader from '../segment-header.vue'
import QAndAItem from './q-and-a-item.vue'

const searchStore = useSearchPageStore()

const items = computed((): searchTypes.TitwSearchResultItemModel[] | null => {
  return searchStore?.searchResults?.results?.q_and_a?.items ?? null
})

const props = defineProps({
  model: {
    type: Object,
    default: () => {},
  },
})
</script>

<style lang="scss"></style>
