<template>
  <section
    v-if="model"
    v-bind:id="model.clientID"
    class="TITW large-callout-block relative overflow-hidden rounded-3xl"
    :class="{ 'bg-grey-100': model.backgroundColor !== 'white' }"
  >
    <picture v-if="model.image" class="absolute bottom-0 left-0 right-0 top-0">
      <shared-image
        :model="model.image"
        class="h-full w-full object-cover"
        format="?width=1300&height=520&format=webp&compand=true"
      />
    </picture>

    <div
      class="relative flex h-full w-full flex-col justify-center px-8 py-14 md:px-12 md:py-32"
      :class="[
        {
          'bg-gradient-radial from-black/50 to-black/80':
            model.image && model.backgroundColor !== 'white',
        },
        { 'items-center': model.contentPosition === 'center' },
        { 'items-end': model.contentPosition === 'right' },
      ]"
    >
      <div
        class="space-y-4"
        :class="[
          { 'max-w-[400px]': !disableContentMaxWidth },
          { 'text-center': model.contentTextAlignment === 'center' },
          { 'text-right': model.contentTextAlignment === 'right' },
          {
            'rounded-2xl bg-white/[0.8] p-6 backdrop-blur-sm md:bg-white/[0.6]':
              model.backgroundColor === 'white',
          },
        ]"
      >
        <h2
          v-if="model.calloutHeading"
          class="line-clamp-2 break-words text-xl font-semibold md:text-2xl"
          :class="[
            { 'text-grey-700': !model.image },
            { 'text-white': model.image && model.backgroundColor !== 'white' },
            { 'text-light-blue-900': model.backgroundColor === 'white' },
          ]"
        >
          {{ model.calloutHeading }}
        </h2>

        <p
          v-if="model.calloutSubheading"
          class="line-clamp-3 break-words text-sm md:text-base"
          :class="computedSubTextColor"
        >
          {{ model.calloutSubheading }}
        </p>

        <s-link
          v-if="model.calloutButton && model.calloutButton.href"
          :href="model.calloutButton.href"
          :target="model.calloutButton.target"
          :style="model.calloutButton.buttonStyle"
          :size="model.calloutButton.buttonSize"
          :emphasis="model.calloutButton.buttonEmphasis"
          :externalLink="model.calloutButton.isExternal"
        >
          {{ model.calloutButton.text }}
        </s-link>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import { computed } from 'vue'
import { LargeCalloutBlockProps } from './types/large-callout-block-types'

const props = defineProps<LargeCalloutBlockProps>()

const computedSubTextColor = computed(() => {
  if (props.model.image && props.model.backgroundColor === 'white') {
    return 'text-grey-700'
  } else if (props.model.image && props.model.backgroundColor !== 'white') {
    return 'text-white'
  } else {
    return 'text-grey-700'
  }
})
</script>
