<template>
  <nav class="container mx-auto hidden justify-between lg:flex">
    <ul
      v-if="header?.utilityMenu && header.utilityMenu.length > 0"
      class="flex items-center space-x-4 pb-1.5 pt-1.5"
    >
      <li v-for="(item, index) in header.utilityMenu" :key="index">
        <s-link
          v-if="item.text"
          :href="item.href"
          :target="item.target"
          :external-link="item.isExternal"
          class="hover:bg-light-blue-700 inline-flex cursor-pointer items-center justify-center rounded-xl px-3 py-1.5 text-xs text-white transition duration-300 ease-in-out hover:text-white"
        >
          {{ item.text }}
        </s-link>
      </li>
    </ul>
    <ul v-else class="flex items-center space-x-4 pb-1.5 pt-1.5"></ul>
    <ul
      v-if="headerStore.showAccountMenu || headerStore.showLanguageMenu"
      class="bg-color text-light-blue-50 flex items-center space-x-2 pb-1.5 pt-1.5"
    >
      <account-menu v-if="headerStore.showAccountMenu"></account-menu>
      <LanguageSelector v-if="headerStore.showLanguageMenu"></LanguageSelector>
    </ul>
    <ul v-else></ul>
  </nav>
</template>

<script setup lang="ts">
import AccountMenu from '@TodayInTheWord/components/shared/header/components/utility-nav/account-menu.vue'
import LanguageSelector from '@TodayInTheWord/components/shared/header/components/utility-nav/language-selector.vue'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'

const headerStore = useHeaderStore()
const header = headerStore.header
</script>
