<template>
  <section v-if="searchComponent" class="mb-8 flex flex-col space-y-6 lg:space-y-0">
    <div v-if="isDevMode" class="text-grey-800 mb-2 rounded-lg bg-slate-200 p-2 text-xs">
      <div>
        author(s):
        <strong
          ><code>{{ state.selectedAuthors }}</code></strong
        >
      </div>
      <div>
        category:
        <strong
          ><code>{{ state.selectedCategory }}</code></strong
        >
      </div>
      <div>
        subcategories:
        <strong
          ><code>{{ state.selectedSubcategories }}</code></strong
        >
      </div>
    </div>
    <div class="flex space-x-2 md:flex-col md:space-x-0 md:space-y-8">
      <div class="flex w-full">
        <div class="relative w-full">
          <input
            type="text"
            v-model="state.searchParams.q"
            :placeholder="searchComponent.searchPlaceholderText"
            class="text-grey border-grey-200 relative flex h-full max-h-12 w-full justify-between border py-3 pl-11 pr-3 font-normal shadow-sm"
            :class="mediaSizeStore.isMobile ? 'rounded-xl' : 'rounded-l-xl'"
            @input="sayt()"
            @keydown.enter="applySearchFilters()"
            @click.prevent.stop
          />
          <button
            class="absolute left-3 top-[14px] h-5 w-5"
            @click="applySearchFilters()"
            aria-label="Submit search"
          >
            <magnifying-glass-icon class="text-grey-500" />
          </button>
          <button
            v-if="state.searchParams.q"
            class="absolute right-3 top-[14px] h-5 w-5"
            @click="clearSearchInput()"
            aria-label="Clear Search"
          >
            <x-mark-icon class="text-grey-500" />
          </button>
        </div>
        <shared-button
          v-if="searchComponent.searchButtonText && !mediaSizeStore.isMobile"
          :emphasis="'bold'"
          :size="'regular'"
          :style="'primary'"
          :class-override="'w-fit !rounded-l-none !rounded-r-xl !py-2.5'"
          @click="applySearchFilters()"
          @keyup.enter="applySearchFilters()"
        >
          {{ searchComponent.searchButtonText }}
        </shared-button>
      </div>
      <div class="flex justify-end" v-if="filtersComponent">
        <p class="hidden">{{ state.searchParams.t }}</p>
        <div
          v-if="!mediaSizeStore.isMobile && props.segmentLinkTabs"
          class="flex w-full items-center space-x-3"
        >
          <button
            v-for="(tab, id) in props.segmentLinkTabs"
            v-show="segmentHasResults(id, tab)"
            :key="id"
            :style="'outline'"
            :emphasis="'outline'"
            @click.prevent="handleTabData(tab)"
            class="whitespace-nowrap rounded-xl border-none px-3 py-2 text-sm transition lg:text-base"
            :class="
              tab.isActive === true
                ? 'bg-cyan-600 text-white'
                : 'text-grey-700 hover:text-light-blue-600 bg-none'
            "
          >
            {{ tab.displayText }}
          </button>
        </div>
        <shared-button
          :emphasis="'tonal'"
          :size="'regular'"
          :style="'primary'"
          :class-override="'w-fit flex justify-between items center space-x-6 relative'"
          @click="openFilterModal()"
        >
          <span class="h-5 w-5">
            <adjustments-horizontal-icon />
          </span>
          <span v-if="searchComponent.filtersButtonText && !mediaSizeStore.isMobile">
            {{ searchComponent.filtersButtonText }}
          </span>
          <div
            v-if="totalFilters !== 0"
            class="text-grey-50 absolute -top-1/4 right-0 rounded-full bg-cyan-900 px-[6px] text-sm font-medium"
          >
            {{ totalFilters }}
          </div>
        </shared-button>
      </div>
    </div>
    <div
      v-if="mediaSizeStore.isMobile"
      class="flex w-full items-center space-x-4 overflow-x-scroll pb-3 lg:pb-0"
    >
      <template v-if="props.segmentLinkTabs && props.segmentLinkTabs.length > 0">
        <button
          v-for="(tab, id) in props.segmentLinkTabs"
          v-show="segmentHasResults(id, tab)"
          :key="id"
          :style="'outline'"
          :emphasis="'outline'"
          @click.prevent="handleTabData(tab)"
          class="whitespace-nowrap rounded-xl px-3 py-2 text-sm transition lg:text-base"
          :class="
            tab.isActive === true
              ? 'bg-cyan-600 text-white'
              : 'text-grey-700 hover:text-light-blue-600 bg-none'
          "
        >
          {{ tab.displayText }}
        </button>
      </template>
    </div>
  </section>

  <c2-modal
    v-if="filtersComponent"
    ref="filterModal"
    :background-overlay-color="'rgba(0,0,0,0.5)'"
    :open-btn-classes="'hidden'"
    :close-btn-classes="'hidden'"
    :wrapper-classes="'lg:rounded-xl'"
    :header-classes="'hidden'"
    :transition-type="'zoom'"
    :max-width="500"
  >
    <template v-slot:body>
      <div
        class="border-grey-200 relative w-full overflow-y-scroll border bg-white px-[18px] pb-20 pt-8 shadow-lg md:space-y-4 md:px-6"
        :class="modalContainerStyles"
      >
        <div
          class="text-grey-800 flex w-full items-center bg-white font-medium md:text-lg"
          :class="[
            filtersComponent?.filtersModalHeader ? 'justify-between' : 'justify-end',
            { 'fixed left-0 top-0 z-[99] pl-6 pr-3': !mediaSizeStore.isMobile },
          ]"
        >
          {{ filtersComponent?.filtersModalHeader }}
          <span class="flex items-center p-3" @click="handleUnsavedChanges()">
            <button class="h-6 w-6">
              <XMarkIcon class="fill-light-blue-900" />
            </button>
            <div class="sr-only">Close Filter Modal</div>
          </span>
        </div>
        <div v-if="isDevMode" class="text-grey-800 mb-2 rounded-lg bg-slate-200 p-2 text-xs">
          <div>
            author(s):
            <strong
              ><code>{{ state.selectedAuthors }}</code></strong
            >
          </div>
          <div>
            category:
            <strong
              ><code>{{ state.selectedCategory }}</code></strong
            >
          </div>
          <div>
            subcategories:
            <strong
              ><code>{{ state.selectedSubcategories }}</code></strong
            >
          </div>
        </div>
        <div
          v-if="
            props.searchComponent?.filtersComponent?.filterDateLabel &&
            computedDates &&
            computedDates.length > 0
          "
          class="text-grey-800 text-sm md:text-base"
        >
          {{ props.searchComponent?.filtersComponent?.filterDateLabel }}
        </div>
        <div v-if="computedDates && computedDates.length > 0" class="relative w-full">
          <button
            class="border-light-blue-900 flex w-full justify-between rounded-xl border px-3 py-2"
            @click="updateDateOpenStatus"
          >
            <span
              v-if="state.searchParams.d === '' || state.searchParams.d === undefined"
              class="title text-grey-800 text-sm font-medium"
            >
              {{ filtersComponent?.filterDatePlaceholder }}
            </span>
            <span v-else class="title text-grey-800 text-sm font-medium">
              {{ state.searchParams.d }}
            </span>
            <span class="icon">
              <ChevronDownIcon
                class="h-5 w-5 transition duration-300 ease-in-out"
                :class="{ 'rotate-180': state.isDateOpen }"
              />
            </span>
          </button>

          <div
            class="border-grey-200 absolute z-10 w-full rounded-b-xl border bg-white shadow-md transition-all duration-300"
            :class="
              state.isDateOpen ? 'cursor-pointer opacity-100' : 'pointer-events-none opacity-0'
            "
          >
            <div
              v-for="(date, index) in computedDates"
              :value="date"
              :key="index"
              class="text-light-blue-900 hover:bg-grey-100 px-3 py-2 text-sm font-medium capitalize"
              :class="{
                'bg-grey-100': state.searchParams.d === date,
              }"
              @click="handleSelectDate(date)"
            >
              {{ date }}
            </div>
          </div>
        </div>
        <c2-accordion-item
          v-if="computedAuthors && computedAuthors.length > 0"
          :button-classes="'flex items-center w-full justify-between'"
          :panel-classes="'w-full'"
          :is-open="true"
          class="w-full"
        >
          <template v-slot:button>
            <span class="flex w-full justify-between py-3 pr-3" @click="updateAuthorsOpenStatus()">
              <span class="title text-grey-800 text-sm font-medium">
                {{ filtersComponent?.filterAuthorLabel }}
              </span>
              <span class="icon relative h-5 w-5">
                <MinusIcon
                  class="absolute transition duration-300 ease-in-out"
                  :class="state.isAuthorsOpen ? 'opacity-100' : 'opacity-0'"
                />
                <PlusIcon
                  class="absolute transition duration-300 ease-in-out"
                  :class="!state.isAuthorsOpen ? 'opacity-100' : 'opacity-0'"
                />
              </span>
            </span>
          </template>

          <template v-slot:content>
            <div class="flex flex-wrap gap-4 lg:gap-x-6 lg:gap-y-4">
              <div
                v-for="(author, index) in computedAuthors"
                :key="index"
                class="flex w-full max-w-[161px] items-center space-x-3 lg:max-w-[203px]"
              >
                <input
                  type="checkbox"
                  :id="`authors_${index}`"
                  class="h-4 w-4"
                  :value="author"
                  v-model="state.selectedAuthors"
                />
                <label :for="`authors_${index}`" class="text-grey-800 text-sm md:text-base">{{
                  author
                }}</label>
              </div>
            </div>

            <button
              v-if="filtersComponent?.filterShowMoreText && state.showMoreAuthorBtn"
              class="hover:text-light-blue-600 my-4 flex justify-center whitespace-nowrap border-none p-1 text-cyan-900 transition-all duration-300 ease-in-out hover:bg-transparent"
              @click="state.openMoreAuthors = !state.openMoreAuthors"
            >
              <template v-if="!state.openMoreCategories">
                {{ filtersComponent.filterShowMoreText }}
              </template>
              <template v-else> Show Less </template>
              <span class="ml-2 h-5 w-5"
                ><chevron-down-icon
                  class="transition duration-300"
                  :class="{ 'rotate-180': state.openMoreAuthors }"
              /></span>
            </button>
          </template>
        </c2-accordion-item>
        <c2-accordion-item
          :button-classes="'flex items-center w-full justify-between'"
          :panel-classes="'w-full'"
          :is-open="true"
          class="w-full"
        >
          <template v-slot:button>
            <span class="flex w-full justify-between py-3 pr-3" @click="updateCategoryOpenStatus">
              <span class="title text-grey-800 text-sm font-medium">{{
                filtersComponent?.filterCategoryLabel
              }}</span>
              <span class="icon relative h-5 w-5">
                <MinusIcon
                  class="absolute transition duration-300 ease-in-out"
                  :class="state.isCategoryOpen ? 'opacity-100' : 'opacity-0'"
                />
                <PlusIcon
                  class="absolute transition duration-300 ease-in-out"
                  :class="!state.isCategoryOpen ? 'opacity-100' : 'opacity-0'"
                />
              </span>
            </span>
          </template>

          <template v-slot:content>
            <div class="space-y-3">
              <div
                v-for="(category, index) in filtersComponent?.categories?.filter((c) => c.name)"
                :key="index"
                class="flex items-center space-x-3"
              >
                <input
                  type="radio"
                  :id="`categories_${category.id}`"
                  class="h-4 w-4"
                  :value="category.name"
                  name="category"
                  v-model="state.selectedCategory"
                />
                <label
                  :for="`categories_${category.id}`"
                  class="text-grey-800 text-sm md:text-base"
                  >{{ category.name }}</label
                >
              </div>
            </div>
          </template>
        </c2-accordion-item>
        <c2-accordion-item
          v-if="subcategories && subcategories.length > 0"
          :button-classes="'flex items-center w-full justify-between'"
          :panel-classes="'w-full'"
          :is-open="true"
          class="w-full"
        >
          <template v-slot:button>
            <span
              class="flex w-full justify-between py-3 pr-3"
              @click="updateSubcategoryOpenStatus"
            >
              <span class="title text-grey-800 text-sm font-medium">
                {{ filtersComponent?.filterSubcategoryLabel }}
              </span>
              <span class="icon relative h-5 w-5">
                <MinusIcon
                  class="absolute transition duration-300 ease-in-out"
                  :class="state.isSubcategoryOpen ? 'opacity-100' : 'opacity-0'"
                />
                <PlusIcon
                  class="absolute transition duration-300 ease-in-out"
                  :class="!state.isSubcategoryOpen ? 'opacity-100' : 'opacity-0'"
                />
              </span>
            </span>
          </template>

          <template v-slot:content>
            <div class="flex flex-wrap gap-4 lg:gap-x-6 lg:gap-y-4">
              <div
                v-for="(subcategory, index) in filteredSubcategories"
                :key="subcategory.id"
                class="flex w-full max-w-[161px] items-center space-x-3 lg:max-w-[203px]"
              >
                <input
                  type="checkbox"
                  :id="`subcategories_${subcategory.id}`"
                  class="h-4 w-4"
                  :value="subcategory.name"
                  v-model="state.selectedSubcategories"
                />
                <label
                  :for="`subcategories_${subcategory.id}`"
                  class="text-grey-800 text-sm md:text-base"
                  >{{ subcategory.name }}</label
                >
              </div>
            </div>

            <button
              v-if="filtersComponent?.filterShowMoreText && showMoreSubcategoryBtn"
              class="hover:text-light-blue-600 my-4 flex justify-center whitespace-nowrap border-none p-1 text-cyan-900 transition-all duration-300 ease-in-out hover:bg-transparent"
              @click="state.openMoreCategories = !state.openMoreCategories"
            >
              <template v-if="!state.openMoreCategories">
                {{ filtersComponent.filterShowMoreText }}
              </template>
              <template v-else> Show Less </template>
              <span class="ml-2 h-5 w-5"
                ><chevron-down-icon
                  class="transition duration-300"
                  :class="{ 'rotate-180': state.openMoreCategories }"
              /></span>
            </button>
          </template>
        </c2-accordion-item>
        <div
          class="border-t-lighter-grey fixed bottom-0 right-0 mt-4 flex w-full justify-end space-x-4 border-t bg-white py-4 pr-6"
        >
          <shared-button
            v-if="filtersComponent?.filterClearSelection"
            :emphasis="'outline'"
            :size="'regular'"
            :style="'neutral'"
            @click="clearFilters()"
            >{{ filtersComponent.filterClearSelection }}</shared-button
          >
          <shared-button
            v-if="filtersComponent?.filterApplyFilters"
            :emphasis="'bold'"
            :size="'regular'"
            :style="'primary'"
            @click="applySearchFilters()"
          >
            {{ filtersComponent.filterApplyFilters }}
          </shared-button>
        </div>
      </div>
    </template>
  </c2-modal>
</template>
<script setup lang="ts">
import { SearchSegmentLinkItem } from '@brand/components/pages/search/types/search-component-types'
import {
  AdjustmentsHorizontalIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/vue/20/solid'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import * as routeHelper from '@shared/api/route-helper'
import { getFirstQueryParameter } from '@shared/api/route-helper'
import { ISearchParams } from '@shared/api/search-request-builder'
import SharedButton from '@TodayInTheWord/components/shared/s-button.vue'
import debounce from '@TodayInTheWord/scripts/debounce'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import C2AccordionItem from 'uikit/c2-accordion/c2-accordion-item.vue'
import C2Modal from 'uikit/c2-modal/c2-modal.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import {
  CategoryModel,
  EventName,
  FiltersComponentProps,
  SearchComponentProps,
  SubcategoryModel,
} from './types/search-filter-types'

const searchStore = useSearchPageStore()
interface SearchComponent {
  searchComponent?: SearchComponentProps
  requestType?: string
  currentTab?: string
  segmentLinkTabs?: SearchSegmentLinkItem[]
  hideEmptyTabs?: boolean
  restrictEmptyTermSearch?: boolean
}

const isDevMode = ref<boolean>(false)
const props = defineProps<SearchComponent>()

const emit = defineEmits<{
  changePage: [page: number]
  searchFilterChange: [searchParams: ISearchParams, eventName: EventName]
}>()

const filtersComponent = computed((): FiltersComponentProps | undefined => {
  return props.searchComponent?.filtersComponent
})

const mediaSizeStore = useMediaSizeStore()
const route = useRoute()
const filterModal = ref<{ openModal: () => void; closeModal: () => void } | null>(null)

interface ISearchFiltersComponentState {
  isCategoryOpen: boolean
  isSubcategoryOpen: boolean
  isDateOpen: boolean
  isAuthorsOpen: boolean
  selectedDate: string
  selectedAuthors: string[]
  searchParams: ISearchParams
  selectedCategory: string
  selectedSubcategories: string[]
  currentSelectedCategoryModel: CategoryModel | null
  openMoreCategories: boolean
  openMoreAuthors: boolean
  showMoreAuthorBtn: boolean
}

const state = ref<ISearchFiltersComponentState>({
  isCategoryOpen: true,
  isSubcategoryOpen: true,
  isDateOpen: false,
  isAuthorsOpen: true,
  selectedDate: 'Month/Year',
  selectedAuthors: [],
  searchParams: {
    q: '',
    p: 1,
    c: [],
    d: '',
    a: [],
    t: 'All',
  },
  selectedCategory: '',
  selectedSubcategories: [],
  currentSelectedCategoryModel: null,
  openMoreCategories: false,
  openMoreAuthors: false,
  showMoreAuthorBtn: false,
})

const modalContainerStyles = computed(() => {
  if (mediaSizeStore.isMobile) {
    return '!overflow-y-scroll !h-dvh'
  } else {
    return 'rounded-xl w-full lg:max-w-[500px] h-[565px]'
  }
})

watch(
  () => state.value.selectedCategory,
  (value, oldValue) => {
    if (!oldValue) {
      state.value.selectedSubcategories = selectedSubcategoriesFromUrl.value
    } else {
      state.value.selectedSubcategories = []
    }

    state.value.currentSelectedCategoryModel =
      props.searchComponent?.filtersComponent.categories?.find(
        (value) => value.name === state.value.selectedCategory,
      ) ?? null
  },
  { deep: true },
)

function segmentHasResults(id: number, tab: SearchSegmentLinkItem): boolean {
  if (props.hideEmptyTabs) {
    if (tab.segmentId == 'bible_search') {
      //in the future this may be replaced with a silent api call to verify there will be results for the bible gateway see TITW-698
      return hasAnyLocalResults()
    }

    if (id !== 0 && searchStore.searchResults?.results !== null) {
      const segment = searchStore.searchResults?.results[tab.segmentId]
      return !!segment && segment.totalCount > 0
    }

    return hasAnyLocalResults()
  }
  return true
}

function hasAnyLocalResults(): boolean {
  let localResults = false
  if (searchStore.searchResults?.results && !searchStore.isLoading) {
    for (const key in searchStore.searchResults.results) {
      if (searchStore.searchResults.results[key].totalCount > 0) {
        return true
      }
    }
  }
  return localResults
}

function clearFilters() {
  state.value.searchParams.c = []
  state.value.searchParams.d = ''
  state.value.selectedCategory = ''
  state.value.selectedSubcategories = []
  state.value.selectedAuthors = []
  state.value.searchParams.a = []
}

function clearSearchInput() {
  state.value.searchParams.q = ''
  searchStore.searchResults = null
  sayt()
}

const totalFilters = computed(() => {
  let filterCount = state.value.selectedCategory ? 1 : 0

  filterCount += state.value.selectedSubcategories.length
  filterCount += state.value.selectedAuthors?.length ?? 0

  if (state.value.searchParams.d && state.value.searchParams.d.length > 0) {
    filterCount += 1
  }

  return filterCount
})

function handleTabData(tab: SearchSegmentLinkItem) {
  if (state.value.searchParams.t === tab.segmentId || state.value.searchParams.t === tab) {
    return
  }
  if (tab?.segmentId !== undefined) {
    state.value.searchParams.t = tab.segmentId
  }
  applySearchFilters()
}

const computedDates = computed(() => {
  //gets the 'key' for key:value pair of dates
  if (props.searchComponent?.filtersComponent?.dates) {
    return Object.keys(props.searchComponent?.filtersComponent?.dates)
  }
})

function handleSelectDate(selectedDate: string) {
  //matches selected date to get 'value' of the 'key:value' pair
  for (const date in props.searchComponent?.filtersComponent?.dates) {
    if (selectedDate === date) {
      state.value.searchParams.d = date
    }
  }

  updateDateOpenStatus()
}

function openFilterModal() {
  if (filterModal.value) {
    filterModal.value.openModal()
  }
}

function closeFilterModal() {
  if (filterModal.value) {
    filterModal.value.closeModal()
  }
}

const subcategories = computed((): SubcategoryModel[] => {
  return state.value.currentSelectedCategoryModel?.items ?? []
})

const MAX_ALLOWED_SUBCATEGORIES = 8

const filteredSubcategories = computed(() => {
  if (subcategories.value.length === 0) {
    return subcategories.value
  }

  if (subcategories.value.length > MAX_ALLOWED_SUBCATEGORIES && !state.value.openMoreCategories) {
    return subcategories.value.slice(0, MAX_ALLOWED_SUBCATEGORIES)
  }

  return subcategories.value
})

const showMoreSubcategoryBtn = computed(() => {
  return subcategories.value.length > MAX_ALLOWED_SUBCATEGORIES
})

const computedAuthors = computed(() => {
  let authors: string[] = []
  state.value.showMoreAuthorBtn = false

  authors = props.searchComponent?.filtersComponent?.authors ?? []

  if (authors.length > 8) {
    state.value.showMoreAuthorBtn = true
  }

  if (authors.length > 8 && !state.value.openMoreAuthors) {
    return authors.splice(0, 8)
  } else {
    return authors
  }
})

function handleUnsavedChanges() {
  if (route.query.c) {
    state.value.searchParams.c = getFirstQueryParameter(route.query, 'c')?.split(',')

    props.searchComponent?.filtersComponent?.categories?.forEach((category) => {
      if (category.name && state.value.searchParams.c?.includes(category.name)) {
        state.value.selectedCategory = category.name
        return false
      }
    })
  }

  closeFilterModal()
}

function updateCategoryOpenStatus() {
  state.value.isCategoryOpen = !state.value.isCategoryOpen
}
function updateSubcategoryOpenStatus() {
  state.value.isSubcategoryOpen = !state.value.isSubcategoryOpen
}

function updateAuthorsOpenStatus() {
  state.value.isAuthorsOpen = !state.value.isAuthorsOpen
}

function updateDateOpenStatus() {
  state.value.isDateOpen = !state.value.isDateOpen
}

const saytDebounce = debounce(() => {
  emit('searchFilterChange', state.value.searchParams, 'sayt')
}, 1000)

function sayt() {
  if (
    !props.restrictEmptyTermSearch ||
    (state.value.searchParams.q && state.value.searchParams.q.length >= 3)
  ) {
    saytDebounce()
  }
}

const computedCategoryArray = computed(() => {
  const results = []
  if (state.value.selectedCategory?.length > 0) {
    results.push(state.value.selectedCategory)
  }

  results.push(...state.value.selectedSubcategories)
  return results
})

function applySearchFilters() {
  filterModal.value?.closeModal()

  state.value.searchParams.c = computedCategoryArray.value
  state.value.searchParams.a = state.value.selectedAuthors

  emit('searchFilterChange', state.value.searchParams, 'applyFilters')
}

const selectedSubcategoriesFromUrl = ref<string[]>([])

function getUrlQueryParams() {
  state.value.selectedCategory = ''
  selectedSubcategoriesFromUrl.value = []
  const categoriesFromUrl = routeHelper.getQueryParameterArray(route.query, 'c')
  if (categoriesFromUrl.length > 0) {
    categoriesFromUrl.forEach((categoryName) => {
      let isCategoryFound = false
      props.searchComponent?.filtersComponent?.categories?.forEach((category) => {
        if (category.name && categoryName === category.name) {
          state.value.selectedCategory = categoryName
          isCategoryFound = true
          return false
        } else if (category.items && category.items.length > 0) {
          category.items.forEach((subcategory) => {
            if (subcategory.name && categoryName === subcategory.name) {
              selectedSubcategoriesFromUrl.value.push(categoryName)
              isCategoryFound = true
              return false
            }
          })
        }

        if (isCategoryFound) {
          return false
        }
      })
    })

    console.log(
      'getUrlQueryParams -> c',
      state.value.selectedCategory,
      selectedSubcategoriesFromUrl.value,
    )
  }

  state.value.searchParams.q = routeHelper.getFirstQueryParameter(route.query, 'q')
  state.value.searchParams.d = routeHelper.getFirstQueryParameter(route.query, 'd')
  state.value.selectedAuthors = routeHelper.getQueryParameterArray(route.query, 'a')
  if (state.value.selectedAuthors.length > 0) {
    state.value.isAuthorsOpen = true
  }

  if (routeHelper.getFirstQueryParameter(route.query, 't') === undefined) {
    state.value.searchParams.t = 'All'
  } else {
    state.value.searchParams.t = routeHelper.getFirstQueryParameter(route.query, 't')
  }
}

onMounted(() => {
  getUrlQueryParams()
})
</script>
