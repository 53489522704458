<template>
  <div v-if="menuItems && menuItems.length > 0" class="relative z-10 mb-5 flex w-full flex-col">
    <button
      class="flex items-center justify-between rounded-xl px-3 py-2"
      :class="classNames"
      @click="toggleMenu()"
    >
      <span>{{ placeholder }}</span>
      <span
        class="flex h-5 w-5 rotate-0 duration-300 ease-in-out"
        :class="{ '-rotate-180': state.isOpen }"
      >
        <ChevronDownIcon class="light-blue-900" />
      </span>
    </button>

    <ul
      class="border-grey-200 absolute left-0 right-0 top-full flex flex-col rounded-xl border bg-white transition duration-300 ease-in-out"
      :class="[
        { 'invisible -translate-y-2.5 opacity-0': !state.isOpen },
        { 'opacity-1 visibile translate-y-1': state.isOpen },
      ]"
    >
      <li v-for="(item, index) in menuItems" :key="index">
        <button
          class="text-grey-700 flex w-full px-3 py-2 text-sm"
          @keydown.esc="toggleMenu()"
          @keydown.tab="closeMenu(index, $event)"
          @click="handleClick(item)"
        >
          {{ item }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { ref } from 'vue'

const props = defineProps({
  menuItems: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: () => '',
  },
  classNames: {
    type: String,
    default: () => '',
  },
})

const state = ref({
  isOpen: false,
})

const emit = defineEmits(['itemChanged'])

function closeMenu(index, e) {
  if (index === props.menuItems.length - 1 || (e.shiftKey && index === 0)) {
    toggleMenu()
  }
}

function toggleMenu() {
  state.value.isOpen = !state.value.isOpen
}

const handleClick = function (text) {
  emit('item-changed', text)
  toggleMenu()
}
</script>
