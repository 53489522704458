<template>
  <section v-if="model" class="space-y-6 lg:w-3/4">
    <section-heading v-if="model.heading" :heading="model.heading" />
    <div>
      <c2-slider
        :slide-total="model.items?.length"
        :slides-to-show="4"
        :slides-to-show-tablet="2"
        :slides-to-show-mobile="1"
        :slide-speed="500"
        :gutter="mediaSizeStore.isMobile ? 16 : 32"
        :media-tablet-query="1023"
        :media-mobile-query="639"
        :show-overflow="true"
        :show-button-nav="false"
        :showIndicators="false"
        :next-btn-classes="'hidden'"
        :prev-btn-classes="'hidden'"
        :nav-classes="'flex justify-end mt-4 relative md:mt-14 md:mb-0'"
        class="relative w-full -translate-x-8 transform pl-8 md:translate-x-0 md:pl-0"
      >
        <template v-slot:slide>
          <div v-for="book in model.items" class="relative flex w-full flex-col rounded-xl">
            <s-link
              v-if="book.link"
              :href="book.link.href"
              :external-link="book.link.isExternal"
              class="aspect-[2/3] h-auto max-w-[218px]"
            >
              <s-image
                v-if="book.image"
                :model="book.image"
                class="h-full w-full rounded-xl object-cover"
              />
            </s-link>
            <div v-if="book.title" class="mt-6 w-full space-y-2">
              <div v-if="book.title" class="mb-1 line-clamp-2">
                <s-link
                  v-if="book.link"
                  :href="book.link.href"
                  :external-link="book.link.isExternal"
                  class="text-lg font-medium leading-7 text-slate-700"
                >
                  {{ book.title }}
                </s-link>
              </div>
              <div v-if="book.authors">
                <div
                  v-for="(author, index) in getFormattedAuthors(book.authors)"
                  :key="index"
                  class="text-sm leading-5 text-cyan-700"
                >
                  {{ getTrimmedAuthor(author)
                  }}<span v-if="index < getFormattedAuthors(book.authors).length - 1">,</span
                  ><span v-if="index === 1 && book.authors.length > 2"> ...</span>
                </div>
              </div>
              <div class="flex flex-col justify-between">
                <div v-for="item in book.formats" class="flex flex-wrap">
                  <span v-if="item.format" class="text-sm font-semibold leading-5 text-slate-700">{{
                    item.format
                  }}</span>
                  <span class="mr-1 text-sm font-semibold leading-5 text-slate-700">:</span>
                  <span v-if="item.price" class="text-sm font-normal leading-5 text-slate-700">{{
                    item.price
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </c2-slider>
    </div>
  </section>
</template>

<script setup lang="ts">
import SectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import SImage from '@TodayInTheWord/components/shared/s-image.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import C2Slider from 'uikit/c2-slider/c2-slider.vue'
import { BookList } from '../types/author-detail-types'

const mediaSizeStore = useMediaSizeStore()

interface BookListProps {
  model?: BookList
}

const props = defineProps<BookListProps>()

function getFormattedAuthors(authors: { text: string }[]) {
  if (!authors) return []
  const authorsToShow = authors.slice(0, 2).map((author) => author.text.trim())
  return authorsToShow
}

function getTrimmedAuthor(author: string) {
  const trimmed = author.trim()
  console.log(trimmed)
  return trimmed
}
</script>
