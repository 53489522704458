<template>
  <c2-pagination
    :max-pages-visible="calculatedMaxPagesVisible"
    :current-page="currentPage"
    :total-pages="totalPages"
    :previous-btn-classes="'flex items-center justify-center h-8 mr-8 md:mr-2 text-light-blue-900 font-medium [@media(hover:hover)]:hover:text-light-blue-600 disabled:text-grey-600 disabled:opacity-50 transition ease-in-out duration-300'"
    :next-btn-classes="'flex items-center justify-center h-8 ml-8 md:ml-2 text-light-blue-900 font-medium [@media(hover:hover)]:hover:text-light-blue-600 disabled:text-grey-600 disabled:opacity-50 transition ease-in-out duration-300'"
    :list-classes="'flex items-center justify-center space-x-1 flex-1 md:flex-auto'"
    :list-item-classes="'flex items-center justify-center h-11 w-10'"
    :page-number-btn-classes="'bg-white flex items-center justify-center h-11 w-10 rounded-xl text-sm text-grey-600 [@media(hover:hover)]:hover:bg-grey-200 transition ease-in-out duration-300'"
    :active-page-number-btn-classes="'!bg-grey-200 disabled:text-grey-600'"
    :class="paginationClasses"
    @change="getCurrentPage"
  >
    <template v-slot:previous-page-btn>
      <span class="flex h-6 w-6 md:h-5 md:w-5"><ArrowLeftIcon class="w-full" /></span>
      <template v-if="!mediaStore.isMobile">
        <span class="ml-2.5 font-medium">Previous</span>
      </template>
      <template v-else>
        <span class="sr-only">Previous Page</span>
      </template>
    </template>

    <template v-slot:next-page-btn>
      <template v-if="!mediaStore.isMobile">
        <span class="mr-2.5 font-medium">Next</span>
      </template>
      <template v-else>
        <span class="sr-only">Next Page</span>
      </template>
      <span class="flex h-6 w-6 md:h-5 md:w-5"><ArrowRightIcon class="w-full" /></span>
    </template>
  </c2-pagination>
</template>

<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/solid'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import C2Pagination from 'uikit/c2-pagination/c2-pagination.vue'
import { computed } from 'vue'

const props = defineProps({
  currentPage: {
    type: Number,
    default: 1,
  },
  totalPages: {
    type: Number,
    required: true,
  },
})

const calculatedMaxPagesVisible = computed(() => {
  if (mediaStore.isMobile) {
    return 2
  } else {
    return 5
  }
})

const paginationClasses = computed(() => {
  return [
    'flex items-center justify-between pt-3',
    `max-${calculatedMaxPagesVisible.value}`,
    `total-${props.totalPages}`,
  ].join(' ')
})

const emit = defineEmits(['pageChanged'])

const mediaStore = useMediaSizeStore()

const getCurrentPage = (currentPage) => {
  emit('page-changed', currentPage)
}
</script>
<style>
.c2-pagination > button:disabled {
  opacity: 0;
  pointer-events: none;
}

.c2-pagination.total-3 ul li:nth-child(4):not([aria-label]),
.c2-pagination.total-3 ul li:nth-child(5):not([aria-label]),
.c2-pagination.total-4 ul li:nth-child(5):not([aria-label]),
.c2-pagination.total-4 ul li:nth-child(6):not([aria-label]),
.c2-pagination.total-5 ul li:nth-child(6):not([aria-label]),
.c2-pagination.total-5 ul li:nth-child(7):not([aria-label]) {
  display: none;
}
</style>
