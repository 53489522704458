<template>
  <s-link
    v-if="model.url"
    class="titw q-and-a-item group relative flex grow flex-col justify-between rounded-xl"
    :href="model.url"
    :external-link="isExternal"
    @click.capture="$emit('trackHit', query, model.hitId, trackId)"
  >
    <div
      class="border-lighter-grey inline-flex flex-col items-start justify-start gap-4 border-b pb-6"
    >
      <div class="flex flex-col items-start justify-start gap-2 self-stretch">
        <div class="inline-flex items-start justify-start gap-2 self-stretch">
          <div
            class="border-light-blue-200 bg-light-blue-100 inline-flex h-6 w-6 flex-col items-center justify-center rounded border bg-sky-50 text-sm font-semibold text-sky-900"
          >
            Q
          </div>
          <div
            class="text-grey-800 group-hover:text-light-blue-600 shrink grow basis-0 text-base font-medium"
          >
            {{ model.title }}
          </div>
        </div>
        <div class="text-grey-700 self-stretch text-sm">
          {{ model.description }}
        </div>
      </div>
      <div
        v-if="model.authorNames || model.categories"
        class="inline-flex items-center justify-start gap-4 self-stretch"
      >
        <div
          v-if="model.authorNames && model.authorNames.length > 0"
          class="text-grey-700 text-justify text-xs font-medium leading-none"
        >
          {{ computedAuthor }}
        </div>
        <div
          v-if="model.categories && model.categories.length > 0"
          class="bullet-seperator flex items-center space-x-1 [&>:not(:first-child)]:before:mr-1"
        >
          <span
            v-for="(category, index) in computedCategories"
            :key="index"
            class="text-grey-500 text-xs font-normal"
          >
            {{ category }}
          </span>
        </div>
      </div>
    </div>
  </s-link>
</template>

<script setup lang="ts">
import SearchHitTracking from '@shared/api/search-hit-tracking'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import SLink from '@TodayInTheWord/components/shared/s-link.vue'
import Tag from '@TodayInTheWord/components/shared/tag.vue'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import { DateTime } from 'luxon'
import { computed } from 'vue'
import segmentHeader from './segment-header.vue'
import TopPageCard from './top-page-card.vue'

const searchStore = useSearchPageStore()
const trackId = computed(() => {
  return searchStore?.searchResults?.trackId
})

const query = computed(() => {
  return searchStore?.searchResults?.query
})

const items = computed((): searchTypes.TitwSearchResultItemModel[] | null => {
  return searchStore?.searchResults?.results?.pages?.items ?? null
})

const computedAuthor = computed(() => {
  //return the first author
  return props?.model?.authorNames[0]
})

const computedCategories = computed(() => {
  return props?.model?.categories?.slice(0, 2)
})

const isExternal = computed(() => {
  return props.model?.url && props.model.url.startsWith('http')
})

const emit = defineEmits(['changeTab', 'trackHit'])

const props = defineProps({
  model: {
    type: Object,
    default: () => {},
  },
})
</script>

<style lang="scss"></style>
