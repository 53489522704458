<template>
  <div class="w-full pb-8 lg:pb-12">
    <div class="w-full">
      <segment-header :model="model" />
    </div>
    <div class="flex flex-col space-y-6">
      <div class="relative md:mb-8">
        <c2-slider
          :slide-total="items?.length"
          :slides-to-show="3"
          :slides-to-show-tablet="2"
          :slides-to-show-mobile="1"
          :slide-speed="500"
          :gutter="16"
          :media-tablet-query="1023"
          :media-mobile-query="768"
          :show-overflow="true"
          :show-button-nav="true"
          :showIndicators="false"
          :next-btn-classes="'w-12 h-12 flex items-center justify-center rounded-xl bg-light-blue-900 hidden md:flex'"
          :prev-btn-classes="'w-12 h-12 flex items-center justify-center rounded-xl bg-light-blue-900 mr-3 hidden md:flex'"
          :nav-classes="'relative justify-end mt-6 md:mt-14 md:flex mb-[42px] md:mb-0'"
          :button-nav-wrapper-classes="'max-w-full absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 flex align-center'"
          :button-classes="'h-1 w-9 rounded mx-0.5 bg-grey-300'"
          :active-button-classes="'!bg-light-blue-900'"
          :show-next-prev-btns="false"
          class="relative w-full -translate-x-8 transform pl-8 md:translate-x-0 md:pl-0"
        >
          <template v-slot:slide>
            <div v-for="item in items">
              <videos-item
                :model="item"
                class="group relative flex grow flex-col justify-between rounded-xl"
              />
            </div>
          </template>
        </c2-slider>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SearchHitTracking from '@shared/api/search-hit-tracking'
import Tag from '@TodayInTheWord/components/shared/tag.vue'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import C2Slider from 'uikit/c2-slider/c2-slider.vue'
import { computed } from 'vue'
import segmentHeader from '../segment-header.vue'
import VideosItem from './videos-item.vue'

const searchStore = useSearchPageStore()

const items = computed((): searchTypes.TitwSearchResultItemModel[] | null => {
  let items = searchStore?.searchResults?.results?.videos?.items ?? null
  if (items !== null && items.length > 3) {
    let slicedItems = items.slice(0, 3)

    return slicedItems
  } else {
    return items
  }
})

const props = defineProps({
  model: {
    type: Object,
    default: () => {},
  },
})
</script>

<style lang="scss"></style>
