<template>
  <section
    v-if="model"
    v-bind:id="model.clientID"
    :class="{
      CTAhero: subscriptionForm,
      'md:aspect-[21/9]': model.imageAspectRatio === '21/9',
      'md:aspect-[16/9]': model.imageAspectRatio === '16/9',
      'md:aspect-[3/1]': model.imageAspectRatio === '3/1',
    }"
    class="TITW hero bg-light-blue-900 relative flex min-h-[550px] flex-col justify-center md:min-h-[auto]"
    :style="computeBackgroundImage"
  >
    <picture v-if="computedImageModel !== null" class="absolute inset-x-0 inset-y-0">
      <shared-image
        :model="computedImageModel"
        class="h-full w-full object-cover"
        :class="computedImageClasses"
        format="?width=1440&height=563&format=webp&compand=true"
        :preload="true"
      />
    </picture>
    <div
      class="container inset-x-0 z-10 mx-auto flex flex-col px-6 py-4"
      :class="{
        'items-center text-center': model.contentTextAlignment === 'center',
        'items-end text-right': model.contentTextAlignment === 'right',
      }"
    >
      <div class="CTAcontent flex w-full items-center justify-between">
        <div class="CTAcopy w-full md:w-1/2" :class="computedGlassBackground">
          <h1
            v-if="model.heading"
            class="text-2xl font-semibold md:text-5xl"
            :class="model.disableGlassOverlay ? 'text-white' : 'text-cyan-900'"
          >
            {{ model.heading }}
          </h1>
          <p
            v-if="model.subheading"
            class="mb-0 mt-8 text-base md:mt-12 md:text-2xl"
            :class="model.disableGlassOverlay ? 'text-white' : 'text-cyan-900'"
          >
            {{ model.subheading }}
          </p>
          <div
            v-if="model.buttons && model.buttons.length > 0"
            class="mt-8 flex items-center"
            :class="{
              'justify-center': model.contentTextAlignment === 'center',
              'justify-end': model.contentTextAlignment === 'right',
            }"
          >
            <s-link
              v-for="(button, index) in model.buttons"
              :key="index"
              :href="button.href"
              :target="button.target"
              :external-link="button.isExternal"
              :size="button.buttonSize ?? 'regular'"
              :emphasis="button.buttonEmphasis ?? 'bold'"
              :style="button.buttonStyle ?? 'primary'"
              :class="{
                'mr-4': model.contentTextAlignment === 'left',
                'mx-2': model.contentTextAlignment === 'center',
                'ml-4': model.contentTextAlignment === 'right',
              }"
            >
              {{ button.text }}
            </s-link>
          </div>
        </div>
        <div class="CTAform subForm w-full md:w-1/2" v-if="showForm(subscriptionForm)">
          <hero-form :model="subscriptionForm"> </hero-form>
        </div>
      </div>

      <s-button
        v-if="model.videoShareLink || model.videoUrl"
        ref="playButton"
        :size="'regular'"
        :emphasis="'bold'"
        :style="'primary'"
        class="absolute left-1/2 top-full flex h-[72px] w-[72px] -translate-x-1/2 -translate-y-1/2 transform items-center justify-center"
        @click="openModal()"
      >
        <span class="sr-only">Play Video</span>
        <span class="flex h-6 w-6 items-center justify-center">
          <PlayIcon class="w-full" />
        </span>
      </s-button>
    </div>

    <c2-modal
      v-if="model.videoShareLink || model.videoUrl"
      ref="heroModal"
      :background-color="'transparent'"
      :background-overlay-color="'rgba(0,0,0,0.8)'"
      :close-btn-classes="'flex items-center justify-center h-12 w-12 absolute top-0 right-0'"
      :header-classes="'flex items-end justify-between min-h-[48px] px-4 relative'"
      :wrapper-classes="'bg-white p-4'"
      :max-width="1296"
      class="relative h-0 overflow-hidden"
      @modal-opened="toggleShowVideo(true)"
      @modal-closed="toggleShowVideo(false)"
    >
      >
      <template v-slot:close-btn>
        <span class="sr-only">Close Modal</span>
        <span class="h-6 w-6"><XMarkIcon class="text-white" /></span>
      </template>

      <template v-slot:body>
        <div
          v-if="model.videoShareLink"
          class="iframe-body relative aspect-[16/9] w-full overflow-hidden rounded-xl bg-white"
          v-html="model.videoShareLink"
        ></div>

        <div v-if="model.videoUrl" class="relative aspect-[16/9] w-full bg-white">
          <video controls class="w-full" ref="modelVideo">
            <source :src="model.videoUrl" type="video/mp4" />
          </video>
        </div>
      </template>
    </c2-modal>

    <template v-if="model.videoShareScript">
      <div id="custom-video-script"></div>
    </template>
  </section>
</template>

<script setup lang="ts">
import { PlayIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import HeroForm from '@TodayInTheWord/components/shared/hero-form.vue'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import C2Modal from 'uikit/c2-modal/c2-modal.vue'
import { computed, ref } from 'vue'
import { HeroBlockProps } from './types/hero-types'

const props = defineProps<HeroBlockProps>()

const mediaStore = useMediaSizeStore()
const heroModal = ref()
const modalVideo = ref(null)
const playButton = ref()

const computeBackgroundImage = computed(() => {
  if (mediaStore.isMobile && props.model?.mobileImage?.url) {
    return 'background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #374151'
  } else if (props.model?.desktopImage?.url) {
    return 'background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #374151'
  } else {
    return ''
  }
})

const computedImageModel = computed(() => {
  if (mediaStore.isMobile) {
    return props.model?.mobileImage ?? null
  } else {
    return props.model?.desktopImage ?? null
  }
})

const computedImageClasses = computed(() => {
  switch (props.model?.imagePosition) {
    case 'center':
      return '!object-center'
    case 'bottom':
      return '!object-bottom'
    case 'bottom-left':
      return '!object-left-bottom'
    case 'bottom-right':
      return '!object-right-bottom'
    default:
      return ''
  }
})

const showVideoSrc = ref(true)
function openModal() {
  heroModal.value.openModal()
}

const toggleShowVideo = (bool: boolean) => {
  showVideoSrc.value = bool

  if (showVideoSrc.value) {
    createScript()
  } else {
    pauseAndReturnFocus()
  }
}

const computedGlassBackground = computed(() => {
  let defaultStyle =
    'rounded-xl px-7 py-9 shadow-md backdrop-blur backdrop-filter md:px-14 md:py-12 border border-grey-50'
  if (props.model.disableGlassOverlay) {
    return ''
  } else {
    return mediaStore.isMobile
      ? `${defaultStyle} mobileGlassBackground`
      : `${defaultStyle} desktopGlassBackground`
  }
})
const createScript = () => {
  //clear any previous scripts
  var prevScript = document.querySelector('#custom-video-script')
  if (prevScript !== null) {
    prevScript.innerHTML = ''
    var script = document.createElement('script')
    prevScript.appendChild(script)
    if (props.model?.videoShareScript) {
      script.innerHTML = props.model?.videoShareScript
    }
  }
}
const pauseAndReturnFocus = () => {
  const videos = [...document.getElementsByTagName('video')]
  videos.forEach((vid) => {
    vid.pause()
  })
  const iframeVideo = heroModal.value.$el.querySelector('iframe')
  if (iframeVideo) {
    let src = iframeVideo.src
    iframeVideo.src = src
  }

  playButton.value.$el.focus()
}

function showForm(form: any) {
  if (typeof form?.contentLink === 'undefined') {
    return false
  }
  return true
}
</script>

<style lang="scss">
.CTAhero {
  .CTAcontent {
    .CTAcopy {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.desktopGlassBackground {
  background: radial-gradient(
      138.63% 80.74% at 28.22% 55.07%,
      rgba(251, 250, 249, 0.38) 0%,
      rgba(209, 235, 255, 0.38) 100%
    ),
    rgba(249, 250, 251, 0.6);
}
.mobileGlassBackground {
  background: radial-gradient(
      138.63% 80.74% at 28.22% 55.07%,
      rgba(251, 250, 249, 0.8) 0%,
      rgba(209, 235, 255, 0.8) 100%
    ),
    rgba(249, 250, 251, 0.6);
}
.disabledBackground {
  background:
    linear-gradient(0deg, rgba(17, 24, 39, 0.6) 0%, rgba(17, 24, 39, 0.6) 100%),
    url(<path-to-image>) lightgray 0px -163.2px / 100% 221.538% no-repeat,
    url(<path-to-image>) lightgray 50% / cover no-repeat,
    #1f2937;
}

@media only screen and (max-width: 768px) {
  .CTAhero {
    div.container {
      position: inherit;
    }

    div.flex.justify-between {
      flex-direction: column;
    }

    .CTAcontent {
      .CTAcopy {
        width: 100%;
        min-height: auto;
      }

      .CTAform {
        width: 100%;
        min-height: auto;
      }
    }
  }
}

.hero .subForm {
  & section input[type='reset'].Form__Element.FormResetButton {
    margin-bottom: 3.25em;
  }
}

.hero .subForm {
  padding: 1rem 0.5rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0.75rem;
  backdrop-filter: blur(6px);
  position: relative;

  .form-container .EPiServerForms {
    position: relative;

    &,
    h2,
    h3,
    .Form__Description,
    label,
    p {
      color: #fff !important;
      margin-bottom: 0.25rem;
    }

    .Form__MainBody > section > .Form__Element {
      flex: inherit;
    }

    .Form__Element__Caption {
      height: auto;
      position: initial;
    }

    .Form__Element,
    .FormTextbox,
    .FormSubmitButton {
      width: 100%;
      margin-bottom: 0.25rem;
    }

    .FormSubmitButton {
      display: block;
      width: 100% !important;
      position: absolute;
      bottom: 0;
    }

    .FormTextbox__Input,
    .FormSubmitButton {
      border-radius: 12px !important;
    }
  }
}

.c2-modal {
  .iframe-body {
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
