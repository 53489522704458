<template v-if="accountMenu && accountMenu.label">
  <div class="relative flex" v-c2-click-outside="closeAccountMenu">
    <!-- change to link to sign in page -->
    <s-button
      class="hover:!border-light-blue-900 hover:!bg-light-blue-900 hover:text-light-blue-300 pb-1.5 pt-1.5 text-xs font-normal"
      :size="'smaller'"
      v-on:click.native="navigateTo(accountMenu.url)"
    >
      {{ accountMenu.label }}
    </s-button>
    <transition
      enter-from-class="translate-y-[-10%] opacity-0"
      leave-to-class="translate-y-[0%] opacity-0"
      enter-active-class="transition duration-300"
      leave-active-class="transition duration-300"
    >
      <div
        v-show="menuState.isDesktopAccountMenuOpen"
        class="absolute -inset-x-5 top-12 z-10 w-40"
      >
        <ul
          class="border-grey-200 z-20 flex w-full flex-col items-start space-y-4 rounded-xl border border-solid bg-white px-3 py-4 drop-shadow-md"
        >
          <li
            v-for="(item, index) in accountMenu.menuItems"
            :key="index"
            class="w-full"
          >
            <a
              v-if="item.text"
              :href="item.href"
              class="text-grey-700 flex w-full"
            >
              {{ item.text }}
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import vC2ClickOutside from 'uikitdirectives/c2-click-outside'

const headerStore = useHeaderStore()
const accountMenu = headerStore.header?.accountMenu
const menuState = headerStore.menuState

const navigateTo = function (url) {
  if (url && url.length > 0) {
    window.location.href = url
    return
  }

  if (accountMenu.menuItems && accountMenu.menuItems.length > 0) {
    menuState.isDesktopAccountMenuOpen = !menuState.isDesktopAccountMenuOpen
  }
}

function closeAccountMenu() {
  menuState.isDesktopAccountMenuOpen = false
}
</script>
