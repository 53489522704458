<template>
  <section v-if="model" v-bind:id="model.clientID">
    <div class="TITW link-card-grid-block container mx-auto">
      <div class="mb-8">
        <section-heading
          v-if="model.sectionHeading || model.description"
          :heading="model.sectionHeading"
          :subheading="model.description"
        />
      </div>
      <div
        v-if="model.cards && model.cards.length > 0"
        class="grid gap-4 md:gap-8"
        :class="computedGridColumns"
      >
        <template v-if="model.cardStyle === 'Image Card'">
          <image-card v-for="(card, i) in loadedCards" :key="`image-card-${i}`" :card="card" />
        </template>

        <template v-else>
          <simple-card v-for="(card, i) in loadedCards" :key="`simple-card-${i}`" :card="card" />
        </template>
        <s-button
          v-if="model.cards.length > 3 && mediaSize.isMobile && !state.btnOpen"
          @click="showCards"
          :size="'regular'"
          :style="'primary'"
          :emphasis="'bold'"
        >
          Show More
        </s-button>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import SectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { computed, reactive } from 'vue'
import ImageCard from './image-card.vue'
import SimpleCard from './simple-card.vue'
import { LinkCardGridBlockProps } from './types/link-card-grid-block-types'

const props = defineProps<LinkCardGridBlockProps>()
const epiPageContextStore = useEpiPageContextStore()

const state = reactive({
  btnOpen: false,
  totalCards: props.model?.cards.length,
  cardsToShow: 3,
})

const mediaSize = useMediaSizeStore()

function showCards() {
  state.cardsToShow += 3
  if (state.totalCards) {
    if (state.cardsToShow >= state.totalCards) {
      state.btnOpen = true
    }
  }
}

const loadedCards = computed(() => {
  if (mediaSize.isMobile) {
    return props.model?.cards.slice(0, state.cardsToShow)
  } else {
    return props.model?.cards.slice(0, state.totalCards)
  }
})

const computedGridColumns = computed(() => {
  if (
    epiPageContextStore.model.contentType &&
    epiPageContextStore.model.contentType.includes('TitwGeneralDetailPage')
  ) {
    return 'md:grid-cols-3'
  } else if (
    epiPageContextStore.model.contentType &&
    epiPageContextStore.model.contentType.includes('TitwLandingPage')
  ) {
    return 'md:grid-cols-4'
  } else {
    return 'md:grid-cols-4'
  }
})
</script>
