<template>
  <section class="">
    <div class="flex flex-col space-y-6 lg:space-y-8">
      <div
        v-for="article in model.items"
        class="border-b-lighter-grey flex w-full flex-col space-y-3 border-b pb-6 lg:pb-8"
      >
        <div class="flex items-center space-x-3">
          <span
            v-if="article.contentType"
            class="bg-light-blue-100 text-light-blue-900 rounded-xl px-2 py-1 text-xs font-medium leading-4 lg:text-base"
            >{{ article.contentType }}</span
          >
          <span v-if="article.displayDate" class="text-grey-600 text-sm font-medium">{{
            article.displayDate
          }}</span>
        </div>
        <a
          v-if="article.title"
          class="text-base font-semibold leading-6 text-cyan-900 transition hover:text-cyan-700 lg:text-xl lg:leading-7"
          :href="article.url"
        >
          {{ article.title }}
        </a>
      </div>
      <shared-pagination
        v-show="model.totalPages > 1"
        class="border-none !pt-2"
        :current-page="model?.page"
        :total-pages="model?.totalPages"
        @page-changed="pageChanged"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import SectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import SharedPagination from '@TodayInTheWord/components/shared/s-pagination.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { ArticleResultProps } from '../types/author-detail-types'

const mediaSizeStore = useMediaSizeStore()

const props = defineProps<ArticleResultProps>()

const emit = defineEmits<{
  changePage: [page: number]
}>()

const pageChanged = (page: number) => {
  emit('changePage', page)
}
</script>
