<template v-if="link">
  <a
    v-if="isExternalLink"
    :target="computedTarget"
    :href="sanitizedHref"
    :title="computedTitle"
    :aria-label="computedAriaLabel"
    :class="activeStyle"
  >
    <slot></slot>
  </a>
  <router-link
    v-else
    :to="sanitizedHref"
    :title="computedTitle"
    :aria-label="computedAriaLabel"
    :class="activeStyle"
  >
    <slot></slot>
  </router-link>
</template>

<script setup lang="ts">
import { EmphasisType, SizeType, StyleType } from '@shared/types/shared'
import {
  LinkItemViewModel,
  LinkItemWithAriaLabel,
} from '@TodayInTheWord/types/shared'
import { computed } from 'vue'
import * as LinkStylesApi from './shared-link-styles-api'

interface SharedLinkAutoProps {
  link?: LinkItemViewModel | LinkItemWithAriaLabel
  emphasis?: EmphasisType
  size?: SizeType
  style?: StyleType
}

const props = defineProps<SharedLinkAutoProps>()

const isExternalLink = computed(() => {
  if (!props.link) {
    return true
  }

  if (props.link.isExternal) {
    return true
  }

  if (props.link?.target === '_blank') {
    return true
  }

  const loweredHref = props.link?.href?.toLowerCase()
  return (
    loweredHref?.startsWith('http://') || loweredHref?.startsWith('https://')
  )
})

const computedAriaLabel = computed(() => {
  const linkWithAriaLabel = props.link as LinkItemWithAriaLabel
  return linkWithAriaLabel?.ariaLabel ?? undefined
})

const computedTitle = computed(() => {
  return props.link?.title ?? undefined
})

const computedTarget = computed(() => {
  return props.link?.target ?? undefined
})

const sanitizedHref = computed(() => {
  if (!props.link?.href) {
    return '#'
  } else {
    return props.link.href
  }
})

const activeStyle = computed(() => {
  return LinkStylesApi.getLinkStyle(props.style, props.emphasis, props.size)
})
</script>
