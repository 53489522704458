<template>
  <svg
    width="auto"
    height="auto"
    viewBox="0 0 268 237"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="40" width="188" height="13" rx="6.5" fill="#D1D5DB" />
    <rect y="53" width="115" height="13" rx="6.5" fill="#E5E7EB" />
    <mask id="path-3-inside-1_2546_24184" fill="white">
      <path
        d="M0 87C0 82.5817 3.58172 79 8 79H260C264.418 79 268 82.5817 268 87V131H0V87Z"
      />
    </mask>
    <path
      d="M0 87C0 82.5817 3.58172 79 8 79H260C264.418 79 268 82.5817 268 87V131H0V87Z"
      fill="#F3F4F6"
    />
    <rect x="16" y="100.5" width="82" height="12" rx="6" fill="#D1D5DB" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M239.278 109.707C238.887 109.317 238.887 108.683 239.278 108.293L242.575 105L239.278 101.707C238.887 101.317 238.887 100.683 239.278 100.293C239.669 99.9024 240.303 99.9024 240.694 100.293L244.699 104.293C245.09 104.683 245.09 105.317 244.699 105.707L240.694 109.707C240.303 110.098 239.669 110.098 239.278 109.707Z"
      fill="#1F2937"
    />
    <path
      d="M0 79H268H0ZM268 132H0V130H268V132ZM0 131V79V131ZM268 79V131V79Z"
      fill="#D1D5DB"
      mask="url(#path-3-inside-1_2546_24184)"
    />
    <mask id="path-7-inside-2_2546_24184" fill="white">
      <path d="M0 131H268V184H0V131Z" />
    </mask>
    <path d="M0 131H268V184H0V131Z" fill="#F3F4F6" />
    <rect x="16" y="151.5" width="82" height="12" rx="6" fill="#D1D5DB" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M239.278 162.207C238.887 161.817 238.887 161.183 239.278 160.793L242.575 157.5L239.278 154.207C238.887 153.817 238.887 153.183 239.278 152.793C239.669 152.402 240.303 152.402 240.694 152.793L244.699 156.793C245.09 157.183 245.09 157.817 244.699 158.207L240.694 162.207C240.303 162.598 239.669 162.598 239.278 162.207Z"
      fill="#1F2937"
    />
    <path
      d="M0 131H268H0ZM268 185H0V183H268V185ZM0 184V131V184ZM268 131V184V131Z"
      fill="#D1D5DB"
      mask="url(#path-7-inside-2_2546_24184)"
    />
    <path
      d="M0 184H268V229C268 233.418 264.418 237 260 237H7.99999C3.58172 237 0 233.418 0 229V184Z"
      fill="#F3F4F6"
    />
    <rect x="16" y="204.5" width="82" height="12" rx="6" fill="#D1D5DB" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M239.278 215.207C238.887 214.817 238.887 214.183 239.278 213.793L242.575 210.5L239.278 207.207C238.887 206.817 238.887 206.183 239.278 205.793C239.669 205.402 240.303 205.402 240.694 205.793L244.699 209.793C245.09 210.183 245.09 210.817 244.699 211.207L240.694 215.207C240.303 215.598 239.669 215.598 239.278 215.207Z"
      fill="#1F2937"
    />
  </svg>
</template>
