import GlobalFooter from './shared/footer/s-footer.vue'
import GlobalHeader from './shared/header/s-header.vue'

export default {
  install(Vue) {
    Vue.component('GlobalHeader', GlobalHeader).component(
      'GlobalFooter',
      GlobalFooter,
    )
  },
}
