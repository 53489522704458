export function isDateWithinOneYear(displayDate: string | undefined): boolean {
  if (!displayDate) return false

  const date = new Date(displayDate)
  const today = new Date()
  const oneYearAgo = new Date()
  oneYearAgo.setFullYear(today.getFullYear() - 1)

  return date >= oneYearAgo && date <= today
}
