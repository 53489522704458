<template>
  <div class="TITW-Bible-Verse-Detail flex flex-col space-y-8 lg:space-y-12">
    <div class="container mx-auto">
      <div class="mx-auto mt-5 space-y-8 lg:mt-6 lg:w-2/3 lg:space-y-6">
        <h1 v-if="model.screenReaderPageTitle" class="sr-only">
          {{ model.screenReaderPageTitle }}
        </h1>
        <div v-if="breadcrumbs">
          <bread-crumbs :links="breadcrumbs.links" :currentPageName="model.screenReaderPageTitle" />
        </div>
        <section class="border-lighter-grey space-y-9 border-b pb-8 lg:space-y-12 lg:pb-12">
          <div v-if="model.featuredImages && model.featuredImages.length > 0" class="relative">
            <div
              class="relative aspect-video lg:overflow-hidden lg:rounded-xl"
              :class="{ '-mx-[18px]': mediaSizeStore.isMobile }"
            >
              <s-image
                v-for="(image, index) in computedImages"
                :model="image"
                :key="index"
                class="absolute h-full w-full object-cover transition duration-300 ease-in-out"
                :class="state.isActive === index ? 'opacity-100' : 'opacity-0'"
                :preload="true"
              />
            </div>
            <div class="absolute -bottom-6 right-[30px] flex w-full justify-end">
              <share-this :featuredImageBtn="true" fill-class="h-6 w-6" :solid="true" />
            </div>
          </div>
          <div class="flex space-x-3 lg:space-x-2.5">
            <div
              v-for="(image, index) in computedImages"
              :key="index"
              class="aspect-video max-h-[60px] lg:max-h-[80px] lg:max-w-[136px]"
            >
              <button
                @click="handleFeaturedImage(index)"
                :class="state.isActive === index ? 'border-grey-400' : 'border-lighter-grey'"
                class="h-full w-full rounded-xl border p-1 transition-all duration-300 ease-in-out hover:shadow-lg"
              >
                <s-image :model="image" class="h-full w-full rounded-lg object-cover" />
              </button>
            </div>
          </div>
        </section>
        <section v-if="model.bibleVerseText || model.bibleVerseReference" class="lg:py-6">
          <div class="space-y-6 lg:space-y-8">
            <div class="border-light-blue-100 bg-light-blue-50 space-y-4 rounded-xl border p-4">
              <p v-if="model.bibleVerseText" class="text-grey-800 mb-4 text-lg font-normal">
                {{ model.bibleVerseText }}
              </p>
              <p v-if="model.bibleVerseReference" class="m-0 text-base font-medium text-cyan-700">
                {{ model.bibleVerseReference }}
              </p>
            </div>
            <s-link-auto
              v-if="model.readChapterLink?.href"
              :link="model.readChapterLink"
              class="text-light-blue-900 flex w-fit items-center text-sm font-medium lg:text-base"
              >{{ model.readChapterLink?.text }}
              <span class="fill-light-blue-900 ml-2 h-5 w-5"><arrow-right-icon /></span
            ></s-link-auto>
          </div>
        </section>
        <div v-if="model.relatedContent">
          <SectionHeading
            v-if="model.relatedContent.heading"
            :heading="model.relatedContent.heading"
          />
          <div
            class="grid grid-cols-1 gap-6 md:grid-cols-3"
            v-if="model.relatedContent.cards && model.relatedContent.cards.length > 0"
          >
            <s-image-link-card
              v-for="(card, index) in loadedCards"
              :card="card"
              :key="index"
              :options="{ hideDescription: true, hideTag: true, hideCategory: true }"
              class="col-span-1 !w-full"
            />
          </div>
          <s-button
            v-if="
              model.relatedContent.cards.length > 3 && mediaSizeStore.isMobile && !state.btnOpen
            "
            @click="showCards"
            :size="'regular'"
            :style="'primary'"
            :emphasis="'bold'"
            class="w-full"
          >
            Show More
          </s-button>
        </div>
      </div>
    </div>
    <block-component-selector
      v-for="(block, index) in model.additionalContent"
      :model="block.model"
      :key="index"
      :has-container="false"
    />
  </div>
</template>

<script setup lang="ts">
import SectionHeading from '@brand/components/blocks/section-heading/section-heading.vue'
import { ArrowRightIcon } from '@heroicons/vue/20/solid'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import sButton from '@TodayInTheWord/components/shared/s-button.vue'
import sImageLinkCard from '@TodayInTheWord/components/shared/s-image-link-card.vue'
import sImage from '@TodayInTheWord/components/shared/s-image.vue'
import sLinkAuto from '@TodayInTheWord/components/shared/s-link-auto.vue'
import ShareThis from '@TodayInTheWord/components/shared/share-this/s-share-this-button.vue'
import BlockComponentSelector from '@TodayInTheWord/epi/block-component-selector.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import { computed, onMounted, ref } from 'vue'
import { BibleVerseProps } from '../bible-verse/types/bible-verse-types'

const model: BibleVerseProps['model'] = useEpiPageContextStore().model
const breadcrumbs: BreadcrumbProps = useEpiPageContextStore().breadcrumbs
const mediaSizeStore = useMediaSizeStore()

const state = ref({
  isActive: 0,
  btnOpen: false,
  totalCards: 0,
  cardsToShow: 3,
})

const handleFeaturedImage = (selectedIndex: any) => {
  state.value.isActive = selectedIndex
}

const computedImages = computed(() => {
  return model.featuredImages?.slice(0, 3)
})

function showCards() {
  state.value.cardsToShow += 3
  if (state.value.totalCards) {
    if (state.value.cardsToShow >= state.value.totalCards) {
      state.value.btnOpen = true
    }
  }
}

const loadedCards = computed(() => {
  if (model.relatedContent && model.relatedContent.cards) {
    if (mediaSizeStore.isMobile) {
      return model?.relatedContent.cards.slice(0, state.value.cardsToShow)
    } else {
      return model?.relatedContent.cards.slice(0, state.value.totalCards)
    }
  }
})

onMounted(() => {
  if (model.relatedContent && model.relatedContent.cards && model.relatedContent.cards.length > 0) {
    state.value.totalCards = model.relatedContent.cards.length
  }
})
</script>
