<template>
  <div v-if="displayBibleResults">
    <h2 v-if="segment.heading" class="mb-4 text-lg font-medium text-black md:mb-8 md:text-2xl">
      {{ segment.heading }}
    </h2>

    <div>
      <div v-if="bibleSearchResults?.books?.items?.length > 0" class="books mb-8">
        <div
          v-if="bibleSearchResults.books.label"
          class="text-grey-600 text-justify text-base font-semibold leading-normal"
        >
          {{ bibleSearchResults.books.label }}
        </div>
        <a
          v-for="(item, index) in bibleSearchResults.books.items"
          :key="index"
          :href="item.link.href"
          class="hover:bg-grey-200 bg-grey-50 mb-4 inline-flex w-full flex-col items-start justify-start gap-3 rounded-xl px-6 py-4 transition duration-300 ease-in-out"
        >
          <span
            v-if="item.title"
            class="text-light-blue-900 text-base font-semibold leading-normal"
          >
            {{ item.title }}
          </span>
          <span v-if="item.description" class="text-base font-medium">
            {{ item.description }}
          </span>
        </a>
      </div>
      <div
        v-if="bibleSearchResults?.suggestions?.items?.length > 0"
        class="suggestedBooks"
        :class="{
          'mb-8': mediaStore.isMobile || isDetailMod,
        }"
      >
        <div
          class="text-grey-600 mb-[18px] text-justify text-base font-semibold leading-normal lg:mb-6"
        >
          {{ bibleSearchResults.suggestions.label }}
        </div>
        <bible-gateway-item
          v-for="(item, index) in bibleSearchResults.suggestions.items"
          :key="index"
          :model="item"
          :class="'bg-grey-200 hover:bg-grey-200 mb-5 inline-flex flex-col items-start justify-start gap-3 rounded-xl px-6 py-4 leading-normal transition duration-300  ease-in-out lg:mb-6'"
          titleclass="text-light-blue-900 text-base font-semibold "
          descriptionclass="text-grey-700 text-base font-normal leading-8"
        />
      </div>
      <div v-if="bibleSearchResults?.results?.items?.length > 0">
        <div
          v-if="bibleSearchResults.results.label"
          class="text-grey-600 mb-[18px] text-justify text-base font-semibold leading-normal lg:mb-6"
        >
          {{ bibleSearchResults.results.label }}
        </div>
        <bible-gateway-item
          v-for="(item, index) in bibleSearchResults.results.items"
          :key="index"
          :model="item"
          :class="'bg-grey-50 mb-5 inline-flex w-full flex-col items-start justify-start gap-3 rounded-xl px-6 py-4 lg:mb-6'"
        />
      </div>
    </div>
    <shared-pagination
      v-if="hasResults"
      v-show="bibleSearchResults.results.totalPages > 1 && isDetailMod"
      :current-page="bibleSearchResults.results.page + 1"
      :total-pages="bibleSearchResults.results.totalPages"
      @page-changed="pageChanged"
      class="mt-8"
    />
  </div>
</template>

<script setup lang="ts">
import SharedPagination from '@TodayInTheWord/components/shared/s-pagination.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import { computed } from 'vue'
import BibleGatewayItem from './bible-gateway-item.vue'

const searchStore = useSearchPageStore()
const mediaStore = useMediaSizeStore()

interface BibleSegmentFullProperties {
  segment: searchTypes.ISegmentModel | null
  bibleSearchResults: searchTypes.TitwBibleSearchResults | null
}

const emit = defineEmits<{
  changePage: [page: number]
}>()

const pageChanged = (page: number) => {
  emit('changePage', page)
}
const props = withDefaults(defineProps<BibleSegmentFullProperties>(), {
  segment: null,
  bibleSearchResults: null,
})

const displayBibleResults: boolean = computed((): boolean => {
  return hasBookResults || hasSuggestedResults || hasResults
})

const hasBookResults: boolean = computed((): boolean => {
  console.log(props.bibleSearchResults)
  return props.bibleSearchResults?.books?.items?.length > 0
})

const hasSuggestedResults: boolean = computed((): boolean => {
  return props.bibleSearchResults?.suggestions?.items?.length > 0
})

const hasResults: boolean = computed((): boolean => {
  return props.bibleSearchResults?.results?.items?.length > 0
})

const isDetailMod: boolean = computed((): boolean => {
  return true
})
</script>
<style lang="scss">
p .chapternum {
  font-size: 18px;
  margin-left: 8px;
}
</style>
