<template v-if="icon">
  <span ref="$iconRoot">
    <component :is="HeroIcons[icon]" @vue:mounted="onChildComponentMount()" />
  </span>
</template>

<script setup>
import * as HeroIcons from '@heroicons/vue/24/outline'
import { ref } from 'vue'

const $iconRoot = ref()

const props = defineProps({
  icon: {
    type: String,
    default: null,
  },
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
})

const onChildComponentMount = () => {
  if (!$iconRoot?.value) {
    return
  }

  const svg = $iconRoot.value.firstChild
  if (!svg || svg.tagName !== 'svg') {
    return
  }

  if (props.width) {
    svg.setAttribute('width', props.width)
  }

  if (props.height) {
    svg.setAttribute('height', props.height)
  }
}
</script>

<style lang="scss"></style>
