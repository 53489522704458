<template>
  <div v-if="message" class="text-xl lg:grid lg:grid-cols-12">
    <div class="lg:col-span-9">
      <div class="text-grey-500 mb-10 text-center" v-html="NoResMess" />

      <div
        v-if="
          searchStore.recommendationsSearchTerms &&
          searchStore.recommendationsSearchTerms.terms &&
          searchStore.recommendationsSearchTerms.terms.length > 0
        "
        class="text-left"
      >
        <h2
          v-if="searchStore.recommendationsSearchTerms.header"
          class="mb-6 text-lg font-medium text-black"
        >
          {{ searchStore.recommendationsSearchTerms.header }}
        </h2>
        <div class="flex flex-col">
          <button
            v-for="(term, i) in searchStore.recommendationsSearchTerms.terms"
            :key="`term${i}`"
            @click="search(term)"
            class="bg-grey-50 mb-4 rounded-2xl p-4 text-left text-sm font-semibold text-black"
          >
            {{ term }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import { computed } from 'vue'

const searchStore = useSearchPageStore()

const NoResMess = computed(() => {
  return (
    props.message &&
    props.message.replace(
      /{{ searchTerm }}/gi,
      `<span class="text-black" >“${props.previousTerm}”</span> `,
    )
  )
})

const props = defineProps({
  message: {
    type: String,
    default: () => '',
  },
  previousTerm: {
    type: String,
    default: () => '',
  },
})

const search = function (term) {
  searchStore.parameters.q = term
  //searchStore.parameters.t = ''
  searchStore.parameters.p = 1

  searchStore.search()
}
</script>
