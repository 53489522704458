<template>
  <button @click="openMobileSearch()">
    <MagnifyingGlassIcon class="text-light-blue-900 h-6 w-6" />
    <span class="sr-only"> Search </span>
  </button>
  <transition
    enter-from-class="-translate-y-[100%] opacity-0"
    leave-to-class="-translate-y-[100%] opacity-0"
    enter-active-class="transition duration-500"
    leave-active-class="transition duration-500"
  >
    <div
      v-if="menuState.isMobileSearchOpen"
      class="absolute left-0 top-0 z-50 flex w-full gap-3 bg-white px-4 py-3"
    >
      <div class="relative h-12 flex-1 gap-3">
        <button class="absolute left-3 top-[14px]">
          <MagnifyingGlassIcon class="text-grey-500 h-5 w-5" />
          <span class="sr-only"> Search </span>
        </button>
        <input
          ref="searchInput"
          v-model="searchText"
          :placeholder="header?.searchPlaceholderText"
          @input="sayt()"
          @keyup.enter="searchRedirect"
          class="border-grey-300 text-grey-500 active:border-grey-300 flex h-full w-full justify-between rounded-xl border px-12 py-3 text-base shadow-sm hover:bg-white active:bg-white"
        />
        <button v-if="searchText" class="absolute right-3 top-[14px]" @click="clearMobileSearch()">
          <XMarkIcon class="text-grey-600 h-5 w-5" />
          <span class="sr-only"> Clear search input </span>
        </button>
      </div>
      <div class="relative right-0 top-0 flex space-x-2">
        <button
          class="cursor-hover bg-light-blue-900 rounded-xl px-3 py-2 text-base leading-6"
          @click="closeMobileSearch()"
        >
          <span class="text-light-blue-100"> Close </span>
          <span class="sr-only"> Close Search Input </span>
        </button>
      </div>
      <div
        v-if="searchText"
        class="absolute left-0 top-[72px] z-50 flex h-screen w-full overflow-auto bg-white px-5 pt-6"
      >
        <div v-if="headerStore.isLoading" class="w-full">
          <SkeletonLoader>
            <SearchResultsSvg></SearchResultsSvg>
          </SkeletonLoader>
        </div>
        <div v-else-if="headerStore.isSearchResponseValid" class="w-full space-y-5">
          <MobileSearchResults
            v-if="headerStore.hasSearchResults"
            @close-search-modal="closeMobileSearch"
          ></MobileSearchResults>

          <div
            v-if="headerStore.hasSearchResults"
            class="mb-10 inline-flex h-10 w-full flex-col items-center justify-start gap-2.5"
          >
            <s-button
              :size="'regular'"
              :emphasis="'bold'"
              :style="'primary'"
              class="inline-flex h-10 items-center justify-center gap-3 rounded-xl bg-sky-900 px-3 py-2 text-justify text-base font-medium text-sky-50"
              @click="searchRedirect"
            >
              See All Results
            </s-button>
          </div>
          <MobileNoResults v-else @click.prevent.stop />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import SearchResultsSvg from '@TodayInTheWord/components/icons/search-mobile-skeleton.vue'
import MobileSearchResults from '@TodayInTheWord/components/shared/header/components/main-nav/mobile/search-results.vue'
import SkeletonLoader from '@TodayInTheWord/components/shared/skeleton-svg.vue'
import debounce from '@TodayInTheWord/scripts/debounce'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import { storeToRefs } from 'pinia'
import { ref, VNodeRef, watch } from 'vue'
import MobileNoResults from './mobile-no-results.vue'

const headerStore = useHeaderStore()
const { header, menuState, searchText } = storeToRefs(headerStore)

const searchInput = ref<VNodeRef | null>(null)

function openMobileSearch() {
  menuState.value.isMobileSearchOpen = true
  menuState.value.isMobileMenuOpen = false
}

function clearMobileSearch() {
  headerStore.clearSearch()
  searchInput.value?.focus()
}

function closeMobileSearch() {
  menuState.value.isMobileSearchOpen = false
}

const saytDebounce = debounce(headerStore.search, 1000)
function sayt() {
  saytDebounce()
}

function searchRedirect() {
  if ((searchText.value ?? '').length === 0) {
    searchInput.value?.focus()
    return
  }

  const newLocation =
    (header.value?.searchPageLink ?? '/search') + `?q=${encodeURIComponent(searchText.value)}`

  window.location.href = newLocation
}

watch(searchInput, (newValue) => {
  if (newValue?.value != null) {
    searchInput.value.focus()
    searchInput.value.select()
  }
})
</script>
