﻿import * as webApi from '@shared/api/web-api'
import { defineStore } from 'pinia'
import { EpiPageContextState } from './epi-page-context-types'

const STORE_ID = 'EPI_PAGE_CONTEXT'

const defaultState: EpiPageContextState = {
  model: null,
  header: {},
  footer: {},
  metadata: null,
  breadcrumbs: null,
  contentSharing: null,
  siteAlerts: null,
  isLoading: true,
  isLoadError: false,
  status: 200,
  contextMode: '',
  errorMessage: null,
  startLoadTime: null,
  endLoadTime: null,
}

const searchPageTypes = ['TitwSearchPage', 'TitwSearchSegmentPageBase']

export const useEpiPageContextStore = defineStore(STORE_ID, {
  state: (): EpiPageContextState => defaultState,
  getters: {
    isSearchPage(): boolean {
      const contentTypes: string[] = this.model?.contentType ?? []
      for (const contentType of contentTypes) {
        for (const registeredSearchType of searchPageTypes) {
          if (registeredSearchType === contentType) {
            return true
          }
        }
      }

      return false
    },
    pageType(): string {
      if (this.model?.contentType && this.model.contentType.length > 0) {
        return this.model.contentType[this.model.contentType.length - 1]
      }

      return 'page'
    },
    duration(): number | undefined {
      if (!this.startLoadTime || !this.endLoadTime) {
        return
      }

      return this.endLoadTime - this.startLoadTime
    },
  },
  actions: {
    async fetchPageContext(url: string) {
      // document is null during server-side rendering
      if (typeof document === 'undefined') {
        console.log(`document is null; cannot fetch page model via xhr: [${url}]`)
        return
      }

      this.isLoading = true
      this.startLoadTime = performance.now()
      this.endLoadTime = null
      const apiResponse = await webApi.callAsync<any>({
        method: 'get',
        url: url,
        headers: { Accept: 'application/json' },
      })

      this.status = apiResponse.status
      this.contextMode = apiResponse.data?.contextMode

      const data = apiResponse.data
      if (data?.page) {
        this.model = data.page ?? {}
        this.metadata = data.metadata ?? {}
        this.breadcrumbs = data.breadcrumbs ?? {}
        this.contentSharing = data.contentSharing ?? {}
        this.customizations = data.customizations ?? {}
      } else {
        this.model = data ?? {}
      }

      this.isLoading = false
      this.endLoadTime = performance.now()
      if (apiResponse.success) {
        this.isLoadError = false
        this.errorMessage = null
      } else {
        this.isLoadError = true
        this.errorMessage = apiResponse.errorMessage
        this.model = null
      }
    },
    setInitialState(model: any) {
      if (!model) {
        return
      }

      if (model.pageLayout) {
        this.model = model.pageLayout.page ?? null
        this.metadata = model.pageLayout.metadata ?? null
        this.breadcrumbs = model.pageLayout.breadcrumbs ?? null
        this.contentSharing = model.pageLayout.contentSharing ?? null
        this.customizations = model.pageLayout.customizations ?? null
      }

      if (model.siteLayout) {
        this.siteAlerts = model.siteLayout.siteAlerts ?? null
      }

      this.isLoading = false
      this.isLoadError = false
      this.status = 200
    },
  },
})
