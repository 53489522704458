<template>
  <div
    class="menu border-grey-200 absolute inset-x-0 inset-y-full z-50 h-96 w-full rounded-b-xl border border-solid bg-white drop-shadow-md"
    v-c2-click-outside="hidePrimaryMenu"
  >
    <div v-if="submenu?.columns" class="container mx-auto grid grid-cols-12 px-7 pb-20 pt-14">
      <div
        v-for="(column, index) in submenu?.columns"
        :key="index"
        class="col-span-3 flex flex-col"
      >
        <s-link
          v-if="column.headerLink"
          :href="column.headerLink.href"
          :target="column.headerLink.target"
          :external-link="column.headerLink.isExternal"
          class="mb-5 flex items-center font-medium"
        >
          {{ column.headerLink.text }}
          <ArrowSmallRightIcon class="ml-2 h-5 w-5"></ArrowSmallRightIcon>
        </s-link>
        <s-link
          v-else
          href="#"
          target="_self"
          external-link="false"
          class="mb-5 flex items-center font-medium"
        ></s-link>
        <ul v-if="column.links && column.links.length > 0" class="flex flex-col space-y-4">
          <li v-for="(link, index) in column.links" :key="index">
            <s-link
              class="text-grey-700 font-medium"
              :href="link.href"
              :external-link="link.isExternal"
              :target="link.target"
            >
              {{ link.text }}
            </s-link>
          </li>
        </ul>
        <ul v-else class="flex flex-col space-y-4"></ul>
      </div>
    </div>
    <div v-else class="container mx-auto grid grid-cols-12 px-7 pb-20 pt-14"></div>
  </div>
</template>

<script setup lang="ts">
import { ArrowSmallRightIcon } from '@heroicons/vue/24/solid'
import { SubMenu } from '@TodayInTheWord/stores/header/header-types'
import vC2ClickOutside from 'uikitdirectives/c2-click-outside'

const emit = defineEmits(['hidePrimaryMenu'])

const hidePrimaryMenu = () => {
  emit('hidePrimaryMenu')
}

interface SubMenuProps {
  submenu?: SubMenu
}

const props = defineProps<SubMenuProps>()
</script>
