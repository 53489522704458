<template>
  <div class="py-5" :class="{ 'border-grey-300': !mediaStore.isMobile }">
    <bread-crumbs
      :links="modifiedBreadcrumbs.links"
      :currentPageName="modifiedBreadcrumbs.currentPageName"
    />
  </div>
  <main class="container mx-auto">
    <div class="mt-5 lg:mt-12 lg:grid lg:grid-cols-12">
      <div class="col-span-12">
        <h1 v-if="model.pageHeading" class="sr-only">
          {{ model.pageHeading }}
        </h1>
        <search-filters-component
          :search-component="model.searchComponent"
          :segment-link-tabs="model.segmentLinks"
          :hideEmptyTabs="true"
          @search-filter-change="onSearchFiltersChanged"
        />
      </div>
      <div class="col-span-8">
        <error-message :text="searchStore.bibleSearchResults?.errorMessage" />

        <popular-searches-mobile
          v-show="!mediaStore.isDesktop"
          :model="model.popularSearchTerms"
          @new-search="newSearch"
        />
        <div v-show="searchStore.isLoading">
          <SkeletonLoader>
            <SearchPageSkeleton />
          </SkeletonLoader>
        </div>

        <div v-if="!searchStore.isLoading">
          <bible-gateway-full
            :segment="epiPageContext.model"
            :bible-search-results="searchStore.bibleSearchResults"
            @change-page="gotoPage"
          ></bible-gateway-full>
        </div>
      </div>
      <div class="lg:col-span-3 lg:col-start-10">
        <popular-searches-desktop
          v-show="mediaStore.isDesktop"
          :model="model.popularSearchTerms"
          @new-search="newSearch"
        />
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import * as routeHelper from '@shared/api/route-helper'
import * as searchRequestBuilder from '@shared/api/search-request-builder'
import * as webApi from '@shared/api/web-api'
import SearchPageSkeleton from '@TodayInTheWord/components/icons/search-page-skeleton.vue'
import { EventName } from '@TodayInTheWord/components/pages/shared/search-filter-component/types/search-filter-types'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import SkeletonLoader from '@TodayInTheWord/components/shared/skeleton-svg.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import * as indexTypes from '@TodayInTheWord/types/index-search-types'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import { computed, onMounted, ref } from 'vue'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import searchFiltersComponent from '../shared/search-filter-component/search-filters-component.vue'
import ErrorMessage from './error-message.vue'
import NoResults from './no-results.vue'
import bibleGatewayFull from './segments/bible-gateway-search/bible-gateway-full.vue'
import popularSearchesDesktop from './segments/popular-searches/popular-searches-desktop.vue'
import popularSearchesMobile from './segments/popular-searches/popular-searches-mobile.vue'

const headerStore = useHeaderStore()
const mediaStore = useMediaSizeStore()

const epiPageContext = useEpiPageContextStore()
const breadcrumbs: BreadcrumbProps = epiPageContext.breadcrumbs

const router = useRouter()
const route = useRoute()
const model: any = useEpiPageContextStore().model
const searchStore = useSearchPageStore()

const currentPage = ref(routeHelper.getFirstQueryParamAsNumber(route.query, 'p', 1))
const query = ref(routeHelper.getFirstQueryParameter(route.query, 'q'))

interface ISearchPageState {
  searchResults: indexTypes.SearchResults | null
  searchParams: searchRequestBuilder.ISearchParams
  isLoading: boolean
  segmentPath: string
}

const state = ref<ISearchPageState>({
  searchResults: null,
  isLoading: false,
  searchParams: {},
  segmentPath: '',
})

const initializePageState = () => {
  state.value.searchParams.q = routeHelper.getFirstQueryParameter(route.query, 'q')
  state.value.searchParams.c = routeHelper.getFirstQueryParamAsStringArray(route.query, 'c')
  state.value.searchParams.p = routeHelper.getFirstQueryParamAsNumber(route.query, 'p', 1)
  state.value.searchParams.d = routeHelper.getFirstQueryParameter(route.query, 'd')
  state.value.searchParams.a = routeHelper.getFirstQueryParamAsStringArray(route.query, 'a')

  const queryParamT = routeHelper.getFirstQueryParameter(route.query, 't')

  if (!queryParamT) {
    state.value.searchParams.t = 'All'
  } else {
    state.value.searchParams.t = queryParamT
  }
}

const modifiedBreadcrumbs = computed((): BreadcrumbProps | null => {
  if (!breadcrumbs) {
    return null
  }
  return {
    ...breadcrumbs,
    links: breadcrumbs.links.map((link) => {
      if (link.href === '/search/' && route.query.q) {
        return {
          ...link,
          href: `${link.href}?q=${route.query.q}`,
        }
      }
      return link
    }),
  }
})

onMounted(async () => {
  searchStore.setApiUrls(model.segmentApiUrls)
  searchStore.setCurrentSegmentId(model.segmentId)

  initializePageState()
  await fireSearchRequest()
})

const newSearch = (query: string) => {
  router.push({
    path: model.searchPageUrl,
    query: { q: query },
  })
}

const gotoPage = (page: number) => {
  if (page <= 0) {
    return
  }

  const newLocation: RouteLocationRaw = {
    path: route.path,
    query: {
      q: route.query.q,
    },
  }

  if (page > 1 && newLocation.query) {
    newLocation.query.p = page
  }

  router.push(newLocation)
}

function setUrlToMatchSearchParams(searchParams: searchRequestBuilder.ISearchParams) {
  const newLocation: RouteLocationRaw = {
    path: state.value.segmentPath,
    query: {},
  }

  newLocation.query.q = searchParams.q

  if (searchParams.c && searchParams.c.length > 0) {
    newLocation.query.c = searchParams.c.join()
  }

  if (searchParams.d !== '') {
    newLocation.query.d = searchParams.d
  }

  if (searchParams.t !== '') {
    newLocation.query.t = searchParams.t
  }

  if (searchParams.a && searchParams.a.length > 0) {
    newLocation.query.a = searchParams.a.join()
  }

  router.push(newLocation)
}

async function onSearchFiltersChanged(
  searchParams: searchRequestBuilder.ISearchParams,
  eventName: EventName,
) {
  state.value.searchParams.q = searchParams.q
  state.value.searchParams.c = searchParams.c
  state.value.searchParams.p = searchParams.p
  state.value.searchParams.d = searchParams.d
  state.value.searchParams.t = searchParams.t
  state.value.searchParams.a = searchParams.a

  getSegmentUrlPath(searchParams.t)

  setUrlToMatchSearchParams(searchParams)

  fireSearchRequest()
}

async function fireSearchRequest() {
  await searchStore.searchSegment(state.value.searchParams, model.segmentId)
}

function getSegmentUrlPath(selectedTabName: string | undefined) {
  model.segmentLinks.forEach((link) => {
    if (link.segmentId === selectedTabName) {
      state.value.segmentPath = link.url
    }
  })
}
</script>
