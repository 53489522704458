﻿<!--
    Rendered when no view was found by block-component-selector.vue for the loaded block model.
-->

<template>
  <div>
    <p>
      <em>Could not load vue component.</em>
    </p>
  </div>
</template>
