﻿<template>
  <div :class="containerClasses">
    <s-header />
    <site-alert :model="siteAlerts" />
    <RouterView :key="$route.path" />
    <s-footer />
  </div>
</template>

<script setup lang="ts">
import siteAlert from '@TodayInTheWord/components/blocks/site-alert/site-alert-block.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { computed, onMounted } from 'vue'

const mediaSizeStore = useMediaSizeStore()
const headerStore = useHeaderStore()
const menuState = headerStore.menuState
const siteAlerts = useEpiPageContextStore().siteAlerts

onMounted(async () => {
  mediaSizeStore.initStore()
  await headerStore.loadHeader()
})

const containerClasses = computed(() => {
  return [
    'TITW',
    'flex',
    'flex-col',
    'min-h-screen',
    {
      'max-h-screen': headerStore.menuState.isMobileMenuOpen,
      'overflow-y-hidden': headerStore.menuState.isMobileMenuOpen,
    },
    'overflow-x-hidden',
  ]
})
</script>

<style lang="scss">
div.max-h-screen.overflow-y-hidden {
  max-height: 120vh;
}

div.overflow-y-hidden {
  & main,
  & > div {
    max-height: 100%;
    overflow: hidden;
  }
}
</style>
