<template>
  <span v-if="model?.imageType === 'SVG'" v-html="model.xml" />
  <img v-else :src="imageUrl" :alt="model?.imageAlt" :loading="preload ? null : 'lazy'" />
</template>

<script setup lang="ts">
import { ImageProp } from '@TodayInTheWord/types/shared'
import { computed, onMounted } from 'vue'

interface Image {
  model?: ImageProp
  modelMobile?: ImageProp
  ranchor?: string | number
  desktopHeight?: number
  desktopWidth?: number
  mobileHeight?: number
  mobileWidth?: number
  svgFullWidth?: boolean
  imageClasses?: string
  ariaLabel?: string
  format?: string
  preload?: boolean
}

const props = defineProps<Image>()

const imageUrl = computed(() => {
  if (!props.model?.url || !props.format) {
    return props.model?.url
  }

  if (props.model.isExternal || props.model.url.endsWith('.svg')) {
    return props.model.url
  }

  return `${props.model.url}${props.format}`
})

// Add preload link dynamically if the preload prop is true
onMounted(() => {
  if (props.preload && props.model?.url) {
    const link = document.createElement('link')
    link.rel = 'preload'
    link.as = 'image'
    link.href = imageUrl.value
    document.head.appendChild(link)
  }
})
</script>
