import axios, { AxiosRequestConfig } from 'axios'

class WebApiResponse<T> {
  success: boolean = false
  status: number = 0

  data: T | undefined
  errorMessage: string | undefined
}

function getErrorMessage<T>(error: unknown, response: WebApiResponse<T>) {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      // Request made but the server responded with an error
      response.errorMessage = `HTTP-${error.response.status}`
      response.status = error.response.status
    } else if (error.request) {
      // Request made but no response is received from the server.
      response.errorMessage = 'No response from server.'
    } else {
      // Error occured while setting up the request
      response.errorMessage = error.message
    }
  } else if (error instanceof Error) {
    response.errorMessage = error.message
  }
}

async function callAsync<T>(
  request: AxiosRequestConfig,
): Promise<WebApiResponse<T>> {
  const response = new WebApiResponse<T>()
  try {
    const apiResponse = await axios(request)
    response.data = apiResponse.data as T
    response.status = apiResponse.status
    response.success = true
  } catch (error) {
    getErrorMessage(error, response)
  }

  return response
}

export { WebApiResponse, getErrorMessage, callAsync }
