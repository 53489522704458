<template>
  <div v-if="model">
    <div
      class="mx-auto w-full max-w-full lg:max-w-[856px]"
      :class="{ container: mediaSizeStore.isMobile }"
    >
      <div v-if="breadcrumbs">
        <bread-crumbs
          :links="breadcrumbs.links"
          :currentPageName="breadcrumbs.currentPageName"
          class="mt-4 lg:mt-6"
        />
      </div>
      <div class="mt-5 border-b border-slate-200 pb-8 lg:mt-6 lg:pb-12">
        <div
          v-if="model.featuredImageUrl"
          class="mb-4 aspect-video lg:mb-6 lg:overflow-hidden lg:rounded-xl"
          :class="{ '-mx-[18px]': mediaSizeStore.isMobile }"
        >
          <s-image
            v-if="model.featuredImageUrl"
            :model="{ url: model.featuredImageUrl }"
            class="h-full w-full object-cover"
            :preload="true"
          />
        </div>
        <div
          v-if="model.contentTag || model.displayDate"
          class="mb-3 flex justify-between lg:mb-6 lg:justify-normal lg:space-x-4"
        >
          <tag
            v-if="model.contentTag"
            class="flex items-center rounded-xl px-2 py-0.5 text-sm font-medium"
            :tag="{ displayName: model.contentTag, tagColor: 'light-blue' }"
          />
          <span v-if="model.displayDate" class="text-grey-600 text-base font-normal">
            {{ model.displayDate }}
          </span>
        </div>
        <div class="relative space-y-3 lg:space-y-0">
          <div class="flex flex-col space-y-1 lg:space-y-2">
            <h1 v-if="model.title" class="text-2xl font-semibold text-cyan-900 lg:text-4xl">
              {{ model.title }}
            </h1>
            <p v-if="model.subtitle" class="text-grey-700 text-base font-normal lg:text-xl">
              {{ model.subtitle }}
            </p>
          </div>
          <s-link
            v-if="model.viewStudyLinkText && model.viewStudyUrl"
            :href="model.viewStudyUrl"
            :emphasis="'bold'"
            :size="'regular'"
            :style="'primary'"
            class="lg:absolute lg:right-0 lg:top-0"
            >{{ model.viewStudyLinkText }}</s-link
          >
        </div>
      </div>
      <section class="mt-6 flex flex-col space-y-4 lg:mt-12 lg:space-y-6">
        <div
          v-for="(starter, index) in model?.discussionStarters"
          :key="index"
          class="flex flex-col space-y-3 lg:flex-row lg:justify-between lg:space-y-0"
        >
          <s-link
            v-if="starter.devotionalDetailUrl"
            :href="starter.devotionalDetailUrl"
            class="text-base font-semibold text-cyan-900"
          >
            {{ starter.label }}
          </s-link>
          <div v-else class="text-base font-semibold text-cyan-900">
            {{ starter.label }}
          </div>
          <RichTextBlock
            v-if="starter.discussionStarterText"
            :model="{ richTextField: starter.discussionStarterText }"
            class="w-full lg:max-w-[729px]"
          />
        </div>
        <div class="relative mt-8 flex lg:mt-16">
          <share-this />
          <button class="printButton bg-transparent p-3" @click.prevent="handlePrint">
            <PrinterIcon class="stroke-light-blue-900 hover:stroke-light-blue-600 h-6 w-6" />
          </button>
        </div>
      </section>
    </div>
    <section
      v-if="model.additionalContent && model.additionalContent.length > 0"
      class="mt-8 space-y-8 lg:mt-[108px] lg:space-y-16"
    >
      <block-component-selector
        v-for="(content, index) in model.additionalContent"
        :key="index"
        :model="content.model"
        :has-container="false"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import RichTextBlock from '@brand/components/blocks/rich-text-block/rich-text-block.vue'
import { PrinterIcon, ShareIcon } from '@heroicons/vue/24/outline'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import SImage from '@TodayInTheWord/components/shared/s-image.vue'
import sLink from '@TodayInTheWord/components/shared/s-link.vue'
import ShareThis from '@TodayInTheWord/components/shared/share-this/s-share-this-button.vue'
import tag from '@TodayInTheWord/components/shared/tag.vue'
import BlockComponentSelector from '@TodayInTheWord/epi/block-component-selector.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { BreadcrumbProps } from 'TodayInTheWord/types/shared'
import { DiscussionStarterDetailProps } from './types/discussion-starter-detail-types'

const epiPageContextStore = useEpiPageContextStore()
const model: DiscussionStarterDetailProps['model'] = epiPageContextStore.model
const breadcrumbs: BreadcrumbProps = epiPageContextStore.breadcrumbs
const mediaSizeStore = useMediaSizeStore()

const handlePrint = () => {
  window.print()
}
</script>

<style scoped></style>
