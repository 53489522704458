<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 18.8823V5C5 3.89543 5.89543 3 7 3H19V16.7647C19 16.7647 17.9231 16.7647 17.9231 17.8235V19.9412C17.9231 20.4706 18.4615 21 19 21"
      stroke="#0C4A6E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      :class="props.fillClass"
    />
    <path
      d="M12 13V7M10 8.6H14"
      stroke="#0C4A6E"
      stroke-width="2"
      stroke-linecap="round"
      :class="props.fillClass"
    />
    <path
      d="M19 16H7C5.89543 16 5 16.8954 5 18V19C5 20.1046 5.89543 21 7 21H19"
      stroke="#0C4A6E"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  fillClass: {
    type: String,
    default: null,
  },
})
</script>
