<template>
  <ul class="mt-6 flex flex-wrap items-center gap-x-4 gap-y-3 md:mt-0">
    <li v-for="(item, index) in footer?.utilityLinks" :key="index">
      <s-link
        :href="item.href"
        :target="item.target"
        :external-link="item.isExternal"
        class="text-xs"
      >
        {{ item.text }}
      </s-link>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useFooterStore } from '@TodayInTheWord/stores/footer/footer'
import { UtilityNavProps } from '@TodayInTheWord/stores/footer/footer-types'

const footer = useFooterStore().footer

const props = defineProps<UtilityNavProps>()
</script>
