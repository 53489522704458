<template>
  <div v-if="card" class="border-lighter-grey w-full border-b pb-7 lg:pb-10">
    <div class="flex w-full flex-col-reverse lg:flex-row lg:justify-between lg:space-x-10">
      <div class="flex w-full flex-col space-y-4 lg:space-y-3">
        <div v-if="!mediaSizeStore.isMobile" class="flex items-center space-x-3">
          <tag
            v-if="card.contentTag"
            :tag="{ displayName: card.contentTag, tagColor: 'light-blue' }"
            class="flex w-fit items-center justify-center rounded-xl px-2 py-0.5 text-xs font-medium"
          />
          <div v-if="card.date" class="text-grey-600 text-xs font-medium">{{ articleDate }}</div>
        </div>
        <div class="space-y-1">
          <s-link
            v-if="card.title && card.url"
            :href="card.url"
            :external-link="true"
            class="hover:text-light-blue-600 text-base font-semibold text-cyan-900 transition-all duration-300 lg:text-xl"
          >
            {{ card.title }}
          </s-link>
          <div
            v-if="card.description"
            class="text-grey-700 line-clamp-2 text-xs font-normal leading-5"
          >
            {{ card.description }}
          </div>
        </div>
        <div
          v-if="card.mainAuthorImageUrl || card.mainAuthorName"
          class="mb-3 flex items-center space-x-3 py-1 lg:mb-10 lg:py-2"
        >
          <div class="aspect-square h-8 w-8 overflow-hidden rounded lg:h-12 lg:w-12">
            <s-image :model="{ url: card.mainAuthorImageUrl }" class="h-full w-full object-cover" />
          </div>
          <span v-if="card.mainAuthorName" class="text-grey-800 text-sm font-medium lg:text-base">{{
            card.mainAuthorName
          }}</span>
        </div>
        <s-link
          v-if="card.url"
          class="text-light-blue-900 mb-3 flex w-fit items-center py-1 text-sm font-medium md:mb-2 lg:py-2"
          :href="card.url"
          :external-link="true"
        >
          Read More
          <span><ArrowRightIcon class="ml-2.5 h-4 w-4" /></span>
        </s-link>
      </div>
      <div class="my-4 w-full overflow-hidden rounded-xl lg:my-0 lg:max-h-[216px] lg:max-w-[386px]">
        <s-image :model="{ url: card.image }" class="w-full object-cover" />
      </div>
      <div v-if="mediaSizeStore.isMobile" class="flex items-center space-x-3">
        <tag
          v-if="card.contentTag"
          :tag="{ displayName: card.contentTag, tagColor: 'light-blue' }"
          class="flex w-fit items-center justify-center rounded-xl px-2 py-0.5 text-xs font-medium"
        />
        <div v-if="card.date" class="text-grey-600 text-xs font-medium">{{ articleDate }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowRightIcon } from '@heroicons/vue/24/solid'
import { useMediaSizeStore } from '@MoodyBible/stores/media-size'
import sImage from '@TodayInTheWord/components/shared/s-image.vue'
import sLink from '@TodayInTheWord/components/shared/s-link.vue'
import tag from '@TodayInTheWord/components/shared/tag.vue'
import { TitwSearchResultItemModel } from '@TodayInTheWord/stores/search-page/search-types'
import { DateTime } from 'luxon'
import { computed } from 'vue'

interface ArticleIndexSearchResult {
  card: TitwSearchResultItemModel
}

const props = defineProps<ArticleIndexSearchResult>()
const mediaSizeStore = useMediaSizeStore()

const articleDate = computed(() => {
  if (props.card.date) {
    const date = DateTime.fromISO(props.card.date)
    return date.toFormat('MMMM dd, yyyy')
  }
})
</script>

<style scoped></style>
