<template>
  <div v-if="results?.items" class="md:flex-no-wrap flex flex-wrap md:-mx-3">
    <template v-for="item in results.items">
      <daily-devotional-card
        :model="item"
        class="md:max-w-1/3 group relative mb-4 flex w-full flex-col justify-between rounded-xl px-3 md:w-1/3 md:px-3"
      />
    </template>
    <shared-pagination
      class="w-full"
      v-show="results.totalPages > 1"
      :current-page="results.page"
      :total-pages="results.totalPages"
      @page-changed="pageChanged"
    />
  </div>
</template>

<script setup lang="ts">
import SharedPagination from '@TodayInTheWord/components/shared/s-pagination.vue'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import DailyDevotionalCard from './daily-devotional-card.vue'

interface PagesProperties {
  segment: searchTypes.ISegmentModel
  results: searchTypes.TitwSearchTabResults | null
}

const emit = defineEmits<{
  changePage: [page: number]
}>()

const props = withDefaults(defineProps<PagesProperties>(), {
  results: null,
})

const pageChanged = (page: number) => {
  emit('changePage', page)
}
</script>

<style lang="scss"></style>
