<template>
  <div
    class="account-menu-mobile flex h-full w-full"
    v-if="headerStore.showAccountMenu"
  >
    <s-button
      class="text-light-blue-900 flex items-center space-x-2 border-none bg-transparent px-0 py-1.5 pb-1.5 pt-1.5 font-normal"
      @click="navigateTo(accountMenu?.url)"
    >
      <UserCircleIcon class="text-light-blue-900 h-5 w-5" />
      <span class="text-light-blue-900"> {{ accountMenu?.label }} </span>
    </s-button>
    <ul
      class="absolute right-0 top-0 h-screen w-full bg-white p-5 transition-all ease-in"
      :class="
        menuState.isMobileAccountMenuOpen
          ? 'opacity-1 visible translate-x-0'
          : 'invisible translate-x-full opacity-0'
      "
    >
      <s-button
        class="flex items-center border-none !bg-transparent px-0 py-1.5 font-normal"
        @click="closeMenu"
      >
        <ArrowLeftIcon class="text-light-blue-900 h-5 w-5"></ArrowLeftIcon>
        <span class="text-light-blue-900 ml-2.5"> Back </span>
      </s-button>
      <li
        v-for="(item, index) in accountMenu?.menuItems"
        :key="index"
        class="mt-7 w-full"
      >
        <a
          class="text-light-blue-900 flex w-full items-center justify-between"
          :href="item.href"
          @click="closeMenu"
        >
          {{ item.text }}
          <ArrowRightIcon class="text-light-blue-900 h-5 w-5"></ArrowRightIcon>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'
import { UserCircleIcon } from '@heroicons/vue/24/solid'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'

const headerStore = useHeaderStore()
const accountMenu = headerStore.header?.accountMenu
const menuState = headerStore.menuState

const navigateTo = function (url: string | undefined) {
  if (url && url.length > 0) {
    window.location.href = url
    return
  }

  if (
    accountMenu &&
    accountMenu.menuItems &&
    accountMenu.menuItems.length > 0
  ) {
    menuState.isMobileAccountMenuOpen = !menuState.isMobileAccountMenuOpen
  }
}

function openMenu() {
  menuState.isMobileAccountMenuOpen = true
}

function closeMenu() {
  menuState.isMobileAccountMenuOpen = false
}
</script>
