import { defineStore } from 'pinia'

const STORE_ID = 'COOKIE_STORE'

export const useCookiesStore = defineStore(STORE_ID, {
  actions: {
    getCookie(cname: any) {
      let name = `${cname}=`
      let decodedCookie = decodeURIComponent(document.cookie)
      let ca = decodedCookie.split(';')

      for (let item of ca) {
        let c = item.trim()

        if (c.startsWith(name)) {
          return c.substring(name.length, c.length)
        }
      }

      return ''
    },

    setCookie(cname: any, cvalue: any, exdays?: any) {
      if (exdays) {
        const date = new Date()
        date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000)
        let expires = `expires=${date.toUTCString()}`

        document.cookie = `${cname}=${cvalue};${expires};path=/`
      } else {
        document.cookie = `${cname}=${cvalue};path=/`
      }
    },
    deleteCookie(cname: any) {
      let name = `${cname}=`
      let decodedCookie = decodeURIComponent(document.cookie)
      let ca = decodedCookie.split(';')

      for (let item of ca) {
        let c = item.trim()

        if (c.startsWith(name)) {
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
        }
      }

      return ''
    },
  },
})
