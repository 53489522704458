<template>
  <ul
    v-if="header.primaryMenu && header.primaryMenu.length > 0"
    class="align-center bg-color text-light-blue-50 ml-8 flex space-x-4 pt-6"
  >
    <li
      v-for="(item, index) in header.primaryMenu"
      :key="index"
      class="group flex cursor-pointer flex-col justify-center pb-6"
      v-on:mouseenter="showPrimaryMenu(index)"
      v-on:mouseleave="hidePrimaryMenu()"
    >
      <s-link
        v-if="item.text"
        :href="item.url"
        :external-link="item.isExternal"
        :target="item.target"
        class="!text-light-blue-900 group-hover:bg-light-blue-900 flex max-h-8 flex-col justify-center rounded-xl border-none bg-transparent px-3 py-1.5 text-sm font-medium group-hover:!text-white"
        v-on:focus="showPrimaryMenu(index)"
        ref="menuLinks"
      >
        {{ item.text }}
      </s-link>
      <s-link
        v-else
        href="#"
        target="_self"
        external-link="false"
        class="!text-light-blue-900 group-hover:bg-light-blue-900 flex max-h-8 flex-col justify-center rounded-xl border-none bg-transparent px-3 py-1.5 text-sm font-medium group-hover:!text-white"
      ></s-link>
      <transition
        enter-from-class="translate-y-[-10%] opacity-0"
        leave-to-class="translate-y-[0%] opacity-0"
        enter-active-class="transition duration-300"
        leave-active-class="transition duration-300"
      >
        <SubMenu
          v-show="
            header.showMenu &&
            header.activeMenu === index &&
            item.submenu &&
            item.submenu.columns &&
            item.submenu.columns.length > 0
          "
          :submenu="item.submenu"
          @keydown.esc="(tabbingControls = false), handleEscKey(index)"
          @keydown.tab="tabbingControls = true"
          @close-primary-menu="closePrimaryMenu()"
        >
        </SubMenu>
      </transition>
    </li>
  </ul>
  <ul v-else class="align-center bg-color text-light-blue-50 ml-8 flex space-x-4 pt-6"></ul>
</template>

<script setup lang="ts">
import SubMenu from '@TodayInTheWord/components/shared/header/components/main-nav/desktop/sub-menu.vue'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import { ref } from 'vue'

const header = useHeaderStore().header
const menuLinks = ref(null)
let tabbingControls = false

interface ParentLink {
  $el: HTMLElement
}

function showPrimaryMenu(index: number) {
  header.showMenu = true
  header.activeMenu = index
}

function hidePrimaryMenu() {
  if (!tabbingControls) {
    header.activeMenu = 0
    header.showMenu = false
  }
}

function closePrimaryMenu() {
  tabbingControls = false
  header.activeMenu = 0
  header.showMenu = false
}

function handleEscKey(index: number) {
  const parentLink = menuLinks.value?.[index] as ParentLink | undefined

  if (parentLink) {
    parentLink.$el.focus()
    hidePrimaryMenu()
  }
}
</script>
