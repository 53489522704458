<template>
  <section v-if="model" v-bind:id="model.clientID">
    <div class="TITW image-block container mx-auto">
      <section-heading
        v-if="model.heading || model.subheading"
        :heading="model.heading"
        :subheading="model.subheading"
      />
      <picture v-if="model.image">
        <shared-image
          :model="model.image"
          class="my-6 h-full w-full rounded-xl object-cover"
          :class="{
            'lg:aspect-[16/9]': model.aspectRatio === '16/9',
            'lg:aspect-[3/2]': model.aspectRatio === '3/2',
            'lg:aspect-[1/1]': model.aspectRatio === '1/1',
          }"
          :format="computeImageFormat"
        />
      </picture>
      <div v-if="model.description" class="description text-xs font-normal text-black">
        <RichTextBlock
          :hasContainer="false"
          :model="{ richTextField: model.description }"
          classOverride="prose-sm md:prose !max-w-none"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import RichTextBlock from '@TodayInTheWord/components/blocks/rich-text-block/rich-text-block.vue'
import SectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import { computed } from 'vue'
import { ImageBlockProps } from './types/image-block-types'

const props = defineProps<ImageBlockProps>()

const computeImageFormat = computed(() => {
  var imageWidth = 850
  var imageHeight = 0
  if (props.model?.imageAspectRatio === '3/2') {
    imageHeight = (imageWidth * 2) / 3
  } else if (props.model?.imageAspectRatio === '1/1') {
    imageHeight = imageWidth * 1
  } else {
    imageHeight = (imageWidth * 9) / 16
  }
  imageHeight = Math.round(imageHeight)
  return `?width=${imageWidth}&height=${imageHeight}&format=webp&compand=true`
})
</script>
<style lang="scss">
.description {
  font-size: 0.8em;
}
</style>
