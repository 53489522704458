<template>
  <div class="mb-4 flex items-center md:mb-8" v-if="model">
    <h2
      class="text-grey-700 overflow-hidden text-base font-semibold md:text-2xl lg:text-xl"
      v-if="model.heading"
    >
      {{ model.heading }}
    </h2>
    <span class="bg-lighter-grey mx-4 flex h-[1px] flex-grow"></span>
    <div class="group flex" v-if="model.showAllLink">
      <s-link
        class="text-light-blue-900 flex items-center space-x-2 text-base font-medium"
        :href="model.showAllLink.href"
        @click.prevent="gotoSegment(model.showAllLink.href)"
      >
        <span>{{ model.showAllLink.text }}</span>
        <ArrowRightIcon class="hover:text-light-blue-700 h-5 w-5" />
      </s-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowRightIcon } from '@heroicons/vue/24/outline'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()

const emit = defineEmits(['changeTab'])

const props = defineProps({
  model: {
    type: Object,
    default: () => {},
  },
})

const gotoSegment = (segmentUrl: string): void => {
  const currentQuery = route.query?.q ?? ''

  router.push({
    path: segmentUrl,
    query: {
      q: currentQuery,
      t: props.model.heading,
      a: route.query?.a,
      c: route.query?.c,
      d: route.query?.d,
    },
  })
}
</script>

<style lang="scss"></style>
