<template>
  <div class="flex h-full w-full" v-if="headerStore.showLanguageMenu">
    <s-button
      class="flex items-center space-x-2 border-none bg-transparent px-0 py-1.5 font-normal"
      @click="openMenu"
    >
      <GlobeAltIcon class="text-light-blue-900 h-5 w-5" />
      <span class="text-light-blue-900" v-if="header.languageMenu?.label">
        {{ header.languageMenu?.label }}
      </span>
    </s-button>
    <ul
      class="absolute right-0 top-0 h-screen w-full bg-white p-5 transition-all ease-in"
      :class="
        menuState.isMobileLanguageMenuOpen
          ? 'opacity-1 visible translate-x-0'
          : 'invisible translate-x-full opacity-0'
      "
    >
      <s-button
        class="flex items-center border-none !bg-transparent px-0 py-1.5 font-normal"
        @click="closeMenu"
      >
        <ArrowLeftIcon class="text-light-blue-900 h-5 w-5"></ArrowLeftIcon>
        <span class="text-light-blue-900 ml-2.5"> Back </span>
      </s-button>
      <li
        v-for="(item, index) in header.languageMenu?.items"
        :key="index"
        class="mt-7 w-full"
      >
        <s-link
          class="text-light-blue-900 flex w-full items-center justify-between"
          :href="item.href"
          target="_self"
          :external-link="true"
          @click="closeMenu"
        >
          {{ item.name }}
          <ArrowRightIcon class="text-light-blue-900 h-5 w-5"></ArrowRightIcon>
        </s-link>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  GlobeAltIcon,
} from '@heroicons/vue/24/outline'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'

const headerStore = useHeaderStore()
const header = headerStore.header
const menuState = headerStore.menuState

function openMenu() {
  menuState.isMobileLanguageMenuOpen = true
}

function closeMenu() {
  menuState.isMobileLanguageMenuOpen = false
}
</script>
