<template>
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="props.fillClass"
      d="M21.0575 2.94504C20.938 2.50107 20.704 2.09627 20.3789 1.77117C20.0538 1.44606 19.649 1.21204 19.205 1.09254C17.57 0.65004 11 0.65004 11 0.65004C11 0.65004 4.43001 0.65004 2.79501 1.09254C2.35104 1.21204 1.94625 1.44606 1.62114 1.77117C1.29603 2.09627 1.06202 2.50107 0.942515 2.94504C0.637224 4.61249 0.489075 6.30491 0.500015 8.00004C0.489075 9.69517 0.637224 11.3876 0.942515 13.055C1.06202 13.499 1.29603 13.9038 1.62114 14.2289C1.94625 14.554 2.35104 14.788 2.79501 14.9075C4.43001 15.35 11 15.35 11 15.35C11 15.35 17.57 15.35 19.205 14.9075C19.649 14.788 20.0538 14.554 20.3789 14.2289C20.704 13.9038 20.938 13.499 21.0575 13.055C21.3628 11.3876 21.511 9.69517 21.5 8.00004C21.511 6.30491 21.3628 4.61249 21.0575 2.94504ZM8.90001 11.15V4.85004L14.3525 8.00004L8.90001 11.15Z"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  fillClass: {
    type: String,
    default: null,
  },
})
</script>
