<template>
  <div>
    <div
      v-if="!props.isInList"
      class="audio-player bg-grey-50 border-grey-100 flex w-full flex-col space-y-3 rounded-xl border p-3 lg:p-4"
    >
      <div class="flex justify-between">
        <div v-if="props.title" class="title text-light-blue-900 text-base font-medium leading-6">
          {{ props.title }}
        </div>
        <div class="timer text-grey-700 text-base font-normal leading-6">
          {{ formatTime(currentTime) }} / {{ formatTime(duration) }}
        </div>
      </div>
      <div
        class="flex flex-row flex-wrap items-center justify-between lg:flex-nowrap lg:justify-normal"
      >
        <div class="order-2 lg:order-none">
          <button
            @click="togglePlayback"
            class="bg-light-blue-900 hover:bg-light-blue-700 mr-3 rounded-xl p-2 transition lg:p-3"
          >
            <span v-if="!isPlaying" class="flex items-center justify-center">
              <PlayIcon class="h-6 w-6 text-white" />
            </span>
            <span v-if="isPlaying" class="flex items-center justify-center">
              <PauseIcon class="h-6 w-6 text-white" />
            </span>
          </button>
        </div>

        <div class="order-1 mb-3 flex w-full lg:order-none lg:mb-0">
          <input
            ref="audioSlider"
            type="range"
            class="audio-slider order-1"
            :value="currentTime"
            @input="seek"
            :min="0"
            :max="durationMax"
            :step="0.1"
          />
        </div>
        <div class="order-3 ml-3 flex space-x-2 lg:order-none">
          <button
            @click="toggleRewind"
            class="bg-light-blue-600 hover:bg-light-blue-900 rounded-xl p-2 transition"
          >
            <span class="flex items-center justify-center">
              <RewindSvg class="h-6 w-6 text-white" />
            </span>
          </button>
          <button
            @click="toggleForward"
            class="bg-light-blue-600 hover:bg-light-blue-900 rounded-xl p-2 transition"
          >
            <span class="flex items-center justify-center">
              <ForwardSvg class="h-6 w-6 text-white" />
            </span>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="audio-player bg-grey-50 flex w-full flex-col space-y-3 py-4">
      <div class="flex flex-row items-center justify-between lg:flex-nowrap lg:justify-normal">
        <div class="lg:order-none">
          <button
            @click="updateSelected"
            class="bg-light-blue-900 hover:bg-light-blue-700 mr-3 rounded-xl p-2 transition lg:p-3"
          >
            <span class="flex items-center justify-center">
              <PlayIcon class="h-6 w-6 text-white" />
            </span>
          </button>
        </div>

        <div class="mb-3 flex w-full flex-col lg:order-none lg:mb-0">
          <div class="flex justify-between">
            <div
              v-if="props.title"
              class="title text-light-blue-900 text-base font-medium leading-6"
            >
              {{ props.title }}
            </div>
            <div class="timer text-grey-700 text-base font-normal leading-6">
              {{ formatTime(duration) }}
            </div>
          </div>
          <div v-if="props.description" class="text-light-blue-900 text-xs font-normal leading-5">
            {{ props.description }}
          </div>
          <div v-if="props.date" class="text-grey-600 text-xs font-normal leading-5">
            {{ props.date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PauseIcon, PlayIcon } from '@heroicons/vue/24/solid'
import ForwardSvg from '@TodayInTheWord/components/icons/forward-new-svg.vue'
import RewindSvg from '@TodayInTheWord/components/icons/rewind-new-svg.vue'
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'

interface AudioPlayerProps {
  title?: string
  src: string
  date?: String
  description?: string
  isInList?: boolean
}

const state = reactive({
  audio: null,
})

const props = defineProps<AudioPlayerProps>()
const emit = defineEmits(['updateSelected'])

const isPlaying = ref<boolean>(false)
const currentTime = ref<number>(0)
const duration = ref<number>(0)
const audioSlider = ref(null)
const initialPageLoad = ref<boolean>(true)

const durationMax = computed(() => Math.floor(duration.value))

const togglePlayback = () => {
  isPlaying.value ? state.audio.pause() : state.audio.play()
  isPlaying.value = !isPlaying.value
}

const updateSelected = () => {
  emit('updateSelected')
}

const toggleForward = () => {
  const newTime = state.audio.currentTime + 15
  if (newTime <= duration.value) {
    state.audio.currentTime = newTime
    currentTime.value = newTime
  } else {
    state.audio.currentTime = duration.value
    currentTime.value = duration.value
  }
  updateSliderStyle()
}

const toggleRewind = () => {
  const newTime = state.audio.currentTime - 15
  if (newTime >= 0) {
    state.audio.currentTime = newTime
    currentTime.value = newTime
  } else {
    state.audio.currentTime = 0
    currentTime.value = 0
  }
  updateSliderStyle()
}

const progress = computed(() => {
  return duration.value !== 0 ? (currentTime.value / duration.value) * 100 : 0
})

const seek = (event: Event) => {
  const seekTime = (event.target as HTMLInputElement).valueAsNumber

  state.audio.currentTime = seekTime
  currentTime.value = seekTime

  updateSliderStyle()
}

const updateSliderStyle = () => {
  if (audioSlider.value) {
    audioSlider.value.style.background = `linear-gradient(to right, #0369a1 ${progress.value}%, #bae6fd ${progress.value}%)`
  }
}

const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}

const updateTimer = () => {
  currentTime.value = state.audio?.currentTime || 0
  updateSliderStyle()
}

onMounted(() => {
  state.audio = new Audio()

  state.audio.src = props.src
  state.audio.addEventListener('timeupdate', updateTimer)
  state.audio.addEventListener('loadedmetadata', () => {
    duration.value = state.audio.duration
  })
})

onUnmounted(() => {
  state.audio.removeEventListener('timeupdate', updateTimer)
  state.audio.pause() // Pause the audio when the component is unmounted
  state.audio = null // Clear the audio element reference
})

watch(
  () => props.src,
  () => {
    isPlaying.value = false
    state.audio.src = props.src
    updateTimer()
    updateSliderStyle()

    if (initialPageLoad.value) {
      initialPageLoad.value = false
      return
    } else {
      togglePlayback()
    }
  },
)

watch(isPlaying, () => {
  isPlaying.value ? state.audio.play() : state.audio.pause()
})
</script>

<style lang="scss">
.audio-player {
  .audio-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    height: 6px;
    background: #bae6fd;
  }

  .audio-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 20px;
    background-color: #0369a1;
    border-radius: 50%;
    border: none;
  }

  .audio-slider::-moz-range-thumb {
    height: 20px;
    width: 20px;
    background-color: #0369a1;
    border-radius: 50%;
    border: none;
  }
}
</style>
