<template>
  <div class="space-y-9">
    <h2 v-if="model.heading" class="text-grey-700 text-2xl font-medium">
      {{ model.heading }}
    </h2>

    <ul v-if="model.menuItems && model.menuItems.length > 0" class="lg:w-3/4">
      <li v-for="(item, index) in model.menuItems" :key="index">
        <template v-if="item.href && !item.isActive">
          <s-link
            v-if="!item.isExternal"
            :href="item.href"
            class="text-grey-700 inline-flex -translate-x-3 transform rounded-xl bg-white px-3 py-1.5 text-sm font-medium"
            :class="{ 'text-light-blue-700': hasActiveChildItem(item) }"
          >
            {{ item.text }}
          </s-link>
          <a
            v-else
            :href="item.href"
            :target="item.target"
            class="text-grey-700 inline-flex -translate-x-3 transform rounded-xl bg-white px-3 py-1.5 text-sm font-medium"
            :class="{ 'text-light-blue-700': hasActiveChildItem(item) }"
          >
            {{ item.text }}
          </a>
        </template>
        <div
          v-else
          class="bg-light-blue-100 text-light-blue-700 inline-flex rounded-xl px-3 py-1.5 text-sm font-medium"
        >
          {{ item.text }}
        </div>

        <ul
          v-if="item.menuItems && item.menuItems.length > 0"
          class="flex flex-col py-2 pl-4"
        >
          <li v-for="(subItem, j) in item.menuItems" :key="j" class="w-full">
            <template v-if="subItem.href && !subItem.isActive">
              <s-link
                v-if="!subItem.isExternal"
                :href="subItem.href"
                class="text-grey-500 flex w-full rounded-xl bg-white px-3 py-1.5 text-sm"
              >
                {{ subItem.text }}
              </s-link>
              <a
                v-else
                :href="subItem.href"
                :target="subItem.target"
                class="text-grey-500 flex w-full rounded-xl bg-white px-3 py-1.5 text-sm"
              >
                {{ subItem.text }}
              </a>
            </template>
            <div
              v-else
              class="bg-light-blue-100 text-light-blue-700 flex w-full rounded-xl px-3 py-1.5 text-sm"
            >
              {{ subItem.text }}
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script setup>
const props = defineProps({
  model: {
    type: Object,
    default: () => ({}),
  },
})

function hasActiveChildItem(item) {
  if (item.menuItems && item.menuItems.length > 0) {
    return true
  } else {
    return false
  }
}
</script>
