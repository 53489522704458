<template>
  <div class="header-wrapper relative overflow-hidden">
    <h2
      v-if="props.heading"
      class="heading-class text-justify text-base font-semibold leading-normal text-gray-700 md:text-xl md:leading-7"
    >
      <!--text-grey-700 text-2xl font-bold md:text-3xl-->
      {{ props.heading }}
    </h2>
    <div
      v-if="props.subheading"
      class="subheading-class text-sm md:text-base"
      :class="props.subHeadingClasses"
    >
      {{ props.subheading }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { SectionHeadingProps } from './types/section-heading-types'

const props = defineProps<SectionHeadingProps>()
</script>
