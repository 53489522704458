<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="props.fillClass"
      d="M17.0025 0H0.9975C0.733553 0.00196251 0.480974 0.107686 0.29433 0.29433C0.107686 0.480974 0.00196251 0.733553 0 0.9975V17.0025C0.00196251 17.2664 0.107686 17.519 0.29433 17.7057C0.480974 17.8923 0.733553 17.998 0.9975 18H9.615V11.04H7.275V8.3175H9.615V6.315C9.615 3.99 11.0325 2.7225 13.1175 2.7225C13.815 2.7225 14.5125 2.7225 15.21 2.8275V5.25H13.7775C12.645 5.25 12.4275 5.79 12.4275 6.5775V8.31H15.1275L14.775 11.0325H12.4275V18H17.0025C17.2664 17.998 17.519 17.8923 17.7057 17.7057C17.8923 17.519 17.998 17.2664 18 17.0025V0.9975C17.998 0.733553 17.8923 0.480974 17.7057 0.29433C17.519 0.107686 17.2664 0.00196251 17.0025 0Z"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  fillClass: {
    type: String,
    default: null,
  },
})
</script>
