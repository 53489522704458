<!-- 
    PROP OPTIONS:
    emphasis: bold, outline, tonal
    size: smaller, regular, larger
    style: secondary, primary, neutral
-->
<template>
  <button :class="activeStyle">
    <slot></slot>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface SharedButtonProps {
  emphasis?: string
  size?: string
  classOverride?: string
  style?: string
}

const props = defineProps<SharedButtonProps>()

const activeStyle = computed(() => {
  const btnGlobal =
    'inline-flex items-center justify-center font-medium border-solid border rounded-xl cursor-pointer transition ease-in-out duration-300'
  let btnStyle = ''
  let btnSize = 'py-2 px-[14px] text-base'

  if (props.style?.toLowerCase() === 'secondary') {
    if (props.emphasis?.toLowerCase() === 'outline') {
      btnStyle = `bg-white text-cyan-800 border-cyan-600 
                        hover:bg-cyan-700 hover:text-cyan-50 hover:border-cyan-700
                        active:bg-cyan-100 active:border-cyan-600
                        disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
    } else if (props.emphasis?.toLowerCase() === 'tonal') {
      btnStyle = `bg-cyan-100 text-cyan-900 border-cyan-100 
                        hover:bg-cyan-700 hover:text-cyan-50 hover:border-cyan-700
                        active:bg-cyan-200 active:border-cyan-200
                        disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
    } else {
      btnStyle = `bg-cyan-600 text-cyan-50 border-cyan-600 
                        hover:bg-cyan-700 hover:border-cyan-700
                        active:bg-cyan-700 active:border-cyan-700
                        disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
    }
  } else if (props.style?.toLowerCase() === 'neutral') {
    if (props.emphasis?.toLowerCase() === 'outline') {
      btnStyle = `bg-white text-grey-700 border-grey-500 
                        hover:bg-grey-100 hover:border-grey-500
                        active:bg-grey-100 active:border-grey-500
                        disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
    } else if (props.emphasis?.toLowerCase() === 'tonal') {
      btnStyle = `bg-grey-200 text-grey-800 border-grey-200 
                        hover:bg-grey-300 hover:border-grey-300
                        active:bg-grey-300 active:border-grey-300
                        disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
    } else {
      btnStyle = `bg-grey-800 text-grey-50 border-grey-800 
                        hover:bg-grey-600 hover:border-grey-600
                        active:bg-grey-600 active:border-grey-600
                        disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
    }
  } else {
    if (props.emphasis?.toLowerCase() === 'outline') {
      btnStyle = `bg-white text-light-blue-900 border-light-blue-900 
                        hover:bg-light-blue-700 hover:text-light-blue-50 hover:border-light-blue-700
                        active:bg-light-blue-100
                        disabled:text-grey-400 disabled:border-grey-400 disabled:cursor-not-allowed`
    } else if (props.emphasis?.toLowerCase() === 'tonal') {
      btnStyle = `bg-light-blue-100 text-light-blue-900 border-light-blue-100 
                        hover:bg-light-blue-700 hover:text-light-blue-50 hover:border-light-blue-700
                        active:bg-light-blue-200 active:border-light-blue-200
                        disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
    } else {
      btnStyle = `bg-light-blue-900 text-light-blue-50 border-light-blue-900 
                        hover:bg-light-blue-700 hover:border-light-blue-700
                        active:bg-light-blue-700 active:border-light-blue-700
                        disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
    }
  }

  if (props.size?.toLowerCase() === 'smaller') {
    btnSize = 'py-2 px-3 text-sm'
  } else if (props.size?.toLowerCase() === 'larger') {
    btnSize = 'py-[10px] px-3 text-lg'
  }

  return `${btnGlobal} ${btnStyle} ${btnSize} ${props.classOverride}`
})
</script>
