<template>
  <section>
    <button
      @click="
        (bibleGateway.mobileVerseMenu = !bibleGateway.mobileVerseMenu),
          (bibleGateway.mobileVersionMenu = false)
      "
      class="text-grey-800 inline-flex cursor-pointer items-center justify-center border-none px-3 py-2 font-medium transition duration-300 ease-in-out"
      :emphasis="'outline'"
    >
      <template v-if="!isLoading">
        <template v-for="(book, index) in bibleGateway.reader" :key="index">
          {{ book.passage.reference }}
        </template>
      </template>
      <template v-else>
        {{ bibleGateway.activeBook }}
      </template>
      <span class="transition">
        <Bars2Icon
          v-if="!bibleGateway.mobileVerseMenu"
          class="ml-3 h-5 w-5"
        ></Bars2Icon>
        <XMarkIcon
          v-if="bibleGateway.mobileVerseMenu"
          class="ml-3 h-5 w-5"
        ></XMarkIcon>
      </span>
    </button>
    <div
      v-if="bibleGateway.mobileVerseMenu"
      class="absolute left-0 top-full z-40 w-full"
    >
      <div
        class="border-grey-300 flex flex-col space-y-2 border-b bg-white p-4 lg:p-8"
      >
        <div v-for="(menu, index) in bibleGateway.booksMenu" :key="index">
          <div v-for="(section, i) in menu.sections" :key="i">
            <div
              v-if="section.name === bibleGateway.selectedSection"
              class="flex flex-col gap-x-14"
            >
              <div class="flex w-full flex-row">
                <div
                  v-for="(section, j) in menu.sections"
                  class="w-1/2"
                  :key="j"
                >
                  <button
                    :style="neutral"
                    :emphasis="tonal"
                    :class="
                      section.name === bibleGateway.selectedSection
                        ? 'border-grey-200 bg-grey-200'
                        : 'border-white bg-white'
                    "
                    class="text-grey-800 hover:border-grey-200 hover:bg-grey-200 inline-flex w-full cursor-pointer items-center justify-center rounded-xl border px-3 py-2 font-medium"
                    @click="bibleGateway.selectedSection = section.name"
                  >
                    {{ section.display }}
                  </button>
                </div>
              </div>
              <div
                v-if="section.name === bibleGateway.selectedSection"
                class="mt-10 flex w-full flex-col"
              >
                <div
                  v-for="(book, j) in section.books"
                  :key="j"
                  class="border-grey-300 border-t"
                >
                  <button
                    class="flex w-full items-center justify-between whitespace-nowrap py-2 font-medium transition ease-in"
                    @click="book.mobileChapterMenu = !book.mobileChapterMenu"
                  >
                    <span>
                      {{ book.display }}
                    </span>
                    <ChevronDownIcon
                      v-if="!book.mobileChapterMenu"
                      class="h-5 w-5"
                    ></ChevronDownIcon>
                    <ChevronUpIcon
                      v-if="book.mobileChapterMenu"
                      class="h-5 w-5"
                    ></ChevronUpIcon>
                  </button>
                  <div
                    v-if="book.mobileChapterMenu"
                    class="bg-light-blue-50 my-3 flex flex-wrap rounded-xl px-4 py-3"
                  >
                    <button
                      v-for="(chapter, k) in book.chapters"
                      :key="k"
                      class="flex h-8 w-8 cursor-pointer items-center justify-center text-sm transition-all ease-in"
                      :class="
                        chapter.isActive
                          ? 'bg-light-blue-200 text-light-blue-900 rounded-xl'
                          : 'bg-none'
                      "
                      @click="
                        (bibleGateway.passage = book.name + chapter.name),
                          (bibleGateway.activeBook =
                            bibleGateway.passage.reference),
                          (bibleGateway.verseMenu = false),
                          (bibleGateway.activeBook = book.display),
                          updateContent(false),
                          (bibleGateway.mobileVerseMenu = false)
                      "
                    >
                      {{ chapter.name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline'
import {
  Bars2Icon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/vue/24/solid'
import { useBibleGatewayStore } from '@TodayInTheWord/stores/bible-gateway'

const bibleGateway = useBibleGatewayStore()

const emit = defineEmits(['updateContent'])

const updateContent = (fetchMenu) => {
  emit('updateContent', fetchMenu)
}

const props = defineProps({
  menu: {
    type: Object,
    default: () => ({}),
  },
})
</script>
