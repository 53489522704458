<template>
  <svg
    width="804"
    height="349"
    viewBox="0 0 804 349"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="272" height="23.5154" rx="11.7577" fill="#D1D5DB" />
    <path
      d="M8 41.0154H796C800.142 41.0154 803.5 44.3733 803.5 48.5154V126.515C803.5 130.658 800.142 134.015 796 134.015H7.99998C3.85784 134.015 0.5 130.658 0.5 126.515V48.5154C0.5 44.3733 3.85786 41.0154 8 41.0154Z"
      fill="#F3F4F6"
    />
    <rect x="16" y="60.5154" width="82" height="12" rx="6" fill="#D1D5DB" />
    <rect x="16" y="84.5154" width="182" height="9" rx="4.5" fill="#E5E7EB" />
    <rect x="16" y="105.515" width="262" height="9" rx="4.5" fill="#E5E7EB" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M775.278 92.2225C774.887 91.832 774.887 91.1989 775.278 90.8083L778.575 87.5154L775.278 84.2225C774.887 83.832 774.887 83.1989 775.278 82.8083C775.669 82.4178 776.303 82.4178 776.694 82.8083L780.699 86.8083C781.09 87.1989 781.09 87.832 780.699 88.2225L776.694 92.2225C776.303 92.6131 775.669 92.6131 775.278 92.2225Z"
      fill="#1F2937"
    />
    <path
      d="M8 41.0154H796C800.142 41.0154 803.5 44.3733 803.5 48.5154V126.515C803.5 130.658 800.142 134.015 796 134.015H7.99998C3.85784 134.015 0.5 130.658 0.5 126.515V48.5154C0.5 44.3733 3.85786 41.0154 8 41.0154Z"
      stroke="#D1D5DB"
    />
    <path
      d="M8 147.015H796C800.142 147.015 803.5 150.373 803.5 154.515V233.515C803.5 237.658 800.142 241.015 796 241.015H7.99998C3.85784 241.015 0.5 237.658 0.5 233.515V154.515C0.5 150.373 3.85786 147.015 8 147.015Z"
      fill="#F3F4F6"
    />
    <rect x="16" y="167.015" width="82" height="12" rx="6" fill="#D1D5DB" />
    <rect x="16" y="191.015" width="182" height="9" rx="4.5" fill="#E5E7EB" />
    <rect x="16" y="212.015" width="262" height="9" rx="4.5" fill="#E5E7EB" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M775.278 198.723C774.887 198.332 774.887 197.699 775.278 197.308L778.575 194.015L775.278 190.723C774.887 190.332 774.887 189.699 775.278 189.308C775.669 188.918 776.303 188.918 776.694 189.308L780.699 193.308C781.09 193.699 781.09 194.332 780.699 194.723L776.694 198.723C776.303 199.113 775.669 199.113 775.278 198.723Z"
      fill="#1F2937"
    />
    <path
      d="M8 147.015H796C800.142 147.015 803.5 150.373 803.5 154.515V233.515C803.5 237.658 800.142 241.015 796 241.015H7.99998C3.85784 241.015 0.5 237.658 0.5 233.515V154.515C0.5 150.373 3.85786 147.015 8 147.015Z"
      stroke="#D1D5DB"
    />
    <path
      d="M8 254.015H796C800.142 254.015 803.5 257.373 803.5 261.515V340.515C803.5 344.658 800.142 348.015 796 348.015H7.99998C3.85784 348.015 0.5 344.658 0.5 340.515V261.515C0.5 257.373 3.85786 254.015 8 254.015Z"
      fill="#F3F4F6"
    />
    <rect x="16" y="274.015" width="82" height="12" rx="6" fill="#D1D5DB" />
    <rect x="16" y="298.015" width="182" height="9" rx="4.5" fill="#E5E7EB" />
    <rect x="16" y="319.015" width="262" height="9" rx="4.5" fill="#E5E7EB" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M775.278 305.723C774.887 305.332 774.887 304.699 775.278 304.308L778.575 301.015L775.278 297.723C774.887 297.332 774.887 296.699 775.278 296.308C775.669 295.918 776.303 295.918 776.694 296.308L780.699 300.308C781.09 300.699 781.09 301.332 780.699 301.723L776.694 305.723C776.303 306.113 775.669 306.113 775.278 305.723Z"
      fill="#1F2937"
    />
    <path
      d="M8 254.015H796C800.142 254.015 803.5 257.373 803.5 261.515V340.515C803.5 344.658 800.142 348.015 796 348.015H7.99998C3.85784 348.015 0.5 344.658 0.5 340.515V261.515C0.5 257.373 3.85786 254.015 8 254.015Z"
      stroke="#D1D5DB"
    />
  </svg>
</template>
