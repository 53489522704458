import * as searchRequestBuilder from '@shared/api/search-request-builder'
import * as webApi from '@shared/api/web-api'
import { defineStore } from 'pinia'
import * as searchTypes from '../../components/pages/search/types/search-types'

const STORE_ID = 'SEARCH_STORE'

const defaultState: searchTypes.searchStoreModel = {
  currentSegmentId: 'all',
  searchResults: null,
  segmentSearchResults: {},
  searchApiUrls: null,
  bibleSearchResults: null,
  isSearchingContent: false,
  isLoading: false,
  isError: false,
  abortController: null,
  parameters: {
    q: '',
    p: 1,
  },
}

export const useSearchPageStore = defineStore(STORE_ID, {
  state: (): searchTypes.searchStoreModel => defaultState,
  getters: {
    getCurrentSearchApiUrl(): string {
      if (this.searchApiUrls === null) {
        return '/search/search'
      }

      const searchApiUrl = this.searchApiUrls[this.currentSegmentId]
      if (!searchApiUrl || searchApiUrl === null) {
        return '/search/search'
      }

      return searchApiUrl
    },
  },
  actions: {
    createAbortController() {
      this.abortController = new AbortController()
    },
    cancelAbortController() {
      if (
        this.abortController &&
        this.abortController !== null &&
        !this.abortController.signal.aborted
      ) {
        this.abortController.abort()
      }
    },
    resetAbortController() {
      this.cancelAbortController()
      this.createAbortController()
    },
    setApiUrls(segmentApiUrls: Record<searchTypes.searchSegmentId, string>) {
      this.searchApiUrls = segmentApiUrls || null
    },
    setCurrentSegmentId(currentSegment: searchTypes.searchSegmentId) {
      this.currentSegmentId = currentSegment
    },
    getUrlQueryString(query: string, page?: number) {
      let str = `q=${encodeURIComponent(query)}`
      if (page && page > 1) {
        str += `&p=${page}`
      }

      return str
    },
    getSearchApiUrl(segmentId: searchTypes.searchSegmentId) {
      if (!this.searchApiUrls || this.searchApiUrls === null) {
        return '/search/search'
      }

      return this.searchApiUrls[segmentId]
    },
    async searchAllSegments(searchParams: searchRequestBuilder.ISearchParams) {
      const currentResults = this.searchResults

      this.isError = false
      this.isLoading = true
      this.searchResults = null
      const apiUrl = this.getSearchApiUrl('all')

      const request = searchRequestBuilder.createPostRequest(apiUrl, searchParams)
      request.signal = this.abortController?.signal

      try {
        const searchResponse =
          await webApi.callAsync<searchTypes.TitwSearchResultsViewModel>(request)
        this.searchResults = searchResponse.data ?? null
        if (this.searchResults == null) {
          this.isError = true
        }
      } catch (error) {
        console.error('Error fetching search results for all segments:', error)
        this.isError = true
        this.searchResults = null // Set results to null in case of an error
      } finally {
        this.isLoading = false
      }
    },
    async bibleGatewaySearch(searchParams: searchRequestBuilder.ISearchParams) {
      if (!searchParams.p || searchParams.p <= 0) {
        searchParams.p = 1
      }

      this.isError = false
      this.isLoading = true
      const apiUrl = this.getSearchApiUrl('bible_search')

      const request = searchRequestBuilder.createPostRequest(apiUrl, searchParams)
      request.signal = this.abortController?.signal

      try {
        const searchResponse = await webApi.callAsync<searchTypes.TitwBibleSearchResults>(request)
        this.bibleSearchResults = searchResponse.data ?? null
      } catch (error) {
        console.error('Error fetching Bible search results:', error)
        this.isError = true
        this.bibleSearchResults = null // Set results to null in case of an error
      } finally {
        this.isLoading = false
      }
    },
    async cmsSearch(
      searchParams: searchRequestBuilder.ISearchParams,
      segment: searchTypes.searchSegmentId,
    ) {
      if (!searchParams.p || searchParams.p <= 0) {
        searchParams.p = 1
      }

      this.isError = false
      this.isLoading = true
      const apiUrl = this.getSearchApiUrl(segment)

      const request = searchRequestBuilder.createPostRequest(apiUrl, searchParams)
      request.signal = this.abortController?.signal

      try {
        const searchResponse = await webApi.callAsync<searchTypes.TitwSearchTabResults>(request)
        this.segmentSearchResults[segment] = searchResponse.data ?? null
      } catch (error) {
        console.error(`Error fetching ${segment} search results:`, error)
        this.isError = true
        this.segmentSearchResults[segment] = null // Set results to null in case of an error
      } finally {
        this.isLoading = false
      }
    },
    async searchSegment(
      searchParams: searchRequestBuilder.ISearchParams,
      segment: searchTypes.searchSegmentId,
    ) {
      if (segment === 'all') {
        return
      }

      if (segment === 'bible_search') {
        await this.bibleGatewaySearch(searchParams)
      } else {
        await this.cmsSearch(searchParams, segment)
      }
    },
  },
})
