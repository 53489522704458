<template>
  <div v-show="headerStore.isMobile">
    <div class="border-grey-300 relative flex items-center justify-between border-b px-7 py-2">
      <button class="mobile-nav-btn" @click="openMobileMenu()">
        <template v-if="!menuState.isMobileMenuOpen">
          <Bars3Icon class="h-6 w-6 p-0" />
          <span class="sr-only"> Open mobile menu </span>
        </template>
        <template v-else>
          <XMarkIcon class="h-6 w-6 p-0" />
          <span class="sr-only"> Close mobile menu </span>
        </template>
      </button>
      <div v-if="header?.mobileLogo" class="w-full max-w-[187px]">
        <a :href="header.homePageLink ?? '/'">
          <shared-image
            :model="header.mobileLogo"
            format="?width=107&height=40&format=webp&compand=true"
            :preload="true"
          />
        </a>
      </div>
      <div class="static flex">
        <s-link
          v-if="header?.bibleReadingExperiencePageLink"
          :href="header.bibleReadingExperiencePageLink.href"
          :target="header.bibleReadingExperiencePageLink.target"
          :external-link="header.bibleReadingExperiencePageLink.isExternal ?? true"
          class="bibleReadingButton mr-3"
          @click="closeMobileMenu()"
        >
          <bible-icon :fill-class="'fill-white'" class="h-6 w-6" />
          <span class="sr-only"> Bible Experience </span>
        </s-link>
        <SearchBar v-if="!epiStore.isSearchPage" />
      </div>
    </div>
    <transition
      enter-from-class="-translate-y-[100%] opacity-0"
      enter-active-class="transition duration-300"
      leave-to-class="-translate-y-[100%] opacity-0"
      leave-active-class="transition duration-300"
    >
      <PrimaryMenu v-if="menuState.isMobileMenuOpen"></PrimaryMenu>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/solid'
import BibleIcon from '@TodayInTheWord/components/icons/bible-icon-outline.vue'
import PrimaryMenu from '@TodayInTheWord/components/shared/header/components/main-nav/mobile/primary-menu-simplified.vue'
import SearchBar from '@TodayInTheWord/components/shared/header/components/main-nav/mobile/search-bar.vue'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import { storeToRefs } from 'pinia'

const headerStore = useHeaderStore()
const { header, menuState } = storeToRefs(headerStore)
const epiStore = useEpiPageContextStore()

if (header.value) {
  menuState.value.isMobileMenuOpen = false
}

function openMobileMenu() {
  if (!header.value) {
    return
  }

  if (menuState.value.isMobileMenuOpen) {
    menuState.value.isMobileMenuOpen = false
    return
  }

  menuState.value.isMobileMenuOpen = true
}

function closeMobileMenu() {
  if (!header.value) {
    return
  }

  menuState.value.isMobileMenuOpen = false
}
</script>
