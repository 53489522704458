import './assets/index.css'
import { createApp } from './renderer'
import router from './router'

const model = window._model
const { app } = createApp(model)

app.config.errorHandler = (err, instance, info) => {
  console.error(err, info)
  const appInsights = window?.appInsights
  if (!appInsights) {
    return
  }

  const exceptionTelemetry = {
    exception: err,
  }

  let includeProperties: boolean = false
  const properties: Record<string, string> = {}
  const _app = instance?.$?.appContext?.app
  if (_app) {
    if (_app.version) {
      properties.appVersion = _app.version
      includeProperties = true
    }

    if (_app._component?.name) {
      properties.appName = _app._component.name
      includeProperties = true
    }

    if (_app._container?.id) {
      properties.appContainer = `${_app._container.localName}#${_app._container.id}`
      includeProperties = true
    }
  }

  if (instance?.$?.type.__name) {
    properties.componentName = instance?.$?.type.__name
    includeProperties = true
  }

  if (includeProperties) {
    exceptionTelemetry.properties = properties
  }

  appInsights.trackException(exceptionTelemetry)
}

const mountApp = () => {
  app.mount('#todayintheword-app')
}

router.isReady().then(mountApp)
