import { EmphasisType, SizeType, StyleType } from '@shared/types/shared'

const getSecondaryLinkStyle = (emphasis?: EmphasisType): string => {
  if (emphasis && emphasis === 'outline') {
    return `bg-white text-cyan-800 border-cyan-600 
                              hover:bg-cyan-700 hover:text-cyan-50 hover:border-cyan-700
                              active:bg-cyan-100 active:text-cyan-800 active:border-cyan-600
                              disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
  } else if (emphasis && emphasis === 'tonal') {
    return `bg-cyan-100 text-cyan-900 border-cyan-100 
                              hover:bg-cyan-700 hover:text-cyan-50 hover:border-cyan-700
                              active:bg-cyan-200 active:text-cyan-900 active:border-cyan-200
                              disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
  } else {
    return `bg-cyan-600 text-cyan-50 border-cyan-600 
                              hover:bg-cyan-700 hover:text-cyan-50 hover:border-cyan-700
                              active:bg-cyan-700 active:text-cyan-50 active:border-cyan-700
                              disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
  }
}

const getNeutralLinkStyle = (emphasis?: EmphasisType): string => {
  if (emphasis && emphasis === 'outline') {
    return `bg-white text-grey-700 border-grey-500 
                              hover:bg-grey-100 hover:text-grey-700 hover:border-grey-500
                              active:bg-grey-100 active:text-grey-700 active:border-grey-500
                              disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
  } else if (emphasis && emphasis === 'tonal') {
    return `bg-grey-200 text-grey-800 border-grey-200 
                              hover:bg-grey-300 hover:text-grey-800 hover:border-grey-300
                              active:bg-grey-300 active:text-grey-800 active:border-grey-300
                              disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
  } else {
    return `bg-grey-800 text-grey-50 border-grey-800 
                              hover:bg-grey-600 hover:text-grey-50 hover:border-grey-600
                              active:bg-grey-600 active:text-grey-50 active:border-grey-600
                              disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
  }
}

const getPrimaryLinkStyle = (emphasis?: EmphasisType): string => {
  if (emphasis && emphasis === 'outline') {
    return `bg-white text-light-blue-900 border-light-blue-900 
                              hover:bg-light-blue-700 hover:text-light-blue-50 hover:border-light-blue-700
                              active:bg-light-blue-100 acitve:text-light-blue-900
                              disabled:text-grey-400 disabled:border-grey-400 disabled:cursor-not-allowed`
  } else if (emphasis && emphasis === 'tonal') {
    return `bg-light-blue-100 text-light-blue-900 border-light-blue-100 
                              hover:bg-light-blue-700 hover:text-light-blue-50 hover:border-light-blue-700
                              active:bg-light-blue-200 active:text-light-blue-900 active:border-light-blue-200
                              disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
  } else {
    return `bg-light-blue-900 text-light-blue-50 border-light-blue-900 
                              hover:bg-light-blue-700 hover:text-light-blue-50 hover:border-light-blue-700
                              active:bg-light-blue-700 active:text-light-blue-50 active:border-light-blue-700
                              disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
  }
}

const getSize = (size?: SizeType): string => {
  if (size && size === 'smaller') {
    return 'py-2 px-3 text-sm'
  } else if (size && size === 'larger') {
    return 'py-[10px] px-3 text-lg'
  } else {
    return 'py-2 px-[14px] text-base'
  }
}

const getGlobalLinkStyle = (): string => {
  return 'inline-flex items-center justify-center font-medium border-solid border rounded-xl cursor-pointer transition ease-in-out duration-300'
}

const getLinkStyle = (
  style?: StyleType,
  emphasis?: EmphasisType,
  size?: SizeType,
): string => {
  if (!style) {
    return ''
  }

  if (style === 'secondary') {
    return `${getGlobalLinkStyle()} ${getSecondaryLinkStyle(emphasis)} ${getSize(size)}`
  } else if (style === 'neutral') {
    return `${getGlobalLinkStyle()} ${getNeutralLinkStyle(emphasis)} ${getSize(size)}`
  } else {
    return `${getGlobalLinkStyle()} ${getPrimaryLinkStyle(emphasis)} ${getSize(size)}`
  }
}

export { getLinkStyle }
