﻿<template>
  <block-component-selector
    v-for="block in model"
    :key="uid()"
    :class="getDisplayWidth(block)"
    :model="block?.model"
    :has-container="block?.hasContainer"
  />
</template>

<script setup lang="ts">
import BlockComponentSelector from '@TodayInTheWord/epi/block-component-selector.vue'
import { ContentAreaProps } from '@TodayInTheWord/types/epi'
import { BlockProp } from '@TodayInTheWord/types/shared'
import { uid } from 'uid'

interface ContentArea {
  model?: ContentAreaProps
  hasContainer?: boolean | null
}

const props = defineProps<ContentArea>()

const getDisplayWidth = (block: BlockProp | undefined) => {
  if (!block || !block.model.contentType?.[1]) {
    return 'w-full'
  }

  const blockName = block.model.contentType

  if (blockName.includes('RichTextBlock')) {
    if (!block.model.displayOption) {
      return 'w-full'
    }

    if (block.model.displayOption === 'one-third') {
      return 'w-full lg:w-1/4'
    }

    if (block.model.displayOption === 'half') {
      return 'w-full lg:w-1/2'
    }

    if (block.model.displayOption === 'two-thirds') {
      return 'w-full lg:w-2/3'
    }

    if (block.model.displayOption === 'one-fourth') {
      return 'w-full lg:w-1/4'
    }
  } else {
    return 'w-full'
  }
}
</script>
