<template>
  <div
    v-if="model && model.items && model.items.length > 0"
    v-bind:id="model.clientID"
    class="TITW layout-block container mx-auto"
  >
    <div v-if="model.heading || model.subheading" class="mb-6 w-full md:mb-4">
      <section-heading :heading="model.heading" :subheading="model.subheading" />
    </div>

    <div class="grid grid-cols-12 gap-x-4 gap-y-8 md:gap-x-8">
      <block-component-selector
        v-for="(item, index) in model.items"
        :key="index"
        :class="setDisplayWidths(item)"
        :model="item.model"
        :has-container="false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import SectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import BlockComponentSelector from '@TodayInTheWord/epi/block-component-selector.vue'
import { onMounted } from 'vue'
import { LayoutBlockProps } from './types/layout-block-types'

const props = defineProps<LayoutBlockProps>()

function setDisplayWidths(item: any) {
  if (item) {
    // set defaults
    let mobile = 'col-span-12'
    let tablet = 'md:col-span-12'
    let desktop = 'xl:col-span-12'

    if (!item || !item.model.displayWidths) {
      return 'col-span-12'
    }

    if (item.model.displayWidths.mobileWidth) {
      if (item.model.displayWidths.mobileWidth === 'col-span-8') {
        mobile = 'col-span-8'
      } else if (item.model.displayWidths.mobileWidth === 'col-span-6') {
        mobile = 'col-span-6'
      } else if (item.model.displayWidths.mobileWidth === 'col-span-4') {
        mobile = 'col-span-4'
      } else if (item.model.displayWidths.mobileWidth === 'col-span-3') {
        mobile = 'col-span-3'
      }
    }

    if (item.model.displayWidths.tabletWidth) {
      if (item.model.displayWidths.tabletWidth === 'col-span-8') {
        tablet = 'md:col-span-8'
      } else if (item.model.displayWidths.tabletWidth === 'col-span-6') {
        tablet = 'md:col-span-6'
      } else if (item.model.displayWidths.tabletWidth === 'col-span-4') {
        tablet = 'md:col-span-4'
      } else if (item.model.displayWidths.tabletWidth === 'col-span-3') {
        tablet = 'md:col-span-3'
      }
    }

    if (item.model.displayWidths.desktopWidth) {
      if (item.model.displayWidths.desktopWidth === 'col-span-8') {
        desktop = 'xl:col-span-8'
      } else if (item.model.displayWidths.desktopWidth === 'col-span-6') {
        desktop = 'xl:col-span-6'
      } else if (item.model.displayWidths.desktopWidth === 'col-span-4') {
        desktop = 'xl:col-span-4'
      } else if (item.model.displayWidths.desktopWidth === 'col-span-3') {
        desktop = 'xl:col-span-3'
      }
    }
    return `${mobile} ${tablet} ${desktop}`
  }
}

onMounted(() => {
  setDisplayWidths()
})
</script>
