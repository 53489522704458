<template>
  <main
    class="errorPage container mx-auto mt-5"
    v-if="!epiPageContext.isLoading"
  >
    <div class="mt-5">
      <template v-if="epiPageContext.status === 404">
        <div class="heading mb-5">
          <h1 class="text-2xl font-medium">Page not found.</h1>
        </div>
      </template>
      <template v-else-if="epiPageContext.status === 500">
        <div class="heading mb-5">
          <h1 class="text-2xl font-medium">Uh-oh.</h1>
          <h2 class="text-md font-bold">Something went wrong.</h2>
        </div>
        <div class="text-md">
          <p>Please try again.</p>
        </div>
      </template>
      <template v-else>
        <h1 class="text-2xl">Unexpected error.</h1>
      </template>

      <div
        class="errorMessage text-sm font-semibold text-red-600"
        v-if="epiPageContext.errorMessage"
      >
        {{ epiPageContext.errorMessage }}
      </div>
    </div>

    <content-area v-if="model?.pageContent" :model="model.pageContent" />
  </main>
</template>

<script setup>
import ContentArea from '@TodayInTheWord/epi/content-area.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'

const epiPageContext = useEpiPageContextStore()
const model = epiPageContext.model
</script>
