<template>
  <div
    class="mx-auto w-full max-w-full lg:max-w-[856px]"
    :class="{ container: mediaSizeStore.isMobile }"
  >
    <div v-if="breadcrumbs">
      <bread-crumbs
        :links="breadcrumbs.links"
        :currentPageName="breadcrumbs.currentPageName"
        class="mt-4 lg:mt-6"
      />
    </div>
    <div class="mb-8 mt-5 border-b border-slate-200 pb-8 lg:mb-10 lg:mt-6 lg:pb-10">
      <div
        v-if="model.heroImage"
        class="bg-light-blue-100 mb-4 aspect-video flex-shrink-0 lg:mb-6 lg:overflow-hidden lg:rounded-xl"
        :class="{ '-mx-[18px]': mediaSizeStore.isMobile }"
      >
        <s-image
          v-if="model.heroImage"
          :model="model.heroImage"
          class="h-full w-full object-cover"
          :preload="true"
        />
      </div>
      <div
        v-if="model.contentTag || model.displayDate"
        class="mb-3 flex justify-between lg:mb-6 lg:justify-normal lg:space-x-4"
      >
        <div
          class="text-light-blue-900 bg-light-blue-100 flex items-center rounded-xl px-2 py-0.5 text-xs font-medium"
        >
          Monthly Study
        </div>
        <span v-if="model.displayDate" class="text-grey-600 text-base font-normal">
          {{ model.displayDate }}
        </span>
      </div>
      <div class="relative space-y-3 lg:space-y-0">
        <div class="flex flex-col space-y-1 lg:space-y-2">
          <h1 v-if="pageTitle" class="text-2xl font-semibold text-cyan-900 lg:text-4xl">
            {{ pageTitle }}
          </h1>
          <p v-if="model.heroSubtitle" class="text-grey-700 text-base font-normal lg:text-xl">
            {{ model.heroSubtitle }}
          </p>
        </div>
      </div>
    </div>
    <section class="mb-10 space-y-6 lg:mb-12 lg:space-y-10">
      <s-rich-text-block :model="{ richTextField: model.mainBody }" />
      <div class="flex flex-col space-y-4">
        <div
          v-if="model.issueVolume || model.issueNumber"
          class="text-grey-800 space-x-1 text-lg font-medium lg:text-xl"
        >
          <span>Volume {{ model.issueVolume }}</span>
          <span>Edition {{ model.issueNumber }}</span>
        </div>
        <div v-if="model.issueCreditsText" class="text-grey-700 text-base font-normal">
          {{ model.issueCreditsText }}
        </div>
        <s-button
          v-if="model.pdfDownload"
          :emphasis="'tonal'"
          :style="'primary'"
          @click="downloadPdf(model.pdfDownload.href)"
          :class-override="'w-fit lg:mt-6 downloadButton'"
        >
          {{ model.pdfDownload.text }}
          <span><document-arrow-down-icon class="ml-2 h-5 w-5" /></span>
        </s-button>
      </div>
      <div class="relative flex space-x-1">
        <share-this />
        <button class="printButton bg-transparent p-4" @click.prevent="handlePrint">
          <PrinterIcon class="stroke-light-blue-900 hover:stroke-light-blue-600 h-6 w-6" />
        </button>
      </div>
    </section>
    <section class="space-y-8 lg:space-y-12">
      <about-the-author :model="model?.authorsSection" />
      <devotional-calendar :model="model?.calendarSection" />
      <devotional-podcasts :model="model?.podcastSection" />
      <related-content :model="model?.relatedMaterials" :date-format="'MMM yyyy'" />
    </section>
  </div>
</template>

<script setup lang="ts">
import { DocumentArrowDownIcon, PrinterIcon } from '@heroicons/vue/24/outline'
import SRichTextBlock from '@TodayInTheWord/components/blocks/rich-text-block/rich-text-block.vue'
import AboutTheAuthor from '@TodayInTheWord/components/pages/shared/about-the-author.vue'
import RelatedContent from '@TodayInTheWord/components/pages/shared/related-content.vue'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import sButton from '@TodayInTheWord/components/shared/s-button.vue'
import SImage from '@TodayInTheWord/components/shared/s-image.vue'
import ShareThis from '@TodayInTheWord/components/shared/share-this/s-share-this-button.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { BreadcrumbProps } from 'TodayInTheWord/types/shared'
import { computed } from 'vue'
import devotionalCalendar from './components/devotional-calendar.vue'
import devotionalPodcasts from './components/devotional-podcasts.vue'
import { DevotionalMonthlyStudyProps } from './types/devotional-monthly-study-types'

const epiPageContextStore = useEpiPageContextStore()
const model: DevotionalMonthlyStudyProps['model'] = epiPageContextStore.model
const breadcrumbs: BreadcrumbProps = epiPageContextStore.breadcrumbs
const mediaSizeStore = useMediaSizeStore()

const handlePrint = () => {
  window.print()
}

const pageTitle = computed(() => {
  return model?.heroTitle ?? breadcrumbs.currentPageName
})

function downloadPdf(pdfLink: string) {
  const link = document.createElement('a')
  link.href = pdfLink
  link.target = '_blank'
  link.download = pdfLink

  // Simulate a click on the element <a>
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
</script>

<style scoped></style>
