<template>
  <div>
    <div v-if="streamUrl">
      <AudioPlayer :title="props.title || ''" :src="streamUrl"></AudioPlayer>
    </div>
  </div>
</template>

<script setup lang="ts">
import AudioPlayer from '@TodayInTheWord/components/pages/shared/audio-player.vue'
import axios from 'axios'
import { defineProps, onMounted, ref } from 'vue'
import { OmnyConfig, OmnyResponse } from './types/omny-response-types'

interface OmnyPlayerProps {
  title?: string
  omnyId: string
  omnyOrg: string
}

const streamUrl = ref<string | null>(null)
const props = defineProps<OmnyPlayerProps>()

onMounted(() => {
  fetchOmnyClip()
})

async function fetchOmnyClip() {
  let url = `https://api.omny.fm/orgs/${props.omnyOrg}/clips/${props.omnyId}`

  const queryString = window.location.search
  if (queryString.includes('testomny=true')) {
    url =
      'https://api.omny.fm/orgs/289ceca7-bef3-48af-9f74-a4ba0095cab1/clips/b61cd5d4-886f-4f9f-9813-aafe00e55fdb'
  }

  try {
    const response = await axios.get<OmnyResponse>(url, {
      withCredentials: false,
    })
    console.log(response.data.AudioUrl)
    streamUrl.value = response.data.AudioUrl
    console.log(streamUrl.value)
  } catch (error) {
    console.error('Error fetching Omny clip:', error)
    throw error
  }
}
</script>

<style lang="scss"></style>
