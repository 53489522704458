<template>
  <div class="mb-8 md:mb-16">
    <div v-if="model" class="container mx-auto">
      <bread-crumbs
        class="mb-5 mt-5 md:mb-12 md:mt-6"
        :links="breadcrumbs.links"
        :currentPageName="breadcrumbs.currentPageName"
      />
      <section class="border-b-lighter-grey mb-8 border-b pb-9 md:mb-12 md:pb-12">
        <div class="w-full md:w-5/12">
          <div
            class="bg-light-blue-100 text-light-blue-900 mb-3 w-fit rounded-xl px-2 py-1 text-sm md:mb-4 md:font-medium"
          >
            Q & A
          </div>
          <h1
            v-if="model.title"
            class="mb-1 text-2xl font-semibold text-cyan-900 md:mb-2 md:text-4xl"
          >
            {{ model.title }}
          </h1>
          <div v-if="model.subtitle" class="text-grey-700 text-base font-normal md:text-xl">
            {{ model.subtitle }}
          </div>
        </div>
      </section>
      <section v-if="model.featuredQuestionsSection" class="mb-8 md:mb-[52px]">
        <div class="mb-4 flex items-center">
          <h2
            class="text-grey-700 overflow-hidden text-base font-semibold md:text-2xl lg:text-xl"
            v-if="model.featuredQuestionsSection.title"
          >
            {{ model.featuredQuestionsSection.title }}
          </h2>
          <span class="bg-lighter-grey mx-4 flex h-[1px] flex-grow"></span>
        </div>

        <div
          v-if="
            model.featuredQuestionsSection?.questions &&
            model.featuredQuestionsSection.questions?.length > 0
          "
          class="flex flex-wrap justify-center md:mb-8 md:justify-start"
        >
          <c2-slider
            :slide-total="model.featuredQuestionsSection.questions.length"
            :slides-to-show="3"
            :slides-to-show-tablet="2"
            :slides-to-show-mobile="1"
            :slide-speed="500"
            :gutter="mediaSizeStore.isMobile ? 8 : 12"
            :media-tablet-query="1023"
            :media-mobile-query="639"
            :show-overflow="true"
            :show-button-nav="true"
            :showIndicators="false"
            :next-btn-classes="'hidden'"
            :prev-btn-classes="'hidden'"
            :nav-classes="'flex justify-end mt-4 relative md:mt-14 md:mb-0'"
            :button-nav-wrapper-classes="'max-w-full absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 flex align-center'"
            :button-classes="'relative h-6 w-[37px] mx-0.5 after:rounded after:block after:h-1 after:w-full after:bg-grey-300 after:absolute after:top-1/2 after:transform after:-translate-y-1/2'"
            :active-button-classes="'after:!bg-light-blue-600'"
            class="relative w-full -translate-x-8 transform pl-8 md:translate-x-0 md:pl-0"
          >
            <template v-slot:slide>
              <div
                class="flex"
                v-for="(card, index) in model.featuredQuestionsSection.questions"
                :key="index"
              >
                <shared-link
                  :href="card.url"
                  class="border-lighter-grey bg-grey-50 flex w-full flex-col space-y-2 rounded-xl border p-4 shadow-sm md:space-y-3 md:p-6"
                >
                  <div
                    v-if="card.title"
                    class="hover:text-light-blue-600 h-full text-sm text-cyan-900 transition-all duration-300 md:text-base md:font-medium"
                  >
                    {{ card.title }}
                  </div>
                  <div
                    v-if="card.categories?.length > 0"
                    class="bullet-seperator flex items-center space-x-1 [&>:not(:first-child)]:before:mr-1"
                  >
                    <span
                      v-for="(category, index) in card.categories"
                      :key="index"
                      class="text-grey-500 text-xs font-normal"
                    >
                      {{ category }}
                    </span>
                  </div>
                  <div class="space-y-2">
                    <div v-if="card.authorLabel" class="text-grey-800 text-xs font-medium">
                      {{ card.authorLabel }}
                    </div>
                    <div class="flex items-center space-x-2">
                      <shared-image
                        v-if="card.author.image"
                        :model="card.author.image"
                        class="aspect-square h-8 w-8 rounded object-cover"
                      />
                      <div v-if="card.author.title" class="text-grey-800 text-sm font-medium">
                        {{ card.author.title }}
                      </div>
                    </div>
                  </div>
                </shared-link>
              </div>
            </template>
          </c2-slider>
        </div>
      </section>
      <div ref="searchSection"></div>
      <search-filters-component
        :search-component="model?.searchComponent"
        :request-type="'post'"
        @search-filter-change="onSearchFiltersChanged"
      />
      <section
        ref="relatedQuestions"
        class="relatedQuestions mb-8 w-full space-y-8 md:mb-16 md:w-9/12 md:space-y-[108px]"
        v-if="
          state.searchResults && state.searchResults.items && state.searchResults.items.length > 0
        "
      >
        <div class="space-y-6">
          <qa-question-result
            v-for="(question, index) in state.searchResults.items"
            :key="index"
            :model="question"
          />
        </div>

        <shared-pagination
          v-show="state.searchResults.totalPages > 1"
          :current-page="state.searchResults.page"
          :total-pages="state.searchResults.totalPages"
          @page-changed="gotoPage"
        />
      </section>
    </div>
    <block-component-selector
      v-for="(block, index) in model.additionalContent"
      :key="index"
      :has-container="false"
      :model="block.model"
    />
  </div>
</template>

<script setup lang="ts">
import * as routeHelper from '@shared/api/route-helper'
import * as searchRequestBuilder from '@shared/api/search-request-builder'
import * as webApi from '@shared/api/web-api'
import { EventName } from '@TodayInTheWord/components/pages/shared/search-filter-component/types/search-filter-types'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import SharedLink from '@TodayInTheWord/components/shared/s-link.vue'
import SharedPagination from '@TodayInTheWord/components/shared/s-pagination.vue'
import BlockComponentSelector from '@TodayInTheWord/epi/block-component-selector.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import * as indexTypes from '@TodayInTheWord/types/index-search-types'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import C2Slider from 'uikit/c2-slider/c2-slider.vue'
import { onMounted, ref } from 'vue'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import searchFiltersComponent from '../shared/search-filter-component/search-filters-component.vue'
import qaQuestionResult from './components/qa-question-result.vue'
import { QAIndexPageProps } from './types/qa-index-types'

const epiPageContextStore = useEpiPageContextStore()
const mediaSizeStore = useMediaSizeStore()
const model: QAIndexPageProps['model'] = epiPageContextStore.model
const breadcrumbs: BreadcrumbProps = epiPageContextStore.breadcrumbs
const router = useRouter()
const route = useRoute()
const requestedCategories = routeHelper.getFirstQueryParamAsStringArray(route.query, 'c')
const requestedAuthors = routeHelper.getFirstQueryParamAsStringArray(route.query, 'a')

interface IQAIndexPageState {
  searchResults: indexTypes.SearchResults | null
  searchParams: searchRequestBuilder.ISearchParams
  isLoading: boolean
  isFirstLoad: boolean
}

const state = ref<IQAIndexPageState>({
  searchResults: null,
  isLoading: false,
  searchParams: {},
  isFirstLoad: true,
})

const searchSection = ref<HTMLElement | null>(null)

const initializePageState = () => {
  state.value.searchParams.q = routeHelper.getFirstQueryParameter(route.query, 'q')
  state.value.searchParams.c = routeHelper.getFirstQueryParamAsStringArray(route.query, 'c')
  state.value.searchParams.p = routeHelper.getFirstQueryParamAsNumber(route.query, 'p', 1)
  state.value.searchParams.a = routeHelper.getFirstQueryParamAsStringArray(route.query, 'a')
}

function setUrlToMatchSearchParams(searchParams: searchRequestBuilder.ISearchParams) {
  const newLocation: RouteLocationRaw = {
    path: route.path,
    query: {},
  }

  newLocation.query.q = searchParams.q

  if (searchParams.c && searchParams.c.length > 0) {
    newLocation.query.c = searchParams.c.join()
  }

  if (searchParams.a && searchParams.a.length > 0) {
    newLocation.query.a = searchParams.a.join()
  }

  router.push(newLocation)
}

async function onSearchFiltersChanged(
  searchParams: searchRequestBuilder.ISearchParams,
  eventName: EventName,
) {
  state.value.searchParams.q = searchParams.q
  state.value.searchParams.c = searchParams.c
  state.value.searchParams.p = searchParams.p
  state.value.searchParams.d = searchParams.d
  state.value.searchParams.t = searchParams.t
  state.value.searchParams.a = searchParams.a

  setUrlToMatchSearchParams(searchParams)
  await fireSearchRequest()
}

async function fireSearchRequest() {
  state.value.isLoading = true
  const searchParams = state.value.searchParams
  const searchApiUrl: string = model?.searchComponent?.searchApiUrl ?? route.path

  if (searchParams.c && searchParams.c?.length === 1) {
    searchParams.c = searchParams.c.toString().split(',')
  }

  const request = searchRequestBuilder.createPostRequest(searchApiUrl, searchParams)
  setUrlToMatchSearchParams(searchParams)

  const response = await webApi.callAsync<indexTypes.SearchResults>(request)

  state.value.searchResults = response.data

  if (response.data) {
    if (searchSection.value && state.value.isFirstLoad == false) {
      searchSection.value.scrollIntoView({
        behavior: 'instant',
        block: 'start',
      })
    }
  } else {
    state.value.isFirstLoad = false
  }
  state.value.isLoading = false
  state.value.isFirstLoad = false
}

const gotoPage = async (page: number) => {
  if (page <= 0) {
    return
  }

  const newLocation: RouteLocationRaw = {
    path: route.path,
    query: {
      q: route.query.q,
    },
  }

  if (page > 1 && newLocation.query) {
    newLocation.query.p = page
  }

  if (requestedCategories && newLocation.query) {
    newLocation.query.c = requestedCategories
  }

  if (requestedAuthors && newLocation.query) {
    newLocation.query.a = requestedAuthors
  }

  await onSearchFiltersChanged(newLocation.query, 'applyFilters')
}

onMounted(async () => {
  initializePageState()
  await fireSearchRequest()
})
</script>
