<template>
  <div class="w-full pb-8 lg:pb-12">
    <segment-header :model="model" />
    <div>
      <exclamation-triangle-icon class="h-5 w-5 text-red-600" />
      <span class="text-grey-900 font-semibold">Configuration Error.</span>
    </div>
    <template v-for="item in items">
      <page-result-item :model="item" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import { computed } from 'vue'
import PageResultItem from '../pages/page-result-item.vue'
import segmentHeader from '../segment-header.vue'

const searchStore = useSearchPageStore()

const items = computed((): searchTypes.TitwSearchResultItemModel[] | null => {
  if (searchStore?.searchResults?.results && props.model.segmentId) {
    return searchStore?.searchResults?.results[props.model.segmentId] ?? null
  }

  return null
})

const props = defineProps({
  model: {
    type: Object,
    default: () => {},
  },
})
</script>

<style lang="scss"></style>
