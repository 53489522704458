<template>
  <div v-if="results?.items">
    <div class="space-y-5 lg:space-y-6">
      <page-result-item v-for="item in results.items" :model="item" />
    </div>
    <shared-pagination
      v-show="results.totalPages > 1"
      :current-page="results.page"
      :total-pages="results.totalPages"
      @page-changed="pageChanged"
    />
  </div>
</template>

<script setup lang="ts">
import SharedPagination from '@TodayInTheWord/components/shared/s-pagination.vue'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import { computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import PageResultItem from './page-result-item.vue'

const searchStore = useSearchPageStore()
const router = useRouter()
const route = useRoute()

interface PagesProperties {
  segment: searchTypes.ISegmentModel
  results: searchTypes.TitwSearchTabResults | null
}

const emit = defineEmits<{
  changePage: [page: number]
}>()

const props = withDefaults(defineProps<PagesProperties>(), {
  results: null,
})

const pageChanged = (page: number) => {
  emit('changePage', page)
}
</script>

<style lang="scss"></style>
