<template>
  <section
    v-if="model"
    v-bind:id="model.clientID"
    class="TITW cta-input-block container mx-auto overflow-hidden"
    :class="{ 'mobile-form-section px-0': !mediaStore.isDesktop }"
  >
    <div
      class="flex items-center space-y-4 px-6 py-8 md:rounded-3xl lg:px-24 lg:py-32"
      :class="{
        'bg-light-blue-50': model.backgroundColor === 'light-blue',
        'bg-grey-50': model.backgroundColor === 'gray',
        'flex-col lg:space-y-14': model.layout === 'centered-full-width',
        'flex-col lg:flex-row lg:space-y-0': model.layout === 'two-column',
      }"
    >
      <div
        class="flex"
        :class="{
          'w-full flex-col text-center': model.layout === 'centered-full-width',
          'w-full lg:w-1/2': model.layout === 'two-column',
        }"
      >
        <div class="flex w-full flex-col space-y-4">
          <div
            v-if="model.icon && model.icon !== 'no-icon'"
            class="flex h-10 w-10 items-center justify-center rounded-lg bg-cyan-700"
            :class="{ 'mx-auto': model.layout === 'centered-full-width' }"
          >
            <s-hero-icon :icon="model.icon" class="h-6 w-6 text-white" />
          </div>
          <h2 class="text-light-blue-900 font-semibold" :class="computedHeadingSize">
            {{ model.headline }}
          </h2>
          <p class="text-grey-700 text-sm lg:text-lg">
            {{ model.description }}
          </p>
        </div>
      </div>
      <div
        class="flex"
        :class="{
          'mx-auto w-full max-w-md': model.layout === 'centered-full-width',
          'w-full lg:w-1/2': model.layout === 'two-column',
        }"
      >
        <content-block
          v-if="model.form"
          class="w-full"
          :class="{
            centered: model.layout === 'centered-full-width',
          }"
          :model="model.form"
        >
        </content-block>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import SHeroIcon from '@TodayInTheWord/components/shared/s-hero-icon.vue'
import ContentBlock from '@TodayInTheWord/epi/content-block.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { computed } from 'vue'
import { CtaWithInputBlockProps } from './types/cta-with-input-block-types'

const mediaStore = useMediaSizeStore()

const props = defineProps<CtaWithInputBlockProps>()

const displayWidth = computed((layout) => {
  if (!layout) {
    return 'col-span-12'
  }
  // set defaults
  const mobile = 'col-span-12'
  let tablet = 'md:col-span-12'
  let desktop = 'xl:col-span-12'

  if (layout === 'two-column') {
    tablet = 'md:col-span-6'
    desktop = 'xl:col-span-6'
  }

  return `${mobile} ${tablet} ${desktop}`
})

const computedHeadingSize = computed(() => {
  if (props.model.layout === 'centered-full-width') {
    return 'text-3xl lg:text-5xl'
  } else {
    return 'text-xl lg:text-3xl'
  }
})
</script>

<style lang="scss">
.cta-input-block {
  .form-container {
    background-color: transparent;
  }

  &.mobile-form-section .form-container {
    padding: 0;
  }

  .EPiServerForms {
    .Form__Status {
      .Form__Status__Message {
        &.hide {
          display: none;
        }
      }
    }

    .Form__MainBody {
      section.Form__Element {
        flex-wrap: wrap;
      }

      .Form__Element {
        display: flex;
        width: 100%;
        margin: 0;
        justify-content: flex-start;

        &:first-of-type {
          margin-top: 0;
        }

        .Form__Element__Caption,
        label {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        &.FormTextbox {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .FormTextbox__Input {
            width: 100%;
            height: auto;
            padding: 12px;
            border: 1px solid #e5e7eb;
            border-radius: 12px;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            background-color: #fff;
          }
        }

        .FormSubmitButton,
        input[type='reset'] {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: 48px;
          margin-bottom: 0;
          padding: 8px 14px;
          background-color: #0c4a6e;
          border: 1px solid #0c4a6e;
          color: #fff;
          border-radius: 12px;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          cursor: pointer;
        }

        input[type='reset'] {
          background-color: #e0f2fe;
          border: 1px solid #e0f2fe;
          color: #0c4a6e;
          margin-right: 0;
          width: auto;
          align-self: flex-end;
        }

        .centered & {
          flex-direction: column;

          .FormSubmitButton {
            margin-top: 16px;
            margin-left: 0;
          }
        }

        .FormSubmitButton:hover {
          transition:
            0.3s background-color,
            0.3s border-color;
          border-color: #0369a1;
          background-color: #0369a1;
        }
      }

      @media (max-width: 1023px) {
        .Form__Element {
          flex-direction: column;

          .FormSubmitButton,
          input[type='reset'] {
            margin: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
