import { AxiosRequestConfig } from 'axios'
import { RouteLocationRaw } from 'vue-router'

export interface ISearchParams {
  q?: string
  p?: number
  c?: string[]
  d?: string
  a?: string[]
  t?: string
}

const createGetRequestUrl = (
  searchApiUrl: string | undefined,
  searchParams: ISearchParams,
): string => {
  const urlParameters: string[] = []
  if (searchParams.q) {
    urlParameters.push(`q=${encodeURIComponent(searchParams.q)}`)
  }

  if (searchParams.p && searchParams.p > 0) {
    urlParameters.push(`p=${searchParams.p}`)
  }

  if (searchParams.c && searchParams.c.length > 0) {
    urlParameters.push(`c=${encodeURIComponent(searchParams.c)}`)
  }

  if (searchParams.d) {
    urlParameters.push(`d=${searchParams.d}`)
  }

  if (searchParams.a && searchParams.a.length > 0) {
    urlParameters.push(`a=${encodeURIComponent(searchParams.a)}`)
  }

  if (searchParams.t && searchParams.t !== 'All') {
    urlParameters.push(`t=${searchParams.t}`)
  }

  if (urlParameters.length === 0) {
    return searchApiUrl
  }

  const queryInitialCharacter = searchApiUrl.indexOf('?') >= 0 ? '&' : '?'
  return `${searchApiUrl}${queryInitialCharacter}${urlParameters.join('&')}`
}

const createSanitizedSearchParams = (searchParams: ISearchParams): ISearchParams => {
  const sanitizedParams: ISearchParams = {
    p: searchParams.p,
    q: searchParams.q,
    c: searchParams.c,
    d: searchParams.d,
    a: searchParams.a,
    t: searchParams.t,
  }
  if (sanitizedParams.t === 'All') {
    sanitizedParams.t = ''
  }
  return sanitizedParams
}

const createGetRequest = (
  searchApiUrl: string | undefined,
  searchParams: ISearchParams,
): AxiosRequestConfig => {
  const url = createGetRequestUrl(searchApiUrl, searchParams)
  const request: AxiosRequestConfig = {
    method: 'get',
    url: url,
    headers: { Accept: 'application/json' },
  }

  return request
}

const createPostRequest = (
  searchApiUrl: string,
  searchParams: ISearchParams,
): AxiosRequestConfig => {
  const request: AxiosRequestConfig<ISearchParams> = {
    method: 'POST',
    url: searchApiUrl,
    headers: { Accept: 'application/json' },
    data: createSanitizedSearchParams(searchParams),
  }

  return request
}

function syncUrlSearchParams(
  searchParams: ISearchParams,
  newLocation: RouteLocationRaw, // Assuming route is available
): RouteLocationRaw {
  if (searchParams.q !== '') {
    newLocation.query.q = searchParams.q
  }

  if (searchParams.c && searchParams.c.length > 0) {
    newLocation.query.c = searchParams.c.join()
  }

  if (searchParams.d !== '') {
    newLocation.query.d = searchParams.d
  }

  if (searchParams.a && searchParams.a.length > 0) {
    newLocation.query.a = searchParams.a.join()
  }

  if (searchParams.t !== '') {
    newLocation.query.t = searchParams.t
  }

  return newLocation
}

export { createGetRequest, createPostRequest, syncUrlSearchParams, ISearchParams }
