<template>
  <div
    class="relative flex"
    v-c2-click-outside="closeLanguageMenu"
    v-if="header?.languageMenu && headerStore.showLanguageMenu"
  >
    <s-button
      class="hover:!border-light-blue-900 hover:!bg-light-blue-900 hover:text-light-blue-300 flex items-center space-x-4 pb-1.5 pt-1.5 text-xs font-normal"
      :size="'smaller'"
      @click="toggleLanguageMenu"
    >
      <span v-if="header.languageMenu.label">
        {{ header.languageMenu.label }}
      </span>
      <GlobeAltIcon class="h-6 w-6" />
    </s-button>
    <div
      v-show="menuState.isDesktopLanguageMenuOpen"
      class="absolute -inset-x-5 top-12 z-10 w-40"
    >
      <ul
        class="border-grey-200 z-20 flex w-full flex-col items-start space-y-4 rounded-xl border border-solid bg-white px-3 py-4 drop-shadow-md"
      >
        <li
          v-for="(item, index) in header.languageMenu.items"
          :key="index"
          class="w-full"
        >
          <s-link
            class="text-grey-700 flex w-full"
            target="_self"
            :external-link="true"
            :href="item.href"
            @click="closeLanguageMenu"
          >
            {{ item.name }}
          </s-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { GlobeAltIcon } from '@heroicons/vue/24/outline'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import vC2ClickOutside from 'uikitdirectives/c2-click-outside'

const headerStore = useHeaderStore()
const header = headerStore.header
const menuState = headerStore.menuState

function toggleLanguageMenu() {
  menuState.isDesktopLanguageMenuOpen = !menuState.isDesktopLanguageMenuOpen
}

function closeLanguageMenu() {
  menuState.isDesktopLanguageMenuOpen = false
}
</script>
