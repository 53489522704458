<template>
  <div class="inset-y-15 absolute left-0 z-40 h-screen w-full">
    <div class="border-grey-300 donateButton border-b bg-white p-5">
      <ul class="bg-color nohoverstate text-light-blue-50 flex flex-col space-y-6">
        <li v-if="headerStore.showAccountMenu" class="flex items-center space-x-2">
          <AccountMenuMobile></AccountMenuMobile>
        </li>
        <li v-if="headerStore.showLanguageMenu" class="flex items-center space-x-2">
          <LanguageSelector class="text-light-blue-900 pb-1.5 pt-1.5"></LanguageSelector>
        </li>
        <li v-if="header.donateButton && header.donateButton?.href">
          <s-link
            :href="header.donateButton?.href"
            :target="header.donateButton?.target"
            :external-link="header.donateButton?.isExternal"
            :style="'bg-cyan-600 border-cyan-600 text-color-white p3 w-full'"
            class="w-full pb-1.5 pt-1.5 !text-base"
          >
            {{ header.donateButton?.text }}
          </s-link>
        </li>
      </ul>
    </div>
    <div class="sliderNavWrapper bg-white p-5">
      <SliderNav></SliderNav>
    </div>
    <div class="border-grey-300 h-screen border-t bg-white p-5">
      <ul
        v-if="header.utilityMenu && header.utilityMenu.length > 0"
        class="flex flex-col space-y-6"
      >
        <li v-for="(item, index) in header.utilityMenu" :key="index">
          <s-link
            v-if="item.text"
            :href="item.href"
            :target="item.target"
            :external-link="item.isExternal"
            class="hover:bg-light-blue-700 text-light-blue-900 inline-flex cursor-pointer items-center justify-center rounded-xl py-1.5 transition duration-300 ease-in-out hover:text-white"
          >
            {{ item.text }}
          </s-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import AccountMenuMobile from '@TodayInTheWord/components/shared/header/components/main-nav/mobile/account-menu-mobile.vue'
import LanguageSelector from '@TodayInTheWord/components/shared/header/components/main-nav/mobile/language-selector.vue'
import SliderNav from '@TodayInTheWord/components/shared/header/components/main-nav/mobile/slider-nav-simplified.vue'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'

const headerStore = useHeaderStore()
const header = headerStore?.header
</script>

<style lang="scss">
.nohoverstate li div button:hover {
  &,
  * {
    background-color: inherit !important;
  }
}
</style>
