<template>
  <div class="relative z-10 mb-4 flex flex-col">
    <button
      class="bg-light-blue-100 text-light-blue-900 flex items-center justify-between rounded-xl px-3 py-2"
      @click="toggleMenu()"
    >
      <span>{{ selected }}</span>
      <span
        class="flex h-5 w-5 rotate-0 duration-300 ease-in-out"
        :class="{ 'rotate-180': state.isOpen }"
      >
        <ChevronDownIcon class="light-blue-900 h-5 w-5" />
      </span>
    </button>

    <ul
      class="border-grey-200 absolute left-0 right-0 top-full flex flex-col overflow-hidden rounded-xl border bg-white transition duration-300 ease-in-out"
      :class="[
        { 'invisible -translate-y-2.5 opacity-0': !state.isOpen },
        { 'opacity-1 visibile translate-y-1': state.isOpen },
      ]"
    >
      <li v-for="(item, index) in menuItems" :key="index">
        <s-link
          v-if="item.href && !item.isActive"
          :href="item.href"
          :external-link="item.isExternal"
          :target="item.target"
          class="text-grey-700 flex px-3 py-2 text-sm"
          :class="{ 'text-light-blue-700': hasActiveChildItem(item) }"
          @keydown.esc="toggleMenu()"
          @keydown.tab="closeMenu(index, $event)"
        >
          {{ item.text }}
        </s-link>
        <div v-else class="bg-light-blue-100 text-light-blue-900 flex px-3 py-2 text-sm">
          {{ item.text }}
        </div>

        <ul v-if="item.menuItems && item.menuItems.length > 0">
          <li v-for="(subItem, j) in item.menuItems" :key="j">
            <s-link
              v-if="subItem.href && !subItem.isActive"
              :href="subItem.href"
              class="bg-grey-50 text-grey-500 flex px-3 py-2 text-sm"
              @keydown.esc="toggleMenu()"
            >
              {{ subItem.text }}
            </s-link>
            <div v-else class="bg-light-blue-100 text-light-blue-900 flex px-3 py-2 text-sm">
              {{ subItem.text }}
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { computed, ref } from 'vue'

const props = defineProps({
  menuItems: {
    type: Array,
    default: () => [],
  },
})

const state = ref({
  isOpen: false,
})

function flattenList() {
  let list = []

  props.menuItems.map((item) => {
    list = [...list, item]

    if (item.menuItems && item.menuItems.length > 0) {
      list = [...list, ...item.menuItems]
    }
  })

  return list
}

const selected = computed(() => {
  const menuItems = flattenList()

  if (menuItems && menuItems.length > 0) {
    return menuItems.find((item) => item.isActive === true).text
  } else {
    return null
  }
})

function hasActiveChildItem(item) {
  if (item.menuItems && item.menuItems.length > 0) {
    return true
  } else {
    return false
  }
}

function closeMenu(index, e) {
  if (index === props.menuItems.length - 1 || (e.shiftKey && index === 0)) {
    toggleMenu()
  }
}

function toggleMenu() {
  state.value.isOpen = !state.value.isOpen
}
</script>
