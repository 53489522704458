<template>
  <div class="searchResults space-y-5 pb-10">
    <div
      v-if="
        searchResponse?.autocomplete &&
        searchResponse.autocomplete.suggestions &&
        headerStore.searchText
      "
      class="autocompleteSuggestions space-y-4 lg:space-y-2"
    >
      <div
        v-for="(suggestion, index) in searchResponse.autocomplete.suggestions"
        :key="index"
        v-html="formatSuggestion(suggestion.suggestion)"
        class="text-sm font-semibold first-letter:capitalize lg:text-base"
      ></div>
    </div>

    <div
      v-if="
        searchResponse?.devotions &&
        searchResponse.devotions.items &&
        searchResponse.devotions.items.length > 0
      "
      class="devotions border-b-lighter-grey border-b pb-5 lg:pb-6"
    >
      <div
        v-if="searchResponse.devotions.heading"
        class="mb-5 text-sm font-semibold text-slate-700 lg:text-base"
      >
        {{ searchResponse.devotions.heading }}
      </div>
      <div class="space-y-4 lg:space-y-5">
        <s-link
          v-for="(item, b) in searchResponse.devotions.items"
          :key="b"
          :href="item.url"
          :external-link="'true'"
          @click="closeSearchModal()"
          class="text-grey-700 group group flex space-x-4 rounded-xl"
        >
          <div
            v-if="item.image"
            class="flex h-auto max-h-[106px] w-full max-w-[188px] items-center justify-center overflow-hidden rounded-xl"
          >
            <s-image :model="{ url: formatImageUrl(item.image) }" class="w-full" />
          </div>
          <div class="flex flex-col justify-center space-y-1">
            <div
              v-if="item.title"
              class="group-hover:text-light-blue-600 text-sm font-semibold lg:text-base"
            >
              {{ item.title }}
            </div>
            <div v-if="item.description" class="text-grey-700 text-xs font-normal lg:text-sm">
              {{ item.description }}
            </div>
            <div v-if="item.displayDate" class="text-grey-600 text-xs font-medium">
              {{ item.displayDate }}
            </div>
          </div>
        </s-link>
      </div>
    </div>
    <div
      v-if="
        searchResponse?.otherPages &&
        searchResponse.otherPages.items &&
        searchResponse.otherPages.items.length > 0
      "
      class="otherPages"
    >
      <div
        v-if="searchResponse.otherPages.heading"
        class="mb-5 text-sm font-semibold text-slate-700 lg:text-base"
      >
        {{ searchResponse.otherPages.heading }}
      </div>
      <div class="space-y-4 lg:space-y-5">
        <s-link
          v-for="(item, d) in searchResponse.otherPages.items"
          :key="d"
          :href="item.url"
          :external-link="'true'"
          @click="closeSearchModal()"
          class="border-lighter-grey group flex flex-col border-b pb-4 lg:pb-5"
        >
          <div class="flex flex-col space-y-1">
            <div
              v-if="item.title"
              class="group-hover:text-light-blue-600 text-sm font-semibold text-cyan-900 lg:text-base"
            >
              {{ item.title }}
            </div>
            <div v-if="item.description" class="text-grey-700 text-sm font-normal">
              {{ item.description }}
            </div>
            <div class="flex space-x-3">
              <tag
                v-if="item.contentTag"
                :tag="{ displayName: item.contentTag, tagColor: 'light-blue' }"
                class="flex w-fit items-center justify-center rounded-xl px-2 py-0.5 text-xs font-medium"
              />
              <div v-if="item.displayDate" class="text-grey-600 text-sm">
                {{ item.displayDate }}
              </div>
            </div>
          </div>
        </s-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import sImage from '@TodayInTheWord/components/shared/s-image.vue'
import Tag from '@TodayInTheWord/components/shared/tag.vue'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import { storeToRefs } from 'pinia'

const emit = defineEmits(['closeSearchModal'])

const headerStore = useHeaderStore()
const { searchResponse } = storeToRefs(headerStore)

function closeSearchModal() {
  emit('closeSearchModal')
}

const formatSuggestion = function (suggestion: string) {
  let splitSuggestion: string[] = suggestion.toLowerCase().split('')
  let splitSearchText: string[] = headerStore.searchText.toLowerCase().split('')
  let matchedSegment: string | string[] = []
  let nonMatchedSegment: string | string[] = []

  matchedSegment = splitSuggestion.filter((char, index) => char === splitSearchText[index])
  nonMatchedSegment = splitSuggestion.filter((char, index) => char !== splitSearchText[index])

  return (
    `<a href="/search?q=${encodeURIComponent(suggestion)}" class="group">` +
    `<span class="text-grey-700 group-hover:text-light-blue-600">${matchedSegment.join('')}</span>` +
    `<span class="text-grey-400 group-hover:text-light-blue-600">${nonMatchedSegment.join('')}</span>` +
    `</a>`
  )
}

function formatImageUrl(imageUrl: string): string {
  const queryParams = 'width=188&height=106&format=webp&compand=true'
  return imageUrl.includes('?') ? `${imageUrl}&${queryParams}` : `${imageUrl}?${queryParams}`
}
</script>
