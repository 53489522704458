﻿<template>
  <main class="flex flex-col space-y-8 md:space-y-16">
    <template
      v-if="
        (model.heroModel && model.heroModel.contentType.includes('HeroBlock')) ||
        (model.heroModel && model.heroModel.contentType.includes('TriFeatureHeroBlock')) ||
        (model.heroModel && model.heroModel.contentType.includes('TitwTabHeroSliderBlock'))
      "
    >
      <template v-if="model.heroModel && model.heroModel.contentType.includes('HeroBlock')">
        <hero-block :model="model.heroModel" :subscription-form="model.subscriptionForm" />
      </template>
      <template
        v-if="model.heroModel && model.heroModel.contentType.includes('TriFeatureHeroBlock')"
      >
        <tri-feature-hero-block :model="model.heroModel" />
      </template>
      <template
        v-if="model.heroModel && model.heroModel.contentType.includes('TitwTabHeroSliderBlock')"
      >
        <tab-hero-slider-block :model="model.heroModel" class="mt-8 lg:mt-12" />
      </template>
    </template>
    <div v-else class="bg-grey-50">
      <div class="text-grey-900 container mx-auto py-7 lg:py-28">
        <h1 class="mb-8 text-2xl font-bold md:mb-6 md:text-4xl">
          {{ model.title }}
        </h1>
        <p class="w-2/3 text-sm md:text-2xl">{{ model.description }}</p>
      </div>
    </div>

    <content-area :model="model.pageContent" />
  </main>
</template>

<script setup lang="ts">
import HeroBlock from '@TodayInTheWord/components/blocks/hero/hero-block.vue'
import TriFeatureHeroBlock from '@TodayInTheWord/components/blocks/hero/tri-feature-hero-block.vue'
import tabHeroSliderBlock from '@TodayInTheWord/components/blocks/tab-hero-slider-block/tab-hero-slider-block.vue'
import ContentArea from '@TodayInTheWord/epi/content-area.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { LandingPageProps } from './types/landing'

const model: LandingPageProps['model'] = useEpiPageContextStore().model
</script>
