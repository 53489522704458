// TODO: implement directive correctly

function toggleEditAttributes(el, binding, vnode) {
  el.setAttribute('data-epi-edit', binding.value)
}

export default {
  bind: toggleEditAttributes,
  update: toggleEditAttributes,
}
