﻿<template>
  <div class="animate-pulse" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SkeletonSvg',
  props: {
    classes: {
      type: String,
      default: null,
    },
  },
}
</script>
