<template>
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 742 979"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="741" height="978" rx="15.5" fill="white" />
    <rect x="32" y="32" width="101" height="27" rx="12" fill="#D1D5DB" />
    <rect x="32" y="79" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="103" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="127" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="151" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="175" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="199" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="223" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="247" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="279" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="303" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="327" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="351" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="375" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="399" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="423" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="447" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="471" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="495" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="519" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="543" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="567" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="591" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="623" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="647" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="671" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="695" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="719" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="743" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="767" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="791" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="815" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="839" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="863" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="887" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="911" width="654" height="12" rx="6" fill="#E5E7EB" />
    <rect x="32" y="935" width="630" height="12" rx="6" fill="#E5E7EB" />
    <rect x="0.5" y="0.5" width="741" height="978" rx="15.5" stroke="#E5E7EB" />
  </svg>
</template>
