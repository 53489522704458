<template>
  <div class="mb-4 w-full md:mb-8 md:grid md:grid-cols-12 md:gap-8">
    <h1>{{ segment.heading }}</h1>
    <div>
      <exclamation-triangle-icon class="h-5 w-5 text-red-600" />
      <span class="text-grey-900 font-semibold">Configuration Error.</span>
    </div>
  </div>
  <div v-if="results?.items">
    <template v-for="item in results.items">
      <div>
        <a :href="item.url">{{ item.title }}</a>
      </div>
    </template>
    <shared-pagination
      v-show="results.totalPages > 1"
      :current-page="results.page"
      :total-pages="results.totalPages"
      @page-changed="pageChanged"
    />
  </div>
</template>

<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import SharedPagination from '@TodayInTheWord/components/shared/s-pagination.vue'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'

interface PagesProperties {
  segment: searchTypes.ISegmentModel
  results: searchTypes.TitwSearchTabResults | null
}

const emit = defineEmits<{
  changePage: [page: number]
}>()

const props = withDefaults(defineProps<PagesProperties>(), {
  results: null,
})

const pageChanged = (page: number) => {
  emit('changePage', page)
}
</script>

<style lang="scss"></style>
