<template>
  <section class="TITW tab-hero-slider" v-if="model" v-bind:id="model.clientID">
    <div class="container mx-auto w-full">
      <div class="flex flex-col">
        <div class="relative mb-8 overflow-hidden rounded-3xl lg:mb-12">
          <div class="absolute aspect-square h-full w-full lg:aspect-video">
            <SharedImage
              :model="model.heroImage"
              class="h-full w-full object-cover transition duration-300 ease-in-out"
              :preload="true"
            />
          </div>
          <div
            class="relative flex h-full w-full justify-center py-14 md:py-24"
            :class="{ 'bg-black/60': model.showDarkOverlay }"
          >
            <div
              class="transform flex-col justify-center rounded-xl p-6 text-center lg:px-16 lg:py-12"
              :class="{
                'gradient-background border-grey-50 h-auto max-w-[85%] border lg:h-[40%] lg:w-[60%] lg:backdrop-blur-[6px] ':
                  !model.showDarkOverlay,
              }"
            >
              <h2
                v-if="model.heroHeading"
                class="lg:leading-[48px mb-4 text-3xl font-semibold leading-9 lg:mb-6 lg:text-4xl"
                :class="model.showDarkOverlay ? 'text-white' : 'text-cyan-900'"
              >
                {{ model.heroHeading }}
              </h2>
              <div
                v-if="model.heroSubheading"
                class="text-lg font-medium leading-7 lg:text-2xl lg:leading-8"
                :class="model.showDarkOverlay ? 'text-white' : 'text-grey-500'"
              >
                {{ model.heroSubheading }}
              </div>
              <div class="lg-10 mt-8 flex flex-wrap justify-center space-x-4">
                <s-link
                  v-for="button in model.heroButtons"
                  class="group"
                  :href="button.href"
                  :external-link="button.isExternal"
                  :target="button.target"
                  :title="button.title"
                  :emphasis="button.buttonEmphasis"
                  :size="button.buttonSize"
                  :style="button.buttonStyle"
                  >{{ button.text }}</s-link
                >
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="model.tabs && model.tabs.length > 0 && mediaStore.isDesktop"
          class="mx-auto flex w-full flex-wrap justify-center"
          ref="tabContent"
        >
          <c2-slider
            :slide-total="model.tabs.length"
            :slides-to-show="4"
            :slides-to-show-tablet="2"
            :slides-to-show-mobile="1"
            :slide-speed="500"
            :gutter="mediaStore.isMobile ? 10 : 16"
            :media-tablet-query="1023"
            :media-mobile-query="639"
            :show-overflow="false"
            :show-button-nav="true"
            :showIndicators="false"
            :next-btn-classes="'w-12 h-12 flex items-center justify-center rounded-xl bg-light-blue-900 hover:bg-light-blue-700 hidden md:flex disabled:bg-grey-400 transition'"
            :prev-btn-classes="'w-12 h-12 flex items-center justify-center rounded-xl bg-light-blue-900 hover:bg-light-blue-700 mr-3 hidden md:flex disabled:bg-grey-400 transition'"
            :nav-classes="'flex justify-end mt-4 relative md:mt-14 md:mb-0'"
            :button-nav-wrapper-classes="'max-w-full absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 flex align-center'"
            :button-classes="'relative h-6 w-[37px] mx-0.5 after:rounded after:block after:h-1 after:w-full after:bg-grey-300 after:absolute after:top-1/2 after:transform after:-translate-y-1/2'"
            :active-button-classes="'after:!bg-light-blue-900'"
            class="relative w-full -translate-x-8 transform pl-8 md:translate-x-0 md:pl-0"
          >
            <template v-slot:slide>
              <div v-for="(tab, index) in model.tabs" :key="index" class="h-full w-full">
                <div
                  class="bg-grey-100 hover:bg-light-blue-700 group relative flex aspect-video w-full cursor-pointer overflow-hidden rounded-xl transition"
                  :class="state.activeTab === index ? 'bg-light-blue-700' : 'bg-grey-100'"
                  @click.prevent="[(state.activeTab = index), scrollToContent(tabContent)]"
                >
                  <div
                    class="text-grey-700 z-10 flex h-full w-full flex-col justify-end transition ease-in group-hover:text-white lg:p-5 xl:p-6"
                    :class="state.activeTab === index ? 'text-white' : 'text-grey-700'"
                  >
                    <div
                      class="line-clamp-2 text-base font-semibold leading-6 lg:text-lg lg:leading-7"
                    >
                      {{ tab.title }}
                    </div>
                    <div class="line-clamp-4 text-base lg:mt-2 xl:mt-4">
                      {{ tab.subheading }}
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:prev-btn>
              <span class="sr-only"> Previous Slide </span>
              <span class="flex h-6 w-6 items-center justify-center text-white">
                <ArrowLeftIcon class="w-full" />
              </span>
            </template>

            <template v-slot:next-btn>
              <span class="sr-only"> Next Slide </span>
              <span class="flex h-6 w-6 items-center justify-center text-white">
                <ArrowRightIcon class="w-full" />
              </span>
            </template>
          </c2-slider>
        </div>
        <div
          v-if="model.tabs && model.tabs.length > 0 && !mediaStore.isDesktop"
          class="flex flex-wrap"
          ref="mobileTabContent"
        >
          <div
            v-for="(tab, index) in model.tabs"
            :key="index"
            class="bg-grey-100 hover:bg-light-blue-700 group mb-4 flex h-full w-full justify-center rounded-xl p-4 md:w-1/2"
            :class="state.activeTab === index ? 'bg-light-blue-700' : 'bg-grey-100'"
            @click.prevent="[(state.activeTab = index), scrollToContent(mobileTabContent)]"
          >
            <div
              v-if="tab.title"
              class="text-grey-700 text-base font-semibold leading-6 group-hover:text-white"
              :class="state.activeTab === index ? 'text-white' : 'text-grey-700'"
            >
              {{ tab.title }}
            </div>
          </div>
        </div>
        <div v-if="model.tabs && model.tabs.length > 0">
          <div v-for="(tab, index) in model.tabs">
            <div v-if="tab.content && index === state.activeTab" class="mt-8 lg:mt-16">
              <content-area :model="tab.content" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import sLink from '@TodayInTheWord/components/shared/s-link.vue'
import ContentArea from '@TodayInTheWord/epi/content-area.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import C2Slider from 'uikit/c2-slider/c2-slider.vue'
import { ref } from 'vue'
import { TabHeroSliderBlockProps } from './types/tab-hero-slider-block-types'

const props = defineProps<TabHeroSliderBlockProps>()
const mediaStore = useMediaSizeStore()
const tabContent = ref<HTMLElement | null>(null)
const mobileTabContent = ref<HTMLElement | null>(null)

function scrollToContent(element: ref<HTMLElement>) {
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}
const state = ref({
  activeTab: 0,
})
</script>
<style lang="scss">
.gradient-background {
  background: radial-gradient(
      138.63% 80.74% at 28.22% 55.07%,
      rgba(251, 250, 249, 0.38) 0%,
      rgba(209, 235, 255, 0.38) 100%
    ),
    rgba(249, 250, 251, 0.6);
}
</style>
