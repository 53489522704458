﻿<template>
  <main class="flex flex-col space-y-8 md:space-y-16">
    <!-- insert home hero -->
    <h1 v-if="model.screenReaderPageTitle" class="sr-only">{{ model.screenReaderPageTitle }}</h1>
    <content-area :model="model?.heroContent" />
    <content-area :model="model?.pageContent" />
  </main>
</template>

<script setup lang="ts">
import ContentArea from '@TodayInTheWord/epi/content-area.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { HomePageProps } from './types/home'

const model: HomePageProps['model'] = useEpiPageContextStore().model
</script>

<style lang="scss">
div.bullet-seperator {
  span:not(:first-child):before {
    content: '\2022' !important;
  }
}
</style>
