<template>
  <div class="TITW search-bar header-search-btn mx-3 max-w-full grow">
    <s-button
      :style="'neutral'"
      :emphasis="'outline'"
      :size="'smaller'"
      class="text-grey !border-grey-200 hover:border-grey-200 active:border-grey-200 flex max-h-10 w-full justify-between px-3 py-2.5 font-normal shadow-sm hover:bg-white active:bg-white"
      @click="openSearchModal()"
    >
      {{ header?.searchPlaceholderText }}
      <MagnifyingGlassIcon class="text-grey-500 h-5 w-5" />
      <span class="sr-only"> Search </span>
    </s-button>
  </div>

  <c2-modal
    ref="searchModal"
    :background-overlay-color="'rgba(0,0,0,0.5)'"
    :open-btn-classes="'hidden'"
    :close-btn-classes="'hidden'"
    :header-classes="'hidden'"
    :wrapper-classes="'rounded-xl'"
    :transition-type="'zoom'"
    :max-width="852"
    @click="closeSearchModal()"
  >
    <template v-slot:body>
      <div class="relative h-[450px] w-full overflow-hidden bg-white">
        <div class="text-input sticky left-0 right-0 top-0">
          <div class="h-[3.75rem]">
            <input
              ref="searchInput"
              v-model="searchText"
              :placeholder="header?.searchPlaceholderText"
              @input="sayt()"
              @keyup.enter="searchRedirect"
              class="border-grey-200 text-grey-500 hover:border-grey-200 active:border-grey-200 flex h-full w-full justify-between rounded-t-xl border-b px-11 py-4 text-base hover:bg-white active:bg-white"
              @click.prevent.stop
            />
          </div>
          <button class="absolute left-3 top-5" @click.prevent.stop="searchRedirect()">
            <MagnifyingGlassIcon class="text-grey-400 h-5 w-5" />
            <span class="sr-only"> Search </span>
          </button>
          <div class="absolute right-3 top-3 flex space-x-2">
            <button v-if="searchText" @click.prevent.stop="clearDekstopSearch()">
              <XMarkIcon class="text-grey-400 h-5 w-5" />
              <span class="sr-only"> Clear search input </span>
            </button>
            <button
              class="cursor-hover bg-light-blue-100 rounded-xl px-3 py-2 text-base leading-6"
              @click="closeSearchModal()"
            >
              <span class="text-light-blue-900"> Close </span>
              <span class="sr-only"> Close Search Modal </span>
            </button>
          </div>
        </div>
        <div
          class="absolute left-0 top-16 h-[390px] w-full overflow-auto px-5 pt-6"
          @click.self.prevent.stop
        >
          <div v-if="headerStore.isLoading" class="w-full" @click.prevent.stop>
            <SkeletonLoader>
              <SearchResultsSvg></SearchResultsSvg>
            </SkeletonLoader>
          </div>
          <div v-else-if="headerStore.isSearchResponseValid" class="w-full">
            <SearchResults
              v-if="headerStore.hasSearchResults"
              @close-search-modal="closeSearchModal"
            />
            <div
              v-if="headerStore.hasSearchResults"
              class="mb-10 inline-flex h-10 w-full flex-col items-center justify-start gap-2.5"
            >
              <s-button
                :size="'regular'"
                :emphasis="'bold'"
                :style="'primary'"
                class="inline-flex h-10 items-center justify-center gap-3 rounded-xl bg-sky-900 px-3 py-2 text-justify text-base font-medium text-sky-50"
                @click="searchRedirect"
              >
                See All Results
              </s-button>
            </div>
            <NoResults v-else @click.prevent.stop />
          </div>
        </div>
      </div>
    </template>
  </c2-modal>
</template>

<script setup lang="ts">
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import SearchResultsSvg from '@TodayInTheWord/components/icons/search-skeleton.vue'
import NoResults from '@TodayInTheWord/components/shared/header/components/main-nav/desktop/no-results.vue'
import SearchResults from '@TodayInTheWord/components/shared/header/components/main-nav/desktop/search-results.vue'
import SkeletonLoader from '@TodayInTheWord/components/shared/skeleton-svg.vue'
import debounce from '@TodayInTheWord/scripts/debounce'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import { storeToRefs } from 'pinia'
import C2Modal from 'uikit/c2-modal/c2-modal.vue'
import { nextTick, Ref, ref, VNodeRef } from 'vue'

const headerStore = useHeaderStore()
const { header, searchText } = storeToRefs(headerStore)

const searchModal: Ref<any> = ref(null)
const searchInput = ref<VNodeRef | null>(null)

function openSearchModal() {
  searchModal.value?.openModal()
  nextTick(() => {
    searchInput.value?.focus()
  })
}

function clearDekstopSearch() {
  headerStore.clearSearch()
  searchInput.value?.focus()
}
function closeSearchModal() {
  searchModal.value?.closeModal()
  headerStore.clearSearch()
}

const saytDebounce = debounce(headerStore.search, 1000)
function sayt() {
  saytDebounce()
}

function searchRedirect() {
  if ((searchText.value ?? '').length === 0) {
    searchInput.value?.focus()
    return
  }

  const newLocation =
    (header.value?.searchPageLink ?? '/search') + `?q=${encodeURIComponent(searchText.value)}`

  window.location.href = newLocation
}
</script>

<style lang="scss">
.c2-modal .overlay {
  backdrop-filter: blur(24px);
}
</style>
