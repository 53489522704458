<template>
  <div class="border-b-lighter-grey w-full space-y-3 border-b pb-6 md:space-y-4">
    <div class="flex items-center space-x-1 md:space-x-2">
      <div
        class="border-light-blue-200 bg-light-blue-100 inline-flex h-6 w-6 flex-shrink-0 flex-col items-center justify-center rounded border bg-sky-50 text-sm font-semibold text-sky-900"
      >
        Q
      </div>
      <s-link
        v-if="model.title && model.url"
        :href="model.url"
        :external-link="true"
        class="text-sm font-medium text-cyan-900 md:text-base"
      >
        {{ model.title }}
      </s-link>
    </div>
    <div v-if="model.description" class="text-grey-700 text-sm font-medium">
      {{ model.description }}
    </div>
    <div class="flex items-center space-x-4">
      <div
        v-if="model.authorNames && model.authorNames.length > 0"
        class="text-grey-700 text-xs font-medium"
      >
        {{ computedAuthor }}
      </div>
      <div
        v-if="model.categories && model.categories.length > 0"
        class="bullet-seperator flex items-center space-x-1 [&>:not(:first-child)]:before:mr-1"
      >
        <span
          v-for="(category, index) in computedCategories"
          :key="index"
          class="text-grey-500 text-xs font-normal"
        >
          {{ category }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import sLink from '@TodayInTheWord/components/shared/s-link.vue'
import { QAResultItem } from '@TodayInTheWord/stores/qa-index/types'
import { computed } from 'vue'

interface QAQuestionResultProps {
  model?: QAResultItem
}

const props = defineProps<QAQuestionResultProps>()

const computedAuthor = computed(() => {
  //return the first author
  return props?.model?.authorNames[0]
})

const computedCategories = computed(() => {
  return props?.model?.categories?.slice(0, 2)
})
</script>

<style scoped></style>
