<template>
  <ul class="socialLinks flex items-center space-x-2.5">
    <li v-for="(item, index) in footer?.socialMediaCollection" :key="index">
      <s-link
        :href="item.socialMediaLink"
        :external-link="true"
        :style="'primary'"
        class="align-center flex h-10 w-10 justify-center !rounded-lg"
        :class="`${item.socialMedia.toLowerCase()}Button`"
      >
        <span class="sr-only">{{ item.socialMedia }}</span>
        <span v-if="item.socialMedia.toLowerCase() === 'facebook'" class="flex">
          <facebook-icon :fill-class="'fill-white'" />
        </span>
        <span v-if="item.socialMedia.toLowerCase() === 'twitter'" class="flex">
          <twitter-icon :fill-class="'fill-white'" />
        </span>
        <span v-if="item.socialMedia.toLowerCase() === 'instagram'" class="flex">
          <instagram-icon :fill-class="'fill-white'" />
        </span>
        <span v-if="item.socialMedia.toLowerCase() === 'youtube'" class="flex">
          <youtube-icon :fill-class="'fill-white'" />
        </span>
        <span v-if="item.socialMedia.toLowerCase() === 'pinterest'" class="flex">
          <pinterest-icon :fill-class="'fill-white'" />
        </span>
      </s-link>
    </li>
  </ul>
</template>

<script setup lang="ts">
import FacebookIcon from '@TodayInTheWord/components/icons/facebook-icon.vue'
import InstagramIcon from '@TodayInTheWord/components/icons/instagram-icon.vue'
import PinterestIcon from '@TodayInTheWord/components/icons/pinterest-icon.vue'
import TwitterIcon from '@TodayInTheWord/components/icons/twitter-icon.vue'
import YoutubeIcon from '@TodayInTheWord/components/icons/youtube-icon.vue'
import { useFooterStore } from '@TodayInTheWord/stores/footer/footer'
import { SocialNavProps } from '@TodayInTheWord/stores/footer/footer-types'

const footer = useFooterStore().footer

const props = defineProps<SocialNavProps>()
</script>
