<template>
  <s-link
    v-if="card.url"
    :href="card.url"
    :external-link="true"
    class="titw related-video group block w-full flex-col"
  >
    <div
      class="bg-light-blue-100 relative aspect-[16/9] w-full overflow-hidden rounded-xl transition-all duration-300 ease-in-out group-hover:shadow-lg"
    >
      <shared-image
        v-if="card.image"
        :model="{ url: card.image }"
        class="aspect-video h-full w-full object-cover"
        format="?width=540&height=276&format=webp&compand=true"
      />
      <div
        v-if="isDateWithinOneYear(articleDate)"
        class="absolute bottom-2 left-2 w-fit rounded-lg bg-black/60 px-2 py-1 text-xs text-white"
      >
        {{ articleDate }}
      </div>
    </div>
    <div v-if="card.title || card.description || card.duration" class="w-full space-y-2 p-2">
      <div class="flex items-center justify-between">
        <span
          v-if="card.title"
          class="text-grey-800 group-hover:text-light-blue-600 line-clamp-2 text-base font-medium transition duration-300"
        >
          {{ card.title }}
        </span>
        <div v-if="card.duration" class="text-grey-600 text-xs font-normal leading-none">
          {{ card.duration }}
        </div>
      </div>
      <div v-if="card.description" class="text-grey-700 line-clamp-4 text-sm font-normal">
        {{ card.description }}
      </div>
      <div
        v-if="card.categories && card.categories.length > 0"
        class="bullet-seperator flex items-center space-x-1 [&>:not(:first-child)]:before:mr-1"
      >
        <span
          v-for="(category, index) in computedCategories"
          :key="index"
          class="text-grey-500 space-x-1 text-xs font-normal"
        >
          {{ category }}
        </span>
      </div>
    </div>
  </s-link>
</template>

<script setup lang="ts">
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import { isDateWithinOneYear } from '@TodayInTheWord/scripts/date-util'
import { ImageLinkCardProps } from '@TodayInTheWord/types/shared'
import { DateTime } from 'luxon'
import { computed } from 'vue'

interface SImageLinkCardProps {
  card: ImageLinkCardProps
}

const props = defineProps<SImageLinkCardProps>()

const articleDate = computed(() => {
  if (props.card.articleDate || props.card.date) {
    if (props.card.articleDate) {
      const date = DateTime.fromISO(props.card.articleDate)
      return date.toLocaleString()
    } else {
      const date = DateTime.fromISO(props.card.date)
      return date.toLocaleString()
    }
  } else {
    return false
  }
})

const cardType = computed(() => {
  if (props.card.articleType) {
    return props.card.articleType
  } else {
    return props.card.type
  }
})
const computedCategories = computed(() => {
  return props?.card?.categories?.slice(0, 2)
})
</script>
