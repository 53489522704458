<template>
  <nav
    class="w-full overflow-hidden"
    :class="[
      'mobileMenu',
      state.level2Items ? 'level2' : null,
      state.level3Items ? 'level3' : null,
    ]"
  >
    <div class="level1 flex flex-col space-y-5">
      <div class="headerItem" v-for="topLevel in state.menuItems">
        <button
          type="button"
          class="l1 text-light-blue-900 flex w-full items-center justify-between font-medium"
          @click="(e) => setLevel2(e, topLevel.submenu, topLevel)"
          @keydown.right="(e) => setLevel2(e, topLevel.submenu, topLevel)"
          ref="link"
        >
          <span><ArrowLeftIcon class="left h-5 w-5"></ArrowLeftIcon> Back</span>
          <strong>{{ topLevel.text }}</strong>
          <ArrowRightIcon class="right h-5 w-5"></ArrowRightIcon>
        </button>
        <s-link :href="topLevel.url" class="activeMenuItem" @click="closeMobileMenu()">
          <strong>{{ topLevel.text }}</strong>
        </s-link>
      </div>
    </div>
    <template v-if="state.level2Items">
      <div class="children level2">
        <div class="column headerItem">
          <s-link
            :href="state.level1Link.url"
            class="text-light-blue-900 flex w-full items-center justify-between font-medium"
            ref="link"
            @click="closeMobileMenu()"
            :external-link="false"
          >
            {{ state.level1Link.text }}
          </s-link>
        </div>
        <div class="column" v-for="subMenu in state.level2Items.columns">
          <template v-if="subMenu.headerLink">
            <button
              type="button"
              class="l2 text-light-blue-900 mt-6 flex w-full items-center justify-between font-medium"
              @click="(e) => setLevel3(e, subMenu.links, subMenu.headerLink)"
              @keydown.right="(e) => setLevel3(e, subMenu.links, subMenu.headerlink)"
              ref="link"
            >
              <span><ArrowLeftIcon class="left h-5 w-5"></ArrowLeftIcon> Back</span>
              <strong>{{ subMenu.headerLink.text }}</strong>
              <ArrowRightIcon class="right h-5 w-5"></ArrowRightIcon>
            </button>
            <s-link
              :href="subMenu.headerLink.url"
              class="activeMenuItem"
              @click="closeMobileMenu()"
            >
              <strong>{{ subMenu.headerLink.text }}</strong>
            </s-link>
          </template>
          <template v-else>
            <div class="children level3">
              <ul class="level3Items">
                <li v-for="item in subMenu.links" class="mt-6">
                  <s-link
                    :href="item.href"
                    class="text-light-blue-900 flex w-full items-center justify-between font-medium"
                    ref="link"
                    @click="closeMobileMenu()"
                    :external-link="item.isExternal"
                    :target="item.target"
                  >
                    {{ item.text }}
                  </s-link>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-if="state.level3Items">
      <div class="children level3">
        <ul class="level3Items">
          <li v-if="state.level2Link" class="mt-6">
            <s-link
              :href="state.level2Link.href"
              class="text-light-blue-900 flex w-full items-center justify-between font-medium"
              ref="link"
              @click="closeMobileMenu()"
              :external-link="state.level2Link.isExternal"
              :target="state.level2Link.target"
            >
              {{ state.level2Link.text }}
            </s-link>
          </li>
          <li v-for="item in state.level3Items" class="mt-6">
            <s-link
              :href="item.href"
              class="text-light-blue-900 flex w-full items-center justify-between font-medium"
              ref="link"
              @click="closeMobileMenu()"
              :external-link="item.isExternal"
              :target="item.target"
            >
              {{ item.text }}
            </s-link>
          </li>
        </ul>
      </div>
    </template>
  </nav>
</template>

<style lang="scss">
.mobileMenu {
  .level1 .headerItem,
  .level2 .column,
  .level3 li {
    button {
      .left,
      span {
        display: none;
      }

      strong {
        font-weight: inherit;
      }
    }
  }

  .activeMenuItem {
    display: none;
  }

  &.level2,
  &.level3 {
    .column.headerItem {
      display: none;
    }

    div.active button {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;

      .left,
      span {
        margin-right: 1em;
      }
    }
  }

  &.level2 {
    .level1 .headerItem {
      display: none;

      .activeMenuItem {
        display: none;
      }

      &.active {
        display: block;
        line-height: 32px;

        .left {
          display: block;
        }

        span {
          display: flex;
          align-content: center;
          align-items: center;
        }

        .right {
          display: none;
        }

        button strong {
          display: none;
        }

        .activeMenuItem {
          display: block;
          margin-top: 1em;
        }

        strong {
          color: #111827;
          font-size: 24px;
          font-weight: 600;
        }
      }
    }
  }

  &.level3 {
    .level1 .headerItem,
    .level1 .headerItem.active,
    ul.level3Items li:first-child {
      display: none;
    }

    ul.level3Items li:first-child {
      display: block;
      a {
        color: #111827;
        font-size: 24px;
        font-weight: 600;
      }
    }

    .level2 .column {
      display: none;

      .activeMenuItem {
        display: none;
      }

      &.active {
        display: block;
        line-height: 32px;

        .left {
          display: block;
        }

        span {
          display: flex;
          align-content: center;
          align-items: center;
        }

        .right {
          display: none;
        }

        a,
        strong {
          display: none;
        }

        button strong {
          display: none;
        }

        .activeMenuItem {
          display: block;
          margin-top: 1em;
        }
      }
    }
  }

  button > * {
    pointer-events: none;
  }
}
</style>

<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/solid'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import { storeToRefs } from 'pinia'
import { onMounted, reactive, ref } from 'vue'

const selected = ref([])

const select = (character, level) => {
  selected.value[level] = character
  selected.value.splice(level + 1)
}

const headerStore = useHeaderStore()
const { header, menuState } = storeToRefs(headerStore)

const state = reactive({
  currentPosition: [],
  levels: [],
  currentLevel: [],
  prevLevelDepth: 0,
  currentLevelDepth: 0,
  menuItems: header.value?.primaryMenu,
  menuThreeLink: '',
  slideNavLink: '',
  level2Items: null,
  level1Link: null,
  level3Items: null,
  level2Link: null,
})

const navLevels = ref(null)
const link = ref(null)
const back = ref(null)

function setLevel2(e, items, level1) {
  if (e.target.parentElement.classList.contains('active')) {
    state.level2Items = null
  } else {
    state.level2Items = items
  }
  state.level1Link = level1
  state.level3Items = null
  toggleTree(e)
}

function setLevel3(e, items, level2) {
  if (e.target.parentElement.classList.contains('active')) {
    state.level3Items = null
  } else {
    state.level3Items = items
  }
  state.level2Link = level2
  toggleTree(e)
}

function toggleTree(e) {
  e.target.parentElement.classList.toggle('active')
}

function closeMobileMenu() {
  if (menuState.value.isMobileMenuOpen) {
    menuState.value.isMobileMenuOpen = false
    return
  }

  menuState.value.isMobileMenuOpen = true
}
onMounted(() => {})
</script>
