<template>
  <section v-if="model && model.items?.length > 0" class="space-y-6">
    <div class="flex justify-between">
      <h2
        v-if="model.heading"
        class="heading-class text-grey-700 w-full overflow-hidden text-base font-semibold md:text-xl"
      >
        {{ model.heading }}
      </h2>
      <template v-if="model.showAllLink">
        <s-button
          v-if="!mediaSizeStore.isMobile && model.items.length >= 8"
          :style="'outline'"
          :emphasis="'outline'"
          :aria-label="model.showAllLink.ariaLabel"
          class="hover:text-light-blue-600 flex justify-center whitespace-nowrap border-none !py-0 hover:bg-transparent"
          @click="handleSeeAll(model.showAllLink.contentTag)"
        >
          {{ model.showAllLink.text }}
          <span class="ml-2 h-5 w-5"><arrow-long-right-icon /></span
        ></s-button>
      </template>
    </div>

    <div
      v-if="!mediaSizeStore.isMobile && model.items && model.items.length > 0"
      class="grid grid-cols-4 gap-x-6 gap-y-6"
    >
      <devotional-card
        v-for="(card, index) in model.items"
        :card="card"
        :key="index"
        class="col-span-1 w-full"
      />
    </div>
    <div v-if="mediaSizeStore.isMobile && model.items && model.items.length > 0">
      <c2-slider
        :slide-total="model.items?.length"
        :slides-to-show="1"
        :slides-to-show-tablet="2"
        :slides-to-show-mobile="1"
        :slide-speed="500"
        :gutter="mediaSizeStore.isMobile ? 16 : 24"
        :media-tablet-query="1023"
        :media-mobile-query="639"
        :show-overflow="true"
        :show-button-nav="true"
        :showIndicators="false"
        :next-btn-classes="'hidden'"
        :prev-btn-classes="'hidden'"
        :nav-classes="'flex justify-end mt-4 relative md:mt-14 md:mb-0'"
        :button-nav-wrapper-classes="'max-w-full absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 flex align-center'"
        :button-classes="'relative h-6 w-[37px] mx-0.5 after:rounded after:block after:h-1 after:w-full after:bg-grey-300 after:absolute after:top-1/2 after:transform after:-translate-y-1/2'"
        :active-button-classes="'after:!bg-light-blue-600'"
        class="relative w-full -translate-x-8 transform pl-8 md:translate-x-0 md:pl-0"
      >
        <template v-slot:slide>
          <devotional-card v-for="(card, index) in model.items" :card="card" :key="index" />
        </template>
      </c2-slider>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ArrowLongRightIcon } from '@heroicons/vue/20/solid'
import sButton from '@TodayInTheWord/components/shared/s-button.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import C2Slider from 'uikit/c2-slider/c2-slider.vue'
import { AllTabSections } from '../types/devotional-index-types'
import devotionalCard from './devotional-card.vue'

const emit = defineEmits<{ seeAllOfCategory: [categoryType: string] }>()

interface DevotionalSlider {
  model?: AllTabSections
}
const props = defineProps<DevotionalSlider>()
const mediaSizeStore = useMediaSizeStore()

function handleSeeAll(contentTag: string) {
  emit('seeAllOfCategory', contentTag)
}
</script>
