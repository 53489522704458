export default {
  trackHitEventValidation: (query, hitId, trackId) => {
    if (query && hitId && trackId) {
      return true
    } else {
      return false
    }
  },
  trackHit: (query, hitId, trackId) => {
    const isValid = query && hitId && trackId
    if (!isValid) {
      return
    }

    if (navigator && navigator.sendBeacon) {
      const data = {
        query: query,
        trackId: trackId,
        hitId: hitId,
      }

      navigator.sendBeacon('/api/search/track-hit', JSON.stringify(data))
    }
  },
}
