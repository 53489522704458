<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 0.600006H3.19999C1.8745 0.600006 0.799988 1.60289 0.799988 2.84001V15.16C0.799988 16.3971 1.8745 17.4 3.19999 17.4H14.6C14.9314 17.4 15.2 17.1493 15.2 16.84C15.2 16.5307 14.9314 16.28 14.6 16.28H3.79999C3.13725 16.28 2.59999 15.7786 2.59999 15.16C2.59999 14.5414 3.13725 14.04 3.79999 14.04H14C14.6627 14.04 15.2 13.5386 15.2 12.92V1.72001C15.2 1.10145 14.6627 0.600006 14 0.600006ZM9.45716 3.71112C9.45716 3.23002 9.07341 2.84001 8.60002 2.84001C8.12663 2.84001 7.74288 3.23002 7.74288 3.71112V4.66934H6.45713C5.98374 4.66934 5.59999 5.05935 5.59999 5.54045C5.59999 6.02155 5.98374 6.41156 6.45713 6.41156H7.74288V9.8089C7.74288 10.29 8.12663 10.68 8.60002 10.68C9.07341 10.68 9.45716 10.29 9.45716 9.8089V6.41156H10.7428C11.2162 6.41156 11.6 6.02155 11.6 5.54045C11.6 5.05935 11.2162 4.66934 10.7428 4.66934H9.45716V3.71112Z"
      :class="props.fillClass"
    />
  </svg>
</template>

<script setup lang="ts">
const props = defineProps({
  fillClass: {
    type: String,
    default: null,
  },
})
</script>
