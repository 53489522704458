<template>
  <div v-if="model">
    <div
      v-if="model"
      class="border-b-lighter-grey flex w-full flex-col-reverse items-center gap-y-4 border-b pb-9 lg:flex-row lg:gap-x-12 lg:pb-12"
    >
      <div class="flex w-full flex-col">
        <div
          class="row item-center mb-3 flex justify-between lg:mb-4 lg:justify-normal lg:space-x-4"
        >
          <tag
            v-if="model.tag"
            :tag="{ displayName: model.tag, tagColor: 'light-blue' }"
            class="rounded-xl px-3 py-1 text-sm font-medium"
          />
          <div v-if="model.date" class="flex items-center">
            {{ model.date }}
          </div>
        </div>
        <div class="mb-3 space-y-1 lg:mb-10 lg:space-y-2">
          <h3 v-if="model.title" class="text-2xl font-semibold text-cyan-900 lg:text-4xl">
            {{ model.title }}
          </h3>
          <div v-if="model.description" class="text-grey-700 text-base font-normal lg:text-xl">
            {{ model.description }}
          </div>
        </div>
        <div class="flex space-x-2.5">
          <s-button
            v-if="model.link && model.link.href"
            :href="model.link.href"
            :external-link="model.link.isExternal"
            :emphasis="'bold'"
            :style="'primary'"
            class="w-fit"
            @click="openVideoModal"
          >
            {{ model.link.text }}
          </s-button>
        </div>
      </div>
      <div
        class="bg-light-blue-100 relative aspect-video lg:w-7/12 lg:shrink-0 lg:overflow-hidden lg:rounded-xl"
        :class="{ '-mx-[18px]': mediaSizeStore.isMobile }"
      >
        <s-image
          v-if="model.featuredImage"
          :model="model.featuredImage"
          class="h-full w-full object-cover"
          :preload="true"
        />

        <button
          ref="playImage"
          class="group absolute left-1/2 top-1/2 w-fit -translate-x-1/2 -translate-y-1/2 p-4"
          @click="openVideoModal"
        >
          <span class="sr-only">Play Video</span>
          <play-icon
            class="group-hover:fill-light-blue-600 h-10 w-10 text-white transition duration-300"
          />
        </button>
      </div>
    </div>
    <c2-modal
      v-if="model.videoEmbedCode"
      ref="videoModal"
      :background-color="'transparent'"
      :background-overlay-color="'rgba(0,0,0,0.8)'"
      :close-btn-classes="'flex items-center justify-center h-12 w-12 absolute top-0 right-0'"
      :header-classes="'flex items-end justify-between min-h-[48px] px-4 relative'"
      :wrapper-classes="'bg-white p-4'"
      :max-width="1296"
      class="relative h-0 overflow-hidden"
      @modal-opened="toggleShowVideo(true)"
      @modal-closed="toggleShowVideo(false)"
    >
      >
      <template v-slot:close-btn>
        <span class="sr-only">Close Modal</span>
        <span class="h-6 w-6"><XMarkIcon class="text-white" /></span>
      </template>

      <template v-slot:body>
        <div
          v-if="model.videoEmbedCode"
          class="iframe-body relative aspect-[16/9] w-full overflow-hidden rounded-xl bg-white"
          v-html="model.videoEmbedCode"
        ></div>
      </template>
    </c2-modal>
    <template v-if="model.videoShareScript">
      <div id="featured-custom-video-script"></div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { PlayIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import { useMediaSizeStore } from '@MoodyBible/stores/media-size'
import sImage from '@TodayInTheWord/components/shared/s-image.vue'
import sLink from '@TodayInTheWord/components/shared/s-link.vue'
import tag from '@TodayInTheWord/components/shared/tag.vue'
import C2Modal from 'uikit/c2-modal/c2-modal.vue'
import { nextTick, ref } from 'vue'
import { VideoIndexFeaturedVideoSection } from '../types/video-index-types'

interface VideoIndexHeroProps {
  model?: VideoIndexFeaturedVideoSection
}

const videoModal = ref(null)
const showVideoSrc = ref(true)
const playButton = ref()
const playImage = ref()

const mediaSizeStore = useMediaSizeStore()

const props = defineProps<VideoIndexHeroProps>()

function openVideoModal() {
  videoModal.value.openModal()
}

function closevideoModal() {
  videoModal.value.closeModal()
}

const toggleShowVideo = (bool: boolean) => {
  showVideoSrc.value = bool

  if (showVideoSrc.value) {
    createScript()
  } else {
    pauseAndReturnFocus()
  }
}
const createScript = () => {
  //clear any previous scripts
  var prevScript = document.querySelector('#featured-custom-video-script')
  if (prevScript !== null) {
    prevScript.innerHTML = ''
    var script = document.createElement('script')
    prevScript.appendChild(script)
    if (props.model?.videoShareScript) {
      script.innerHTML = props.model?.videoShareScript
    }
  }
}
const pauseAndReturnFocus = () => {
  const videos = [...document.getElementsByTagName('video')]
  videos.forEach((vid) => {
    vid.pause()
  })
  const iframeVideo = videoModal.value.$el.querySelector('iframe')
  if (iframeVideo) {
    let src = iframeVideo.src
    iframeVideo.src = src
  }
  if (playButton.value) {
    playButton.value.$el.focus()
  }
}
</script>

<style scoped></style>
