﻿<template>
  <div @click="clickOff">
    <div class="py-5" :class="{ 'border-grey-300 border-b': !mediaStore.isMobile }">
      <bread-crumbs :links="breadcrumbs.links" :currentPageName="breadcrumbs.currentPageName" />
    </div>
    <main class="container mx-auto py-5">
      <div class="flex flex-col md:flex-row">
        <div class="md:w-1/3">&nbsp;</div>
        <div class="w-full px-4 md:w-2/3">
          <div v-for="g in model.groups" class="border-grey-200 mb-4 border-b-2 pb-4">
            <h2 v-if="g.header" class="text-grey-600 mb-4 text-lg font-semibold">
              {{ g.header }}
            </h2>
            <div class="fields flex justify-between">
              <div v-for="f in g.fields" class="md\:flex-auto">
                <h4 v-if="f.label" class="text-grey-600 mb-4 text-base font-semibold">
                  {{ f.label }}
                </h4>
                <template v-if="f.readOnly">
                  <span>{{ f.value }}</span>
                </template>
                <template v-else>
                  <div
                    :data-name="f.name"
                    class="border-grey-200 flex w-full cursor-pointer flex-col items-start rounded-xl border border-solid bg-white drop-shadow-md"
                    @click="lastClick(g)"
                  >
                    <button
                      class="text-grey-500 hover:bg-grey-200 hover:text-grey-700 inline-flex cursor-pointer items-center justify-center rounded-xl border-solid bg-white !px-3 py-2 font-medium transition duration-300 ease-in-out marker:border-none"
                      :emphasis="'outline'"
                      @click="toggleDDL(f)"
                    >
                      <span class="mr-2.5 line-clamp-1 flex-1">
                        {{ f.matchText }}
                      </span>
                      <ChevronDownIcon v-if="!f.showDDL" class="ml-3 h-5 w-5"> </ChevronDownIcon>
                      <ChevronUpIcon v-if="f.showDDL" class="ml-3 h-5 w-5"> </ChevronUpIcon>
                    </button>
                    <div v-if="f.showDDL" class="absolute top-12 z-10 w-full">
                      <ul
                        class="border-grey-200 z-20 flex w-full flex-col items-start rounded-xl border border-solid bg-white drop-shadow-md"
                      >
                        <li
                          v-for="o in f.options"
                          class="hover:bg-grey-200 hover:text-grey-700 flex w-full justify-between bg-white !px-3 py-2"
                          @click="selectItem(f, o.value)"
                        >
                          <span>{{ o.text }}</span>
                          <CheckIcon
                            v-if="o.text == f.matchText"
                            class="greenIcon ml-3 h-5 w-5"
                          ></CheckIcon>
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="text-right">
            <button
              class="border-light-blue-50 bg-light-blue-50 text-light-blue-900 hover:border-light-blue-700 hover:bg-light-blue-700 hover:text-light-blue-50 active:border-light-blue-700 active:bg-light-blue-700 disabled:border-grey-300 disabled:bg-grey-300 disabled:text-grey-400 inline-flex h-full cursor-pointer items-center justify-center rounded-xl border border-solid px-3 py-[10px] pb-1.5 pt-1.5 !text-base text-lg font-medium transition duration-300 ease-in-out disabled:cursor-not-allowed"
              @click="restoreDefaults"
            >
              {{ model.cancelButton }}
            </button>
            <button
              class="border-light-blue-900 bg-light-blue-900 text-light-blue-50 hover:border-light-blue-700 hover:bg-light-blue-700 active:border-light-blue-700 active:bg-light-blue-700 disabled:border-grey-300 disabled:bg-grey-300 disabled:text-grey-400 ml-2 inline-flex h-full cursor-pointer items-center justify-center rounded-xl border border-solid px-3 py-[10px] pb-1.5 pt-1.5 !text-base text-lg font-medium transition duration-300 ease-in-out disabled:cursor-not-allowed"
              @click="saveAccount"
            >
              <div class="loading" v-if="accountStore.isLoading">
                <span class="loader"></span>
              </div>
              <span
                :class="{
                  'text-light-blue-900 hover:text-light-blue-700': accountStore.isLoading == true,
                }"
                >{{ model.saveButton }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div
    class="saveModal"
    :class="{ visible: accountStore.savedSuccess || accountStore.isLoadError }"
  >
    <div
      class="message bg-grey-900 flex items-center justify-between rounded-xl px-5 py-3 text-lg text-white"
    >
      <template v-if="accountStore.savedSuccess">
        <CheckCircleIcon class="brightGreenIcon mr-3 h-5 w-5"></CheckCircleIcon>
        <span>{{ model.saveSuccess }}</span>
      </template>
      <template v-else>
        <XCircleIcon class="redIcon mr-4 h-5 w-5"></XCircleIcon>
        <span>{{ model.saveFail }}</span>
      </template>
    </div>
  </div>
</template>

<script setup>
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/solid'
import SideNav from '@TodayInTheWord/components/pages/general-detail/components/side-nav.vue'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import ContentArea from '@TodayInTheWord/epi/content-area.vue'
import { useAccountStore } from '@TodayInTheWord/stores/account-management'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { onMounted } from 'vue'

const model = useEpiPageContextStore().model
const breadcrumbs = useEpiPageContextStore().breadcrumbs
const mediaStore = useMediaSizeStore()

const accountStore = useAccountStore()

onMounted(() => {
  setDefaults()
})

function setDefaults() {
  model.groups.forEach((g) => {
    g.fields.forEach((f) => {
      f.matchText = getText(f.value, f.options)
      f.defaultText = f.matchText
      f.defaultValue = f.value
    })
  })
}

function restoreDefaults() {
  model.groups.forEach((g) => {
    g.fields.forEach((f) => {
      f.matchText = f.defaultText
      f.value = f.defaultValue
    })
  })
  model.changed = false
}

function getText(value, options) {
  var text = value
  if (options) {
    options.forEach((o) => {
      if (o.value == value) {
        text = o.text
      }
    })
  }
  return text
}

function lastClick(group) {
  group.lastClick = true
}

function pageClick() {}
function clickOff() {
  model.groups.forEach((g) => {
    if (!g.lastClick) {
      g.fields.forEach((f) => {
        f.showDDL = false
      })
    } else {
      g.lastClick = false
    }
  })
}

function toggleDDL(option) {
  if (option.showDDL) {
    option.showDDL = false
  } else {
    option.showDDL = true
  }
}
function selectItem(option, value) {
  model.accountUpdated = true
  if (option.defaultValue != value) {
    model.changed = true
  }
  option.value = value
  option.showDDL = false
  option.matchText = getText(value, option.options)
}

async function saveAccount() {
  const data = { Version: model.groups[1].fields[0].value }
  await accountStore.saveOptions(data, model.pageUrl)
  setDefaults()
  const clearMessages = setTimeout(function () {
    accountStore.clearMessages()
  }, 5000)
}
</script>
<style lang="scss">
.saveModal {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: -100;
  opacity: 0;

  &.visible {
    animation: pop 5s normal forwards ease-in-out;
  }
}
@keyframes pop {
  0%,
  100% {
    opacity: 0;
    z-index: -1;
  }
  5%,
  95% {
    opacity: 1;
    z-index: 100;
  }
}
.greenIcon path {
  color: #0c4a6e;
}
.brightGreenIcon path {
  color: #16a34a;
}
.redIcon path {
  color: #a31f16;
}

div.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
</style>
