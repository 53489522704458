<template>
  <section
    v-if="model && model.slides.length > 0"
    class="my-12 md:my-16"
    v-bind:id="model.clientID"
  >
    <div class="TITW large-slider-block container mx-auto">
      <c2-slider
        :slide-total="model.slides.length"
        :slides-to-show="1"
        :slides-to-show-tablet="1"
        :slides-to-show-mobile="1"
        :slide-speed="500"
        :show-overflow="false"
        :show-button-nav="true"
        :showIndicators="false"
        :next-btn-classes="'w-12 h-12 flex items-center justify-center rounded-xl bg-light-blue-900 hidden md:flex'"
        :prev-btn-classes="'w-12 h-12 flex items-center justify-center rounded-xl bg-light-blue-900 mr-3 hidden md:flex'"
        :nav-classes="'relative justify-end mt-6 flex md:mt-14'"
        :button-nav-wrapper-classes="'max-w-full absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 flex align-center'"
        :button-classes="'h-1 w-9 rounded mx-0.5 bg-grey-300'"
        :active-button-classes="'!bg-light-blue-900'"
        class="large-slider relative"
      >
        <template v-slot:slide>
          <full-width-callout-block
            v-for="(slide, index) in model.slides"
            :key="index"
            :model="slide.model"
            :title-classes="`block md:text-3xl text-2xl font-semibold mb-6 md:mb-4 ${computedTextColor}`"
            class="slidefix"
          />
        </template>

        <template v-slot:prev-btn>
          <span class="sr-only"> Previous Slide </span>
          <span class="flex h-6 w-6 items-center justify-center text-white">
            <arrow-left-icon class="w-full" />
          </span>
        </template>

        <template v-slot:next-btn>
          <span class="sr-only"> Next Slide </span>
          <span class="flex h-6 w-6 items-center justify-center text-white">
            <arrow-right-icon class="w-full" />
          </span>
        </template>
      </c2-slider>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'
import FullWidthCalloutBlock from '@TodayInTheWord/components/blocks/full-width-callout-block/full-width-callout-block.vue'
import C2Slider from 'uikit/c2-slider/c2-slider.vue'
import { computed } from 'vue'
import { LargeSliderBlockProps } from './types/large-slider-block-types'

const props = defineProps<LargeSliderBlockProps>()

const computedTextColor = computed(() => {
  switch (props.model?.calloutTitleColor) {
    case 'white':
      return 'text-white'
    case 'blue':
      return 'text-light-blue-900'
    default:
      return 'text-white'
  }
})
</script>

<style lang="scss" scoped>
.slidefix {
  padding: inherit;
}
.large-slider {
  :deep(.full-width-callout-block) {
    margin-bottom: 0;
  }

  :deep(button[disabled]) {
    background-color: #6b7280;
  }
}
</style>
