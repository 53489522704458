<template>
  <section v-if="model && model.cards?.length > 0" class="space-y-6">
    <div class="flex justify-between">
      <h2
        v-if="model.displayName"
        class="heading-class text-grey-700 w-full overflow-hidden text-base font-semibold md:text-xl"
      >
        {{ model.displayName }}
      </h2>
      <shared-link
        v-if="model.url"
        :href="model.url"
        :emphasis="'outline'"
        :external-link="isExternalUrl"
        class="hover:text-light-blue-600 text-light-blue-900 ml-4 flex justify-center whitespace-nowrap border-none !py-2 text-sm font-medium leading-5 hover:bg-transparent md:text-base md:leading-6"
        >{{ model.seeAllLinkText }} <span class="ml-2 h-5 w-5"><arrow-long-right-icon /></span
      ></shared-link>
    </div>

    <div v-if="model.cards && model.cards.length > 0">
      <c2-slider
        :slide-total="model.cards?.length"
        :slides-to-show="4"
        :slides-to-show-tablet="2"
        :slides-to-show-mobile="1"
        :slide-speed="500"
        :gutter="mediaSizeStore.isMobile ? 16 : 24"
        :media-tablet-query="1023"
        :media-mobile-query="639"
        :show-overflow="true"
        :show-button-nav="true"
        :showIndicators="false"
        :next-btn-classes="'hidden'"
        :prev-btn-classes="'hidden'"
        :nav-classes="'flex justify-end mt-4 relative md:mt-14 md:mb-0'"
        :button-nav-wrapper-classes="'max-w-full absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 flex align-center'"
        :button-classes="'relative h-6 w-[37px] mx-0.5 after:rounded after:block after:h-1 after:w-full after:bg-grey-300 after:absolute after:top-1/2 after:transform after:-translate-y-1/2'"
        :active-button-classes="'after:!bg-light-blue-600'"
        class="relative w-full -translate-x-8 transform pl-8 md:translate-x-0 md:pl-0"
      >
        <template v-slot:slide>
          <s-image-link-card v-for="(card, index) in model.cards" :card="card" :key="index" />
        </template>
      </c2-slider>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ArrowLongRightIcon } from '@heroicons/vue/20/solid'
import SImageLinkCard from '@TodayInTheWord/components/shared/s-image-link-card.vue'
import SharedLink from '@TodayInTheWord/components/shared/s-link.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import C2Slider from 'uikit/c2-slider/c2-slider.vue'
import { computed } from 'vue'
import { FeaturedVerseCategoriesProps } from '../types/bible-verse-index-types'

interface FeaturedCategoryProps {
  model?: FeaturedVerseCategoriesProps
}

const props = defineProps<FeaturedCategoryProps>()
const mediaSizeStore = useMediaSizeStore()

const isExternalUrl = computed(() => {
  return !props.model?.url?.startsWith('/')
})
</script>
