<template>
  <div class="mx-auto w-full max-w-full lg:max-w-[856px]">
    <bread-crumbs
      :links="breadcrumbs.links"
      :currentPageName="breadcrumbs.currentPageName"
      class="my-5 lg:my-6"
    />
    <div v-if="model.heroImage" class="relative mb-11 lg:mb-12">
      <template v-if="!mediaSizeStore.isMobile">
        <div class="relative aspect-video overflow-hidden lg:rounded-xl">
          <s-image
            :model="model.heroImage"
            class="m-h-full absolute w-full object-cover transition duration-300 ease-in-out"
            format="?width=1000&format=webp&compand=true"
            :preload="true"
          />
        </div>
      </template>
      <template v-else>
        <div class="'-mx-[18px] relative aspect-video overflow-hidden">
          <s-image
            :model="model.mobileHeroImage"
            class="m-h-full absolute w-full object-cover transition duration-300 ease-in-out"
            format="?width=1000&format=webp&compand=true"
            :preload="true"
          />
        </div>
      </template>
      <div class="divide-light-blue-200 absolute -bottom-6 left-6 z-[99] flex flex-wrap divide-x">
        <s-link-auto v-if="model.previousDayUrl" :href="model.previousDayUrl">
          <s-button class="group" :emphasis="'tonal'" :class-override="computedPrevBtnStyle">
            <arrow-left-icon
              class="text-light-blue-900 h-5 w-5 transition duration-300 group-hover:text-white"
            />
            <span class="ml-3">{{ model.previousDayText }}</span>
          </s-button>
        </s-link-auto>
        <s-link-auto v-if="model.nextDayUrl" :href="model.nextDayUrl">
          <s-button class="group" :emphasis="'tonal'" :class-override="computedNextBtnStyle">
            <span class="mr-3">{{ model.nextDayText }}</span>
            <arrow-right-icon
              class="text-light-blue-900 h-5 w-5 transition duration-300 group-hover:text-white"
            />
          </s-button>
        </s-link-auto>
      </div>
      <div class="absolute -bottom-6 right-[30px] flex w-full justify-end">
        <share-this :featuredImageBtn="true" fill-class="h-6 w-6" :solid="true" />
      </div>
    </div>
    <div class="px-5 lg:px-0">
      <div class="flex flex-col border-b border-b-slate-200 pb-8 lg:pb-10">
        <div class="mb-3 flex justify-between lg:mb-4 lg:justify-normal lg:space-x-4">
          <span
            class="text-light-blue-900 bg-light-blue-100 rounded-xl px-3 py-1 text-sm font-medium leading-5"
            >Daily Devotional</span
          >
          <span
            v-if="model.displayDate"
            class="text-grey-600 flex items-center text-sm font-normal leading-5 lg:text-base lg:leading-6"
            >{{ model.displayDate }}</span
          >
        </div>
        <h1
          v-if="model.heroTitle"
          class="text-light-blue-900 mb-1 text-2xl font-semibold leading-8 lg:mb-2 lg:text-4xl lg:leading-10"
        >
          <span class="sr-only" v-if="model.heroTitlePrefix">{{ model.heroTitlePrefix }} | </span
          >{{ model.heroTitle }}
        </h1>
        <div
          v-if="model.heroSubtitle"
          class="text-base font-normal leading-6 lg:text-xl lg:leading-7"
        >
          {{ model.heroSubtitle }}
        </div>
      </div>
      <div v-if="model.streamUrl" class="mt-8 lg:mt-10">
        <AudioPlayer :title="model.heroTitle" :src="model.streamUrl"></AudioPlayer>
      </div>
      <div v-else-if="model.omnyId" class="mt-8 lg:mt-10">
        <OmnyPlayer
          :title="model.heroTitle"
          :omnyId="model.omnyId"
          :omnyOrg="model.omnyOrgId"
        ></OmnyPlayer>
      </div>
      <div class="mt-8">
        <s-link
          v-if="model.verseOfTheDay"
          :href="model.verseOfTheDay.href"
          :external-link="true"
          class="group flex items-center text-base font-medium leading-6"
        >
          {{ model.verseOfTheDay.text }}
          <span>
            <arrow-right-icon
              class="text-light-blue-900 ml-2 h-5 w-5 flex-shrink-0 transition-all duration-300 group-hover:text-cyan-500"
            />
          </span>
        </s-link>
      </div>
      <div>
        <RichTextBlock
          v-if="model.mainBody"
          :model="{ richTextField: model.mainBody }"
          class="my-6"
          :classOverride="'prose text-xl !max-w-none'"
        />
        <div
          v-if="model.discussionStartersLabel"
          class="text-grey-800 mb-4 text-xl font-bold leading-7"
        >
          {{ model.discussionStartersLabel }}
        </div>
        <RichTextBlock
          v-if="model.discussionStartersText"
          :model="{ richTextField: model.discussionStartersText }"
          class="mb-10"
          :classOverride="'text-xl'"
        />
        <div v-if="model.prayWithUsLabel" class="text-grey-800 mb-4 text-xl font-bold leading-7">
          {{ model.prayWithUsLabel }}
        </div>
        <RichTextBlock
          v-if="model.prayWithUsText"
          :model="{ richTextField: model.prayWithUsText }"
          class="mb-7"
          :classOverride="'text-xl'"
        />
        <div
          v-if="model.highlightedVerseText"
          class="bg-light-blue-50 border-light-blue-100 mb-8 flex flex-col space-y-4 rounded-xl p-4"
        >
          <span class="text-lg font-normal leading-7">{{ model.highlightedVerseText }}</span>
          <s-link-auto v-if="model.highlightedVerseLink" :link="model.highlightedVerseLink">{{
            model.highlightedVerseLink.text
          }}</s-link-auto>
        </div>
        <div v-if="model.pdfDownloadUrl" class="mb-7">
          <s-button
            :emphasis="'tonal'"
            :style="'primary'"
            @click="downloadPdf(model.pdfDownloadUrl)"
            :class-override="'w-fit lg:mt-6 downloadButton'"
          >
            {{ model.pdfDownloadText }}
            <span><document-arrow-down-icon class="ml-2 h-5 w-5" /></span>
          </s-button>
        </div>
        <div class="relative flex space-x-1">
          <share-this />
          <button class="printButton bg-transparent p-4" @click.prevent="handlePrint">
            <PrinterIcon
              class="stroke-light-blue-900 hover:stroke-light-blue-600 h-6 w-6 transition"
            />
          </button>
        </div>
      </div>
      <div class="mt-8 space-y-8 lg:mt-12 lg:space-y-16">
        <AboutTheAuthor :model="model.authorsSection" />
        <RelatedContent :model="model.relatedContent" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DocumentArrowDownIcon,
  PrinterIcon,
} from '@heroicons/vue/24/outline'
import RichTextBlock from '@TodayInTheWord/components/blocks/rich-text-block/rich-text-block.vue'
import AboutTheAuthor from '@TodayInTheWord/components/pages/shared/about-the-author.vue'
import AudioPlayer from '@TodayInTheWord/components/pages/shared/audio-player.vue'
import OmnyPlayer from '@TodayInTheWord/components/pages/shared/omny-player.vue'
import RelatedContent from '@TodayInTheWord/components/pages/shared/related-content.vue'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import SImage from '@TodayInTheWord/components/shared/s-image.vue'
import SLinkAuto from '@TodayInTheWord/components/shared/s-link-auto.vue'
import ShareThis from '@TodayInTheWord/components/shared/share-this/s-share-this-button.vue'
import { useCookiesStore } from '@TodayInTheWord/stores/cookies'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import { computed, ref } from 'vue'
import { DevotionalDetailProps } from '../../types/devotional-detail-types'

const model: DevotionalDetailProps = useEpiPageContextStore().model
const breadcrumbs: BreadcrumbProps = useEpiPageContextStore().breadcrumbs
const mediaSizeStore = useMediaSizeStore()
const cookiesStore = useCookiesStore()

const state = ref({
  devotionalSaved: false,
})

const handlePrint = () => {
  window.print()
}

const computedPrevBtnStyle = computed(() => {
  if (model.nextDayUrl) {
    return '!p-2  lg:!p-3 !rounded-l-xl !rounded-r-none !border-t-0 !border-l-0 !border-b-0 !duration-300'
  } else {
    return '!p-2  lg:!p-3 !rounded-xl !border-t-0 !border-l-0 !border-b-0 !duration-300'
  }
})

const computedNextBtnStyle = computed(() => {
  if (model.previousDayUrl) {
    return '!p-2  lg:!p-3 !rounded-r-xl !rounded-l-none !border-t-0 !border-r-0 !border-b-0 !duration-300'
  } else {
    return '!p-2  lg:!p-3 !rounded-xl !border-t-0 !border-l-0 !border-b-0 !duration-300'
  }
})

function downloadPdf(pdfLink: string) {
  const link = document.createElement('a')
  link.href = pdfLink
  link.target = '_blank'
  link.download = pdfLink

  // Simulate a click on the element <a>
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
</script>
