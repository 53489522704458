<template>
  <section>
    <button
      @click="
        (bibleGateway.mobileVersionMenu = !bibleGateway.mobileVersionMenu),
          (bibleGateway.mobileVerseMenu = false)
      "
      class="text-grey-800 inline-flex cursor-pointer items-center justify-center border-none px-3 py-2 font-medium transition duration-300 ease-in-out"
      :emphasis="'outline'"
    >
      {{ bibleGateway.activeVersion }}
      <span class="transition">
        <Bars2Icon
          v-if="!bibleGateway.mobileVersionMenu"
          class="ml-3 h-5 w-5"
        ></Bars2Icon>
        <XMarkIcon
          v-if="bibleGateway.mobileVersionMenu"
          class="ml-3 h-5 w-5"
        ></XMarkIcon>
      </span>
    </button>
    <div
      v-if="bibleGateway.mobileVersionMenu"
      class="border-grey-300 absolute -left-3 top-12 z-40 flex h-screen w-screen flex-col space-y-2 border bg-white p-8"
    >
      <div v-for="(menu, index) in bibleGateway.versionsMenu" :key="index">
        <button
          :class="
            menu.name === bibleGateway.activeVersion
              ? 'text-cyan-700'
              : 'text-grey-700'
          "
          @click="
            updateContent(true),
              (bibleGateway.mobileVersionMenu = false),
              bibleGateway.setActiveVersion(menu)
          "
          @click.stop.prevent
        >
          {{ menu.display }}
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { Bars2Icon } from '@heroicons/vue/24/solid'
import { useBibleGatewayStore } from '@TodayInTheWord/stores/bible-gateway'

const bibleGateway = useBibleGatewayStore()
const emit = defineEmits(['updateContent'])

const updateContent = (fetchMenu) => {
  emit('updateContent', fetchMenu)
}

const props = defineProps({
  menu: {
    type: Object,
    default: () => ({}),
  },
})
</script>
