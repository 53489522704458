<template>
  <section
    v-if="
      model && model.featuredResources && model.featuredResources.length > 0
    "
    v-bind:id="model.clientID"
  >
    <div class="TITW featured-resources-block container relative mx-auto">
      <div v-if="model.heading || model.subheading" class="mb-6 w-full md:mb-4">
        <section-heading
          :heading="model.heading"
          :subheading="model.subheading"
        />
      </div>
      <div
        v-if="model.featuredResources && model.featuredResources.length > 0"
        class="flex flex-wrap justify-center md:mb-8 md:justify-start"
      >
        <c2-slider
          :slide-total="model.featuredResources.length"
          :slides-to-show="4"
          :slides-to-show-tablet="2"
          :slides-to-show-mobile="1"
          :slide-speed="500"
          :gutter="mediaStore.isMobile ? 10 : 16"
          :media-tablet-query="1023"
          :media-mobile-query="639"
          :show-overflow="true"
          :show-button-nav="true"
          :showIndicators="false"
          :next-btn-classes="'w-12 h-12 flex items-center justify-center rounded-xl bg-light-blue-900 hover:bg-light-blue-700 hidden md:flex disabled:bg-grey-400 transition'"
          :prev-btn-classes="'w-12 h-12 flex items-center justify-center rounded-xl bg-light-blue-900 hover:bg-light-blue-700 mr-3 hidden md:flex disabled:bg-grey-400 transition'"
          :nav-classes="'flex justify-end mt-4 relative md:mt-14 md:mb-0'"
          :button-nav-wrapper-classes="'max-w-full absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 flex align-center'"
          :button-classes="'relative h-6 w-[37px] mx-0.5 after:rounded after:block after:h-1 after:w-full after:bg-grey-300 after:absolute after:top-1/2 after:transform after:-translate-y-1/2'"
          :active-button-classes="'after:!bg-light-blue-600'"
          class="relative w-full -translate-x-8 transform pl-8 md:translate-x-0 md:pl-0"
        >
          <template v-slot:slide>
            <div
              v-for="(card, i) in model.featuredResources"
              :key="i"
              class="group h-full w-full"
            >
              <component :is="getCardComponent(card)" :card="card" />
            </div>
          </template>

          <template v-slot:prev-btn>
            <span class="sr-only"> Previous Slide </span>
            <span class="flex h-6 w-6 items-center justify-center text-white">
              <ArrowLeftIcon class="w-full" />
            </span>
          </template>

          <template v-slot:next-btn>
            <span class="sr-only"> Next Slide </span>
            <span class="flex h-6 w-6 items-center justify-center text-white">
              <ArrowRightIcon class="w-full" />
            </span>
          </template>
        </c2-slider>
      </div>
      <div
        v-if="model.viewAllLink && model.viewAllLink.href"
        class="mt-8 flex w-full md:bottom-[3px] md:h-[88px] md:h-auto md:w-auto"
        :class="viewAllBtnPosition"
      >
        <s-link
          :href="model.viewAllLink.href"
          :style="'primary'"
          :emphasis="'outline'"
          class="align-center flex w-full md:w-auto"
          :target="model.viewAllLink.target"
          :aria-label="model.viewAllLink.ariaLabel"
          :external-link="model.viewAllLink.isExternal"
        >
          <span>{{ model.viewAllLink.text }}</span>
          <span class="ml-3 flex h-5 w-5"><PlusIcon class="w-full" /></span>
        </s-link>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PlusIcon,
} from '@heroicons/vue/24/outline'
import ResourceCardMoodybible from '@TodayInTheWord/components/blocks/featured-resources-block/components/resource-card-moodybible.vue'
import ResourceCardTodayintheword from '@TodayInTheWord/components/blocks/featured-resources-block/components/resource-card-todayintheword.vue'
import SectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import C2Slider from 'uikit/c2-slider/c2-slider.vue'
import { computed } from 'vue'
import {
  FeaturedResourcesBlockProps,
  ResourceCardMb,
  ResourceCardTitw,
} from './types/featured-resources-block-types'

const props = defineProps<FeaturedResourcesBlockProps>()

const mediaStore = useMediaSizeStore()

const viewAllBtnPosition = computed(() => {
  if (props.model.featuredResources.length <= 4 && mediaStore.isDesktop) {
    return 'md:relative md:mt-14'
  } else {
    return 'md:absolute md:mt-0'
  }
})

const getCardComponent = (
  card: ResourceCardMb | ResourceCardTitw,
): typeof ResourceCardTodayintheword | typeof ResourceCardMoodybible => {
  if (card.siteName === 'todayintheword') {
    return ResourceCardTodayintheword
  } else {
    return ResourceCardMoodybible
  }
}
</script>
