<template>
  <section
    v-if="model"
    class="TITW full-width-callout-block container mx-auto w-full"
    v-bind:id="model.clientID"
  >
    <div
      class="md:rounded-4xl md:py-30 relative h-full overflow-hidden rounded-3xl px-4 py-24 md:px-14"
      :class="{
        'bg-grey-100': !model.image,
      }"
    >
      <picture
        v-if="model.image"
        class="after:from-white/16 absolute inset-x-0 inset-y-0 after:absolute after:inset-x-0 after:inset-y-0 after:block after:bg-gradient-to-r after:to-white/0"
      >
        <shared-image
          :model="model.image"
          class="h-full w-full object-cover"
          :class="computedImageClasses"
          :format="formatImage"
        />
      </picture>
      <div
        v-if="(model.calloutHeading && model.calloutSubheading) || model.calloutButton.href"
        :class="[textClasses, mediaStore.isMobile ? 'mobile-gradient' : 'desktop-gradient']"
      >
        <h2 v-if="model.calloutHeading" :class="computedTitleClasses" class="line-clamp-2">
          {{ model.calloutHeading }}
        </h2>
        <p v-if="model.calloutSubheading" class="my-6 line-clamp-3 md:my-4">
          {{ model.calloutSubheading }}
        </p>
        <s-link
          v-if="model.calloutButton && model.calloutButton.href"
          :href="model.calloutButton.href"
          :target="model.calloutButton.target"
          :style="model.calloutButton.buttonStyle"
          :size="model.calloutButton.buttonSize"
          :emphasis="model.calloutButton.buttonEmphasis"
          :externalLink="model.calloutButton.isExternal"
        >
          {{ model.calloutButton.text }}
        </s-link>
        <div
          v-else-if="model.thumbnailButtons && model.thumbnailButtons.length > 0"
          class="flex space-x-2.5"
        >
          <s-link
            v-for="(link, index) in model.thumbnailButtons"
            :href="link.href"
            :target="link.target"
            :externalLink="link.isExternal"
            :key="index"
            class="overflow-hidden rounded-xl"
          >
            <shared-image :model="link.image" class="max-h-[40px] max-w-[132px] object-cover" />
          </s-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { computed } from 'vue'
import { FullWidthCalloutBlockProps } from './types/full-width-callout-block-types'

const mediaStore = useMediaSizeStore()
const props = defineProps<FullWidthCalloutBlockProps>()

const textClasses = computed(() => {
  let classes: string = ''
  if (props.panelTextClasses) {
    classes = props.panelTextClasses
  } else {
    //Default
    classes = 'relative rounded-xl backdrop-blur-sm max-w-[644px]'
  }

  if (props.model?.contentPosition === 'center') {
    classes += ' mx-auto'
  }
  if (props.model?.contentTextAlignment === 'right') {
    classes += ' text-right'
  }
  if (props.model?.contentTextAlignment === 'center') {
    classes += ' text-center'
  }
  if (props.model?.ctaSize === 'regular') {
    classes += ' py-6 px-4 md:p-14'
  }
  if (props.model?.ctaSize === 'large') {
    classes += ' py-7 px-5 md:p-16'
  }
  if (props.model?.ctaSize === 'extra large') {
    classes += ' py-8 px-6 md:p-20'
  }

  return classes
})

const computedImageClasses = computed(() => {
  switch (props.model?.imagePosition) {
    case 'bottom':
      return 'object-bottom'
    case 'center':
      return 'object-center'
    case 'left':
      return 'object-left'
    case 'bottom-left':
      return 'object-left-bottom'
    case 'top-left':
      return 'object-left-top'
    case 'right':
      return 'object-right'
    case 'bottom-right':
      return 'object-right-bottom'
    case 'top-right':
      return 'object-right-top'
    case 'top':
      return 'object-top'
    default:
      return ''
  }
})

const formatImage = computed(() => {
  if (mediaStore.isMobile) {
    return '?width=300&format=webp&compand=true'
  } else if (mediaStore.isTablet) {
    return '?width=800&format=webp&compand=true'
  } else {
    return '?width=1300&format=webp&compand=true'
  }
})

const computedTitleClasses = computed(() => {
  if (props.titleClasses) {
    return props.titleClasses
  } else {
    //Default
    return 'md:text-3xl text-2xl font-semibold text-grey-700 mb-6 md:mb-4'
  }
})
</script>
<style scoped lang="scss">
.desktop-gradient {
  background: radial-gradient(
      138.63% 80.74% at 28.22% 55.07%,
      rgba(251, 250, 249, 0.38) 0%,
      rgba(209, 235, 255, 0.38) 100%
    ),
    rgba(249, 250, 251, 0.6);
}
.mobile-gradient {
  background: radial-gradient(
      138.63% 80.74% at 28.22% 55.07%,
      rgba(251, 250, 249, 0.8) 0%,
      rgba(209, 235, 255, 0.8) 100%
    ),
    rgba(249, 250, 251, 0.6);
}
</style>
